import { Injectable } from '@angular/core'
import { environment } from 'environment/environment'
import LCC from 'lightning-container'
import { IntegrationEvent } from 'models/integration-event'
import { Observable, Subject } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class IntegrationsService {
  // Events are sent or received here
  private queue: Subject<IntegrationEvent> = new Subject()

  constructor() {
    if (this.isUsingIntegrations()) {
      // Integrations events only received on minimalVersion for now
      LCC.addMessageHandler((event: IntegrationEvent) => this.queue.next(event))
    }
  }

  isUsingIntegrations(): boolean {
    return Boolean(environment.minimalVersion)
  }

  onEventReceived(): Observable<IntegrationEvent> {
    return this.queue.asObservable()
  }

  sendEvent(event: IntegrationEvent) {
    LCC.sendMessage(event)
  }
}
