import { ActionReducer, ActionReducerMap } from '@ngrx/store'
import { localStorageSync } from 'ngrx-store-localstorage'
import { reducer as aiReducer } from './ai.reducer'
import { deserialize, reducer as authReducer } from './auth.reducer'
import * as botReducer from './bot.reducer'
import * as deskReducer from './desk.reducer'
import { State } from './index'
import { reducer as insightsReducer } from './insights.reducer'
import * as organizationReducer from './organization.reducer'
import { reducer as statsReducer } from './stats.reducer'
import { reducer as tasksReducer } from './tasks.reducer'
import { reducer as templatesReducer } from './templates.reducer'
import { reducer as userSettingsReducer } from './user-settings.reducer'
import { reducer as usersReducer } from './users.reducer'

export const reducers: ActionReducerMap<State> = {
  ai: aiReducer,
  auth: authReducer,
  bot: botReducer.reducer,
  desk: deskReducer.reducer,
  organization: organizationReducer.reducer,
  stats: statsReducer,
  tasks: tasksReducer,
  templates: templatesReducer,
  users: usersReducer,
  userSettings: userSettingsReducer,
  insights: insightsReducer,
}

export function localStorageSyncReducer(
  reducer: ActionReducer<any>
): ActionReducer<any> {
  return localStorageSync({
    keys: [
      { auth: { deserialize } },
      // { bot: { deserialize: botReducer.deserialize } },
      // { desk: { deserialize: deskReducer.deserialize } },
      // { organization: { deserialize: organizationReducer.deserialize } },
      // { tasks: { deserialize: deserializeTasks } },
      // { users: { deserialize: deserialiseUsers } },
    ],
    rehydrate: true,
  })(reducer)
}
