import { Component, Inject, OnInit } from '@angular/core'
import {
  MatLegacySnackBarRef as MatSnackBarRef,
  MAT_LEGACY_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA,
} from '@angular/material/legacy-snack-bar'
import { Feedback } from '../../../models/feedback'

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss'],
})
export class FeedbackComponent implements OnInit {
  showIcon = false
  iconClass = ''

  constructor(
    private ref: MatSnackBarRef<FeedbackComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: Feedback
  ) {}

  ngOnInit(): void {
    this.showIcon = this.data.hasType()
  }

  get icon(): string {
    if (this.data.isError()) {
      return 'highlight_off'
    } else if (this.data.isSuccess()) {
      return 'check_circle_outline'
    }
    return 'info'
  }

  onDismiss() {
    this.ref.dismiss()
  }
}
