import { Component } from '@angular/core'
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog'

@Component({
  selector: 'app-away-dialog',
  templateUrl: './away-dialog.component.html',
  styleUrls: ['./away-dialog.component.scss'],
})
export class AwayDialogComponent {
  constructor(public dialogRef: MatDialogRef<any>) {}

  close(keepWorking = false) {
    this.dialogRef.close(keepWorking)
  }
}
