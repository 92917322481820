import { Injectable } from '@angular/core'
import { JsonConvert, ValueCheckingMode } from 'json2typescript'

// TODO: Should we keep it as a Service or use JsonUtils instead?
@Injectable()
export class ConverterService {
  public jsonConvert: JsonConvert

  constructor() {
    this.jsonConvert = new JsonConvert()
    this.jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL
  }
}
