/* eslint-disable @typescript-eslint/naming-convention */
import { Inject, Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { JsonConvert } from 'json2typescript'
import { HubtypeOrganization } from 'models/hubtype-organization'
import { HubtypeTranslationSettings } from 'models/hubtype-translation-language'
import { EMPTY, Observable, of } from 'rxjs'
import { first, map, switchMap } from 'rxjs/operators'
import { FeatureFlag } from 'src/app/constants/feature-flags'
import { UpdateUserAction } from '../../actions/organization'
import {
  HubtypeUser,
  HubtypeUserStatusEmail,
  UserMeUpdateFields,
  UserUpdateFields,
} from '../../models/hubtype-user'
import * as fromRoot from '../../reducers'
import { AuthService } from '../../services/hubtype-api/auth.service'
import { Utilities } from '../../shared/utilities'
import { VacationRange } from '../../utils/time-utils'
import { ConverterService } from '../converter.service'
import { HubtypeApiService } from './hubtype-api.service'

@Injectable()
export class UserService {
  public me: HubtypeUser | null
  public currentUserHasTranslationsAccess?: boolean = false
  jsonConverter: JsonConvert = new JsonConvert()

  constructor(
    private store: Store<fromRoot.State>,
    @Inject('apiService') private apiService: HubtypeApiService,
    @Inject('Utilities') private utilities: Utilities,
    @Inject('convertService') private convertService: ConverterService,
    @Inject('authService') private authService: AuthService
  ) {
    this.store.select(fromRoot.getMeState).subscribe(me => (this.me = me))
  }
  public setTranslationsAccess(organization: HubtypeOrganization) {
    const organizationAccess = organization.hasAccessTo(
      FeatureFlag.HAS_TRANSLATION_SERVICE
    )
    const userAccess = this.me?.translation_settings?.enabled
    this.currentUserHasTranslationsAccess = organizationAccess && userAccess
  }
  getMe(forceSync = false): Observable<HubtypeUser> {
    if (forceSync) {
      return this.fetchMe()
    }
    return this.store.select(fromRoot.getMeState).pipe(
      first(),
      switchMap(user => {
        if (!user) {
          console.log('User not found in state, it will be fetched')
          return this.fetchMe()
        }
        return of(user)
      })
    )
  }
  // TODO: Since effects have been added, this method should always fetch
  getUsers(forceSync = false): Observable<HubtypeUser[]> {
    if (forceSync) {
      return this.fetchUsers()
    }

    return this.store.select(fromRoot.getUsers).pipe(
      first(),
      switchMap(users => {
        if (!users.length) {
          console.log('User not found in state, they will be fetched')
          return this.fetchUsers()
        }
        return of(users)
      })
    )
  }

  public getUser(userId: string): Observable<HubtypeUser> {
    return this.store.select(fromRoot.getUser(userId))
  }

  setOnline(me: HubtypeUser | null = null): Observable<HubtypeUser> {
    if (me) {
      this.me = me
    }
    if (this.me && this.me.id) {
      return this.apiService
        .post(`/users/${this.me.id}/set_online/`, null, null, 'v2', false)
        .pipe(
          map<any, HubtypeUser>(response =>
            this.convertService.jsonConvert.deserializeObject(
              response.user,
              HubtypeUser
            )
          )
        )
    }
    return EMPTY
  }
  setAvailable(): Observable<HubtypeUser> {
    if (this.me && this.me.id) {
      return this.apiService
        .post(`/users/${this.me.id}/set_available/`, null, null, 'v2')
        .pipe(
          map<any, HubtypeUser>(response =>
            this.convertService.jsonConvert.deserializeObject(
              response.user,
              HubtypeUser
            )
          )
        )
    }
    return EMPTY
  }
  setAway(): Observable<HubtypeUser> {
    if (this.me && this.me.id) {
      return this.apiService
        .post(`/users/${this.me.id}/set_away/`, null, null, 'v2')
        .pipe(
          map<any, HubtypeUser>(response =>
            this.convertService.jsonConvert.deserializeObject(
              response.user,
              HubtypeUser
            )
          )
        )
    }
    return EMPTY
  }
  setOffline(): Observable<HubtypeUser> {
    if (this.me && this.me.id) {
      return this.apiService
        .post(`/users/${this.me.id}/set_offline/`, null, null, 'v2')
        .pipe(
          map<any, HubtypeUser>(response =>
            this.convertService.jsonConvert.deserializeObject(
              response.user,
              HubtypeUser
            )
          )
        )
    }
    return EMPTY
  }
  update(user: HubtypeUser): Observable<HubtypeUser> {
    if (this.me && this.me.id) {
      return this.apiService.put(`/users/${user.id}/`, user, 'v3', false).pipe(
        map(response =>
          this.convertService.jsonConvert.deserializeObject(
            response,
            HubtypeUser
          )
        ),
        map(hubtypeUser => {
          this.store.dispatch(new UpdateUserAction(hubtypeUser))
          return hubtypeUser
        })
      )
    }
    return EMPTY
  }

  partialUpdate(user: Partial<UserUpdateFields>): Observable<HubtypeUser> {
    return this.apiService.patch(`/users/${user.id}/`, user, 'v3').pipe(
      map(response =>
        this.convertService.jsonConvert.deserializeObject(response, HubtypeUser)
      ),
      map(hubtypeUser => {
        this.store.dispatch(new UpdateUserAction(hubtypeUser))
        return hubtypeUser
      })
    )
  }

  updateUserMe(
    userMeUpdateFields: UserMeUpdateFields
  ): Observable<HubtypeUser> {
    return this.apiService
      .patch('/users/me/', userMeUpdateFields, 'v2')
      .pipe(
        map(response =>
          this.convertService.jsonConvert.deserializeObject(
            response,
            HubtypeUser
          )
        )
      )
  }

  setUserMeTranslationSettings(
    translationSettings: HubtypeTranslationSettings
  ): Observable<HubtypeUser> {
    return this.apiService
      .patch('/users/me/', { translation_settings: translationSettings }, 'v2')
      .pipe(
        first(),
        map(response =>
          this.convertService.jsonConvert.deserializeObject(
            response,
            HubtypeUser
          )
        )
      )
  }

  setAssignedQueues(userId: string, queueIds: string[]) {
    return this.apiService
      .post(
        `/users/${userId}/set_assigned_queues/`,
        { queue_ids: queueIds },
        '',
        'v2'
      )
      .pipe(first())
  }

  setAssignedProjects(userId: string, projectIds: string[]) {
    return this.apiService
      .post(
        `/users/${userId}/set_assigned_projects/`,
        { project_ids: projectIds },
        '',
        'v2'
      )
      .pipe(first())
  }

  uploadPicture(userId: string, pic: string) {
    const body = new FormData()
    const img_data = this.utilities.dataURLToBlob(pic)
    body.append('pic', img_data, 'pic')
    return this.apiService.post(
      `/users/${userId}/upload_pic/`,
      body,
      null,
      'v2'
    )
  }
  changePassword(
    userId: string,
    current_password: string,
    new_password: string
  ) {
    const body = {
      current_password,
      new_password,
    }
    return this.apiService.post(
      `/users/${userId}/change_password/`,
      body,
      null,
      'v2',
      false
    )
  }

  // new version of change password
  updatePassword(userId: string, password: string) {
    const body = {
      new_password: password,
    }
    return this.apiService.post(
      `/users/${userId}/change_password/`,
      body,
      null,
      'v3',
      false
    )
  }
  resetPassword(user: HubtypeUser): Observable<any> {
    return this.apiService.post(
      `/users/${user.id}/reset_password/`,
      {},
      null,
      'v2'
    )
  }
  forgotPassword(userEmail: string): Observable<any> {
    const body = {
      email: userEmail,
    }
    return this.apiService.post(`/users/forgot_password/`, body, null, 'v3')
  }
  signUpUser(user: HubtypeUser): Observable<HubtypeUser> {
    return this.apiService
      .post(`/sign-up/`, user, null, 'v1', false)
      .pipe(
        map(response =>
          this.convertService.jsonConvert.deserializeObject(
            response,
            HubtypeUser
          )
        )
      )
  }
  inviteUser(user: HubtypeUser): Observable<HubtypeUser> {
    return this.apiService
      .post(`/users/`, user, null, 'v3', false)
      .pipe(
        map(response =>
          this.convertService.jsonConvert.deserializeObject(
            response,
            HubtypeUser
          )
        )
      )
  }

  deleteUser(user: HubtypeUser): Observable<any> {
    return this.apiService.post(
      `/users/${user.id}/deactivate/`,
      null,
      null,
      'v2',
      false
    )
  }

  activateUser(user: HubtypeUser): Observable<any> {
    return this.apiService
      .post(`/users/${user.id}/activate/`, null, null, 'v3', false)
      .pipe(
        map(() => {
          user.is_active = true
          this.store.dispatch(new UpdateUserAction(user))
          return true
        })
      )
  }
  getAgentVacationRanges(
    user: HubtypeUser
  ): Observable<{ vacation_ranges: VacationRange[] }> {
    return this.apiService.get(`/users/${user.id}/vacation_range/`, null, 'v2')
  }
  addVacationRange(
    user: HubtypeUser,
    vacationRange: VacationRange
  ): Observable<any> {
    const data = {
      range_id: vacationRange.id,
      start_date: vacationRange.start_date,
      end_date: vacationRange.end_date,
    }
    return this.apiService.put(
      `/users/${user.id}/add_vacation_range/`,
      data,
      'v2'
    )
  }
  deleteVacationRange(
    user: HubtypeUser,
    vacationRangeId: number
  ): Observable<any> {
    const data = {
      range_id: vacationRangeId,
    }
    return this.apiService.delete(
      `/users/${user.id}/delete_vacation_range/`,
      null,
      data,
      'v2'
    )
  }
  setOfflineAndLogout(): Observable<HubtypeUser> {
    return this.setOffline().pipe(
      map(user => {
        if (user) {
          this.authService.logout(user)
          return user
        }
      })
    )
  }
  checkEmail(email: string): Observable<HubtypeUserStatusEmail> {
    return this.apiService
      .get(
        '/users/check_by_email/?email=' + encodeURIComponent(email),
        null,
        'v2',
        false
      )
      .pipe(
        map(response =>
          this.convertService.jsonConvert.deserializeObject(
            response,
            HubtypeUserStatusEmail
          )
        )
      )
  }

  resendInvitations(userId: string) {
    return this.apiService
      .post(`/users/${userId}/resend_invitation/`, null, null, 'v3')
      .pipe(
        map(response =>
          this.convertService.jsonConvert.deserializeObject(
            response,
            HubtypeUserStatusEmail
          )
        )
      )
  }

  getAllPaginatedUsers(): Observable<HubtypeUser[]> {
    const PAGE_SIZE = 100
    return this.apiService
      .getAllPaginatedResults(PAGE_SIZE, '/users/', 'v3')
      .pipe(
        first(),
        map(allUsers =>
          this.jsonConverter.deserializeArray(allUsers, HubtypeUser)
        )
      )
  }

  public fetchUser(userId: string): Observable<HubtypeUser> {
    return this.apiService.get(`/users/${userId}`, null, 'v3').pipe(
      first(),
      map(response =>
        this.convertService.jsonConvert.deserializeObject(response, HubtypeUser)
      )
    )
  }

  private fetchMe(): Observable<HubtypeUser> {
    return this.apiService
      .get('/users/me/', null, 'v2')
      .pipe(
        map(response =>
          this.convertService.jsonConvert.deserializeObject(
            response,
            HubtypeUser
          )
        )
      )
  }
  private fetchUsers(): Observable<HubtypeUser[]> {
    return this.apiService
      .get('/users', null, 'v2')
      .pipe(
        map(response =>
          this.convertService.jsonConvert.deserializeArray(
            response.results,
            HubtypeUser
          )
        )
      )
  }
}
