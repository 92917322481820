import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FlexLayoutModule } from '@angular/flex-layout'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { SharedBrandModule } from 'shared/shared-brand/shared-brand.module'
import { SharedCoreModule } from 'shared/shared-core/shared-core.module'
import { SharedDataModule } from 'shared/shared-data/shared-data.module'
import { AppMaterialModule } from '../app-material.module'
import { SharedComponentModule } from '../shared/shared-components.module'
import { AppleIntegrationComponent } from './apple-integration.component'
import { ProviderIntegrationRoutingModule } from './provider-integration-routing.module'

@NgModule({
  declarations: [AppleIntegrationComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    SharedComponentModule,
    ProviderIntegrationRoutingModule,
    AppMaterialModule,
    SharedCoreModule,
    SharedDataModule,
    SharedBrandModule,
  ],
})
export class ProviderIntegrationModule {}
