import { HttpParams } from '@angular/common/http'
import { Inject, Injectable } from '@angular/core'
import moment from 'moment'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { HubtypeSLAs } from '../../models/hubtype-slas'
import { ConverterService } from '../converter.service'
import { CustomQueryEncoderHelper } from './auth.service'
import { HubtypeApiService } from './hubtype-api.service'

@Injectable()
export class SLAsService {
  constructor(
    @Inject('apiService') private apiService: HubtypeApiService,
    @Inject('convertService') private convertService: ConverterService
  ) {}

  // GET: /slas/v2
  getSLAs(
    start_date?: number,
    end_date?: number,
    projects?: string[],
    queues?: string[],
    agents?: string[],
    providers?: string[],
    period?: string
  ): Observable<HubtypeSLAs> {
    const url = '/slas_opt/'
    let params: HttpParams = new HttpParams({
      encoder: new CustomQueryEncoderHelper(),
    })
    if (start_date) {
      params = params.set(
        'start_date',
        moment(start_date).startOf('day').toISOString(true)
      )
    }
    if (end_date) {
      params = params.set(
        'end_date',
        moment(end_date).endOf('day').toISOString(true)
      )
    }
    if (projects && projects.length) {
      projects.forEach(project => {
        params = params.append('project_id', project)
      })
    }
    if (queues && queues.length) {
      queues.forEach(queue => {
        params = params.append('queue_id', queue)
      })
    }
    if (agents && agents.length) {
      agents.forEach(agent => {
        params = params.append('agent_id', agent)
      })
    }
    if (providers && providers.length) {
      providers.forEach(provider => {
        params = params.append('provider', provider)
      })
    }
    if (period) {
      params = params.set('period', period)
    }

    return this.apiService
      .get(url, params, 'v2')
      .pipe(
        map(response =>
          this.convertService.jsonConvert.deserializeObject(
            response,
            HubtypeSLAs
          )
        )
      )
    // .map(result => plainToClass<HubtypeSLAs, Object>(HubtypeSLAs, result));
  }
}
