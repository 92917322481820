import { Pipe, PipeTransform } from '@angular/core'
import { limitLength } from '../utils/string-utils'

@Pipe({
  name: 'truncate',
})
export class TruncatePipe implements PipeTransform {
  transform(value: string, ...args: any[]): any {
    const limit: number = (args[0] && Number(args[0])) || value.length
    return limitLength(value, limit)
  }
}
