import { Injectable } from '@angular/core'
import { CanActivate, Router } from '@angular/router'
import { Store } from '@ngrx/store'
import { HubtypeUser } from 'models/hubtype-user'
import { Observable } from 'rxjs'
import { filter, map } from 'rxjs/operators'
import * as fromRoot from '../reducers'

@Injectable({
  providedIn: 'root',
})
export class MustChangePasswordGuard implements CanActivate {
  constructor(
    private router: Router,
    private store: Store<fromRoot.State>
  ) {}

  canActivate(): Observable<boolean> {
    return this.store.select(fromRoot.getMeState).pipe(
      filter(Boolean),
      map((user: HubtypeUser) => {
        if (user.must_change_password) {
          this.router.navigate(['/auth/reset-password'])
        }
        return !user.must_change_password
      })
    )
  }
}
