import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router'
import { BehaviorSubject } from 'rxjs'
import { filter } from 'rxjs/operators'
import {
  Breadcrumb,
  BreadcrumbList,
} from 'shared/base/ht-breadcrumb/ht-breadcrumb.component'

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {
  public $breadcrumbs = new BehaviorSubject<Breadcrumb[]>([])

  constructor(private router: Router) {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        this.loadBreadCrumbs()
      })
  }

  setActiveBreadcrumb(newValue) {
    const brs = this.getBreadcrumbs(this.router.routerState.snapshot.root)
    const last = brs.length - 1
    if (Array.isArray(brs[last].content)) {
      // eslint-disable-next-line no-extra-semi
      ;(brs[last].content as BreadcrumbList[]).forEach(element => {
        if (element.active) {
          element.text = newValue
        }
      })
    } else {
      brs[last].content = newValue
    }
    this.refreshBreadCrumbs(brs)
  }

  loadBreadCrumbs() {
    const brs = this.getBreadcrumbs(this.router.routerState.snapshot.root)
    this.refreshBreadCrumbs(brs)
  }
  private refreshBreadCrumbs(brs: Breadcrumb[]) {
    this.$breadcrumbs.next(brs)
  }

  private getBreadcrumbs(
    route: ActivatedRouteSnapshot,
    totalBreadcrumbs: Breadcrumb[] = []
  ) {
    if (
      route.data &&
      route.url.length > 0 &&
      route.data.breadcrumbs &&
      Array.isArray(route.data.breadcrumbs)
    ) {
      route.data.breadcrumbs.forEach((breadcrumb, index) => {
        if (breadcrumb.ref) {
          const list: BreadcrumbList[] = route.data[breadcrumb.ref]
          //Greater than 1, is a dropdown
          if (list?.length > 1) {
            const br: Breadcrumb = {
              content: list,
              type: breadcrumb.type,
            }
            totalBreadcrumbs.push(br)
          }
          //Equal than 1, is a string
          else if (list?.length === 1) {
            const br: Breadcrumb = {
              content: list[0].text,
              type: 'string',
              link: list[0].link,
            }
            totalBreadcrumbs.push(br)
          }
        } else {
          const br: Breadcrumb = {
            link: breadcrumb.link,
            content: breadcrumb.label,
          }
          totalBreadcrumbs.push(br)
        }
      })
    }

    if (route.firstChild) {
      this.getBreadcrumbs(route.firstChild, totalBreadcrumbs)
    }
    return totalBreadcrumbs
  }
}
