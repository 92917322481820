import { Inject, Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { iif, of } from 'rxjs'
import { catchError, map, switchMap } from 'rxjs/operators'
import {
  USERS_ERROR,
  USERS_GET,
  USERS_READY,
  USERS_RECEIVE,
} from 'src/app/actions/shared'
import { selectUsers, State } from 'src/app/reducers'
import { UserService } from '../hubtype-api/user.service'

@Injectable({
  providedIn: 'root',
})
export class UserEffects {
  allUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(USERS_GET),
      switchMap(() => this.store.select(selectUsers)),
      switchMap(users =>
        iif(
          () => Boolean(users.length),
          of({
            type: USERS_READY,
            payload: null,
          }),
          this.userService.getUsers(true).pipe(
            map(result => ({
              type: USERS_RECEIVE,
              payload: result,
            })),
            catchError(err =>
              of({
                type: USERS_ERROR,
                payload: err,
              })
            )
          )
        )
      )
    )
  )

  constructor(
    private actions$: Actions,
    private store: Store<State>,
    @Inject('userService') private userService: UserService
  ) {}
}
