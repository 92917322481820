import { Pipe, PipeTransform } from '@angular/core'
import { Markdown } from '../utils/markdown'

@Pipe({
  name: 'markdown',
})
export class MarkdownPipe implements PipeTransform {
  transform(value: any): any {
    if (value && value.length > 0) {
      const md = Markdown.getMarkdown()
      return md.render(value)
    }
    return value
  }
}
