import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core'
import { Subscription } from 'rxjs'
import { AngularComponent } from 'shared/AngularComponent'
import {
  AppVersionService,
  VersionInfo,
} from 'src/app/services/app-version.service'

@Component({
  selector: 'app-version',
  templateUrl: './app-version.component.html',
  styleUrls: ['./app-version.component.scss'],
})
export class AppVersionComponent
  extends AngularComponent
  implements OnInit, OnDestroy
{
  public versionInfo: VersionInfo
  private subscription: Subscription
  @Input() autoRefresh: false

  constructor(
    @Inject('appVersionService') private appVersionService: AppVersionService
  ) {
    super()
  }

  ngOnInit() {
    this.subscription = this.appVersionService.observe(
      versionInfo => {
        this.versionInfo = versionInfo
        if (this.autoRefresh && this.versionInfo.isOutdated) {
          this.updateApp()
        }
      },
      err => console.error('Error getting app version updates.', err)
    )
    this.appVersionService.startCheckIsOutdated()
  }

  checkIsOutdated() {
    this.appVersionService.fetchLatestVersion().subscribe()
  }

  updateApp() {
    console.log('Updating app...')
    if (location.href.indexOf('?') < 0) {
      location.href = location.href + `?v=${new Date().getTime()}`
    } else {
      location.href = location.href + `&v=${new Date().getTime()}`
    }
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe()
  }
}
