import { JsonObject, JsonProperty } from 'json2typescript'
import { DateConverter } from 'utils/json-utils'

@JsonObject
export class HubtypeActiveUsers {
  static readonly MAUS_CUTOFF_YEAR = 2018

  @JsonProperty('maus', Number, false)
  public maus = 0
  @JsonProperty('start_date', DateConverter, false)
  public startDate: Date = undefined
  @JsonProperty('end_date', DateConverter, false)
  public endDate: Date = undefined
}
