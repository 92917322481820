import produce, { Draft } from 'immer'
import { Task } from 'shared/entities/task'
import { deserializeArray } from 'utils/json-utils'
import { LOGOUT } from '../actions/auth'
import {
  TASK_DELETE,
  TASK_FINISH,
  TASK_PUSH_NEW,
  TASK_UPDATE,
} from '../actions/tasks'
import { RunnableTask } from './runnable-task'

type TasksState = RunnableTask[]
export const initialState: TasksState = []

export function deserialize(json: any[]): TasksState {
  if (!json) {
    return []
  }
  return deserializeArray(json, RunnableTask)
}

export function reducer(state?: TasksState, action: any = {}) {
  return produce((draftState: Draft<TasksState>, appraisalAction: any) => {
    let task: Task
    switch (appraisalAction.type) {
      case TASK_PUSH_NEW:
        task = appraisalAction.payload as Task
        if (shouldOverrideTask(task.getName(), draftState)) {
          return overrideTask(task, draftState)
        }
        draftState.push(RunnableTask.fromTaskEntity(appraisalAction.payload))
        return draftState
      case TASK_DELETE:
        return removeTask(appraisalAction.payload, draftState)
      case TASK_FINISH:
        task = appraisalAction.payload as Task
        task.finish()
        return overrideTask(task, draftState)
      case TASK_UPDATE:
        return overrideTask(appraisalAction.payload, draftState)
      case LOGOUT:
        return initialState
      default:
        return draftState
    }
  }, initialState)(state, action)
}

function overrideTask(task: Task, state: Draft<TasksState>): Draft<TasksState> {
  const index = state.findIndex(t => t.id === task.taskId)
  if (index >= 0) {
    state.splice(index, 1, RunnableTask.fromTaskEntity(task))
  }
  return state
}

function removeTask(
  taskId: string,
  state: Draft<TasksState>
): Draft<TasksState> {
  const index = state.findIndex(t => t.id === taskId)
  if (index >= 0) {
    state.splice(index, 1)
  }
  return state
}

function shouldOverrideTask(
  taskName: string,
  state: Draft<TasksState>
): boolean {
  const task = state.find(t => t.name === taskName)
  return task?.isFinished || task?.hasError
}
