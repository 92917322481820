import { HttpParams } from '@angular/common/http'
import { Inject, Injectable } from '@angular/core'
import moment from 'moment'
import { Observable } from 'rxjs/internal/Observable'
import { map } from 'rxjs/operators'
import { JsonFor } from '../../utils/json-utils'
import { ConverterService } from '../converter.service'
import { CustomQueryEncoderHelper } from './auth.service'
import {
  BackwardCompatCase,
  CaseQuery,
  HubtypeCasePaginator,
} from './case-search.service.dtos'
import { HubtypeApiService } from './hubtype-api.service'

@Injectable()
export class CaseSearchService {
  constructor(
    @Inject('apiService') protected apiService: HubtypeApiService,
    @Inject('convertService') protected convertService: ConverterService
  ) {}

  // GET /case-archive/
  search(cq: CaseQuery, cursor?: string): Observable<HubtypeCasePaginator> {
    const path = '/case-archive/'
    let params = this.setQueryParams(cq)
    if (cursor) {
      params = params.set('cursor', cursor)
    }

    return this.apiService
      .get(path, params, 'v3')
      .pipe(
        map((response: JsonFor<HubtypeCasePaginator>) =>
          this.convertService.jsonConvert.deserializeObject(
            BackwardCompatCase.fixPaginator(response),
            HubtypeCasePaginator
          )
        )
      )
  }

  // GET /cases/export_cases/
  exportCases(cq: CaseQuery, extension = 'csv') {
    const path = '/cases/export_cases/'

    let params = this.setQueryParams(cq)
    if (extension) {
      params = params.set('extension', extension)
    }

    return this.apiService.get(path, params, 'v2')
  }

  private setQueryParams(cq: CaseQuery): HttpParams {
    let params = new HttpParams({
      encoder: new CustomQueryEncoderHelper(),
    })
    if (cq.query) {
      params = params.set('q', cq.query)
    }
    if (cq.start_date) {
      params = params.set(
        'start_date',
        moment(cq.start_date).startOf('day').toISOString(true)
      )
    }
    if (cq.end_date) {
      params = params.set(
        'end_date',
        moment(cq.end_date).endOf('day').toISOString(true)
      )
    }
    if (cq.projects && cq.projects.length) {
      cq.projects.forEach(project => {
        params = params.append('project_id', project)
      })
    }
    if (cq.queues && cq.queues.length) {
      cq.queues.forEach(queue => {
        params = params.append('queue_id', queue)
      })
    }
    if (cq.agents && cq.agents.length) {
      cq.agents.forEach(agent => {
        params = params.append('agent_id', agent)
      })
    }
    if (cq.statuses && cq.statuses.length) {
      cq.statuses.forEach(status => {
        params = params.append('status', status)
      })
    }
    if (cq.providers && cq.providers.length) {
      cq.providers.forEach(provider => {
        params = params.append('provider', provider)
      })
    }
    if (cq.contactReasons && cq.contactReasons.length) {
      cq.contactReasons.forEach(contactReason => {
        params = params.append('contact_reasons', contactReason)
      })
    }
    return params
  }
}
