import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core'
import { UntypedFormControl } from '@angular/forms'
import { setServerErrors } from 'models/form'
import {
  Action,
  ActionDeactivated,
  SELECTED_CONTROL_NAME,
  TableConfig,
} from 'shared/base/ht-table/ht-table.component'
import { TableRow } from './../ht-table.component'

@Component({
  selector: '[table-row]',
  templateUrl: './table-row.component.html',
  styleUrls: ['./table-row.component.scss'],
  host: {
    role: 'row',
    '(click)': 'handleClick($event)',
    '(keydown.enter)': 'handleClick($event)',
    '(keydown.space)': 'handleClick($event)',
  },
})
export class TableRowComponent implements OnInit, OnChanges {
  @HostBinding('class.is-clickable') isClickable = false
  @HostBinding('class.is-editing') isEditing = false
  @HostBinding('class.is-selected') isSelected = false
  @Input()
  tableConfig: TableConfig
  @Input()
  editId: string
  @Input()
  index: string
  @Input()
  row: TableRow

  @HostBinding('class.is-active')
  @Input()
  isActive: boolean

  @Output()
  rowClick = new EventEmitter<Event>()
  @Output()
  expandToggle = new EventEmitter<TableRow>()

  formValueAfterEdit: any
  rowActions: Action[]

  @HostBinding('class.is-disabled') get isDisabled() {
    return this.selectFormControl.disabled
  }

  @HostBinding('class.is-expanded') get isExpanded() {
    return this.row.isExpanded
  }

  @HostBinding('attr.tabindex') get tabIndex() {
    return this.isClickable ? 0 : -1
  }

  get selectFormControl() {
    return (
      this.row.formGroup.controls[SELECTED_CONTROL_NAME] ||
      new UntypedFormControl()
    )
  }

  handleClick = event => {
    event.stopPropagation()
    if (this.row.expandable) {
      this.toggleExpand()
    }

    if (this.isClickable || !this.selectFormControl.disabled) {
      this.rowClick.emit(this.row.value)
    }

    if (!this.selectFormControl.disabled) {
      this.isSelected =
        this.row.formGroup.controls[SELECTED_CONTROL_NAME]?.value || false
    }
  }

  clickAction = (event, action: Action) => {
    event.stopPropagation()
    action.onClick(this.row.value, this.row)
  }

  isRowActionDeactivated = (
    isDeactivated: ActionDeactivated = false,
    value: any
  ) => {
    if (typeof isDeactivated === 'function') {
      return isDeactivated(value)
    }
    return isDeactivated
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.editId) {
      this.updateIsEditing()
    }
    if (changes.row) {
      this.updateRowActions()
    }
  }

  ngOnInit(): void {
    this.isClickable =
      this.tableConfig?.clickable ||
      (!this.selectFormControl.disabled &&
        (!!this.tableConfig?.onRowClick ||
          !!this.tableConfig?.selectable ||
          !!this.row.expandable))
  }

  onRestoreOnRow(): void {
    if (this.editId === null) {
      //Just delete new item
    } else if (this.row.formGroup.touched) {
      //Restore old data
      this.row.formGroup.reset()
      this.row.formGroup.setValue(this.formValueAfterEdit)
    }
    this.row.toggleEdit()
  }

  onSaveOnRow() {
    //Refresh control validation. This is because if you have an invalid message on input,
    //and after that change a dropdown, the invalid input message remains an error
    const formGroup = this.row.formGroup
    Object.keys(formGroup.controls).forEach(controlIndex => {
      formGroup.controls[controlIndex].updateValueAndValidity()
    })

    if (formGroup.valid) {
      this.tableConfig.onSaveOnRow(formGroup.value).subscribe({
        next: onResult => {
          this.row.toggleEdit()
        },
        error: err => {
          const inlineErrors = setServerErrors(err, this.row.formGroup.controls)
          if (!inlineErrors.length) {
            throw new Error(err || 'Error')
          }
        },
      })
    } else {
      formGroup.markAllAsTouched()
    }
  }

  toggleExpand() {
    this.row.isExpanded = !this.row.isExpanded
    this.expandToggle.emit(this.row)
  }

  private updateIsEditing = () => {
    this.isEditing = this.editId === this.row.id
    if (this.isEditing) {
      this.formValueAfterEdit = this.isEditing
        ? this.row.formGroup.value
        : undefined
    }
  }

  private updateRowActions = () => {
    if (this.tableConfig.actions) {
      this.rowActions = this.tableConfig.actions.map(action => ({
        ...action,
        isDeactivated:
          typeof action.isDeactivated === 'function'
            ? action.isDeactivated(this.row.value)
            : action.isDeactivated,
      }))
    }
  }
}
