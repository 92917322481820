import { Folder, FolderItemProps } from './folder'
import { HubtypeFolder } from './hubtype-folder'

export class TreeFolder<T> extends Folder<T> {
  public hidden = false
  public levelIndex = 0
  public open = false
  public selected = false

  constructor(model: HubtypeFolder) {
    super(model)
  }

  static setSelected(node: TreeFolder<any>, value: boolean) {
    if (node) {
      node.selected = value
    }
  }

  getParent(): TreeFolder<T> {
    return this.parent as TreeFolder<T>
  }

  toTreeFolderOf<U>(newItemProps?: FolderItemProps): TreeFolder<U> {
    const copy = Object.assign(new TreeFolder<U>({}), this)
    copy.setItems([])
    if (newItemProps) {
      copy.setItemProps(newItemProps)
    }
    return copy
  }
}
