<ng-container *ngIf="$breadcrumbs | async as breadcrumbs">
  <ng-container *ngIf="breadcrumbs.length">
    <div class="breadcrumb">
      <ng-container *ngFor="let breadcrumb of breadcrumbs; let last = last">
        <!--String link-->
        <a
          [routerLink]="breadcrumb.link ? breadcrumb.link : null"
          *ngIf="!breadcrumb.type || breadcrumb.type === 'string'"
          >{{ breadcrumb.content }}</a
        >

        <!--Dropdown -->
        <div *ngIf="breadcrumb.type === 'list'" class="dropdown-item">
          <ng-container *ngFor="let bread of breadcrumb.content">
            <ng-container *ngIf="bread.active === true">
              <span>
                <div class="text-small">{{ bread.text }}</div>
                <i class="fa-solid fa-angle-down fa-xs"></i>
              </span>
            </ng-container>
          </ng-container>

          <div class="dropdown-list">
            <a
              *ngFor="let breadContent of breadcrumb.content"
              [ngClass]="breadContent.active ? 'active' : ''"
              [routerLink]="breadContent.link"
            >
              {{ breadContent.text }}
            </a>
          </div>
        </div>

        <div *ngIf="!last" class="division">/</div>
      </ng-container>
    </div>
  </ng-container>
</ng-container>
