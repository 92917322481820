import { Action } from '@ngrx/store'

export const TOOGLE_NAVBAR_COLLAPSE = '[Desk] Toogle navbar collapse'

export class ToogleNavbarCollapseAction implements Action {
  readonly type = TOOGLE_NAVBAR_COLLAPSE
  constructor() {}
}

export type Actions = ToogleNavbarCollapseAction
