import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class RestService {
  constructor(private http: HttpClient) {}

  request(
    method: string,
    uri: string,
    options: { [param: string]: any } = {}
  ): Observable<any> {
    if (options?.body) {
      options.body = this.assertRequestBody(options.body)
    }
    options.headers = this.assertRequestHeaders(options)
    return this.http.request(method, uri, options)
  }

  private assertRequestBody(body: any): string | FormData {
    if (typeof body === 'string' || body instanceof FormData) {
      return body
    }
    return JSON.stringify(body)
  }

  private assertRequestHeaders(raw: { [param: string]: any }): HttpHeaders {
    // Beware that HttpHeaders is immutable
    let headers =
      raw.headers?.constructor.name === 'HttpHeaders'
        ? raw.headers
        : new HttpHeaders(raw['headers'])
    if (typeof raw.body === 'string') {
      headers = headers.append('Content-Type', 'application/json')
    }
    return headers
  }
}
