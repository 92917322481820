import { Inject, Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { HubtypeCase } from 'models/hubtype-case'
import { HubtypeQueue } from 'models/hubtype-queue'
import { iif, of } from 'rxjs'
import {
  catchError,
  filter,
  map,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators'
import { CLOSE_CASE_CHAT_WINDOW } from 'src/app/actions/desk'
import { DELETE_QUEUE } from 'src/app/actions/organization'
import {
  CASE_QUEUES_ERROR,
  CASE_QUEUES_GET,
  CASE_QUEUES_RECEIVE,
} from 'src/app/actions/shared'
import { getSelectedCase, selectAllQueues, State } from 'src/app/reducers'
import { QueueService } from '../hubtype-api/queue.service'

@Injectable({
  providedIn: 'root',
})
export class QueueEffects {
  allQueues$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CASE_QUEUES_GET),
      switchMap(() => this.store.select(selectAllQueues)),
      switchMap(queues =>
        iif(
          () => Boolean(queues.length),
          of({
            type: CASE_QUEUES_RECEIVE,
            payload: queues,
          }),
          this.queueService.getAll(true).pipe(
            map(result => ({
              type: CASE_QUEUES_RECEIVE,
              payload: result,
            })),
            catchError(err =>
              of({
                type: CASE_QUEUES_ERROR,
                payload: err,
              })
            )
          )
        )
      )
    )
  )

  deleteQueue$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DELETE_QUEUE),
      withLatestFrom(this.store.select(getSelectedCase)),
      map(
        ([action, selectedCase]: [{ payload: HubtypeQueue }, HubtypeCase]) => ({
          queue: action.payload,
          selectedCase,
        })
      ),
      filter(
        ({ queue, selectedCase }) => Boolean(selectedCase) && Boolean(queue)
      ),
      switchMap(({ queue, selectedCase }) => {
        if (queue.id === selectedCase.queue_id) {
          return of({
            type: CLOSE_CASE_CHAT_WINDOW,
            payload: true,
          })
        }
      })
    )
  )

  constructor(
    private actions$: Actions,
    private store: Store<State>,
    @Inject('queueService') private queueService: QueueService
  ) {}
}
