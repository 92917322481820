<form [formGroup]="form" class="ht-form" (ngSubmit)="onSubmit()">
  <div class="ht-form-item">
    <div class="ht-form-item-title">Email</div>
    <div class="ht-form-item-control">
      <ht-input
        label="Email"
        type="email"
        formControlName="email"
        [fullWidth]="true"
      ></ht-input>
    </div>
  </div>

  <div class="ht-form-item">
    <div class="ht-form-item-title">Password</div>
    <div class="ht-form-item-control">
      <ht-input
        label="Password"
        type="password"
        formControlName="password"
        [fullWidth]="true"
      ></ht-input>
    </div>
    <div class="help-text">Minimum 12 character long</div>
  </div>

  <div class="ht-form-item">
    <div class="ht-form-item-title">Repeat password</div>
    <div class="ht-form-item-control">
      <ht-input
        label="Repeat password"
        type="password"
        formControlName="checkPassword"
        [fullWidth]="true"
      ></ht-input>
    </div>
  </div>

  <div class="ht-form-item">
    <div class="ht-form-item-title">Organization</div>
    <div class="ht-form-item-control">
      <ht-input
        label="Organization"
        type="text"
        formControlName="organization"
        [fullWidth]="true"
      ></ht-input>
    </div>
    <div class="help-text">You can change this later</div>
  </div>

  <div class="ht-form-item">
    <div class="ht-form-item-control">
      <ht-checkbox
        label="I agree to Terms of service and Privacy Policy"
        formControlName="agree"
      >
        I agree to Hubtype's
        <a
          href="https://uploads-ssl.webflow.com/5fbd0d2e5cfe50b164243272/60195d14568b9bb23bad6f9a_Hubtype_Platform_Terms_of_Use.pdf"
          target="_blank"
        >
          Terms of service
        </a>
        and
        <a
          href="https://uploads-ssl.webflow.com/5fbd0d2e5cfe50b164243272/615ead909a291d10c240e33c_20180524_Hubtype_Privacy_Policy%20(1).pdf"
          target="_blank"
        >
          Privacy Policy
        </a>
      </ht-checkbox>
    </div>
    <div class="ht-form-item-control">
      <ht-checkbox label="Receive updates" formControlName="updates">
        I'd like to receive updates from Hubtype's products and services
      </ht-checkbox>
    </div>
  </div>

  <div class="ht-form-row" *ngIf="form.errors?.serverError">
    <div class="error-alert">
      <i class="fa-solid fa-circle-xmark"></i>
      <span>{{ form.errors?.serverError }}</span>
    </div>
  </div>

  <div class="ht-form-actions">
    <button
      ht-button-primary
      type="submit"
      size="large"
      [fullWidth]="true"
      #submitButton
    >
      Sign up
    </button>
  </div>
</form>
