import { Injectable, Renderer2, RendererFactory2 } from '@angular/core'
import { ColorScheme } from '../models/hubtype-color-scheme'

const THEME_PREFERENCE_KEY = 'prefers-color'
const DARK_PURPLE = 'dark-purple'
const LIGHT_BLUE = 'light-blue'
const LIGHT_PURPLE = 'light-purple'

@Injectable({
  providedIn: 'root',
})
export class ColorSchemeService {
  static readonly DEFAULT_COLOR = LIGHT_BLUE
  static readonly AVAILABLE_SCHEMES = {
    [LIGHT_PURPLE]: {
      name: 'Aubergine',
      value: LIGHT_PURPLE,
    },
    [DARK_PURPLE]: {
      name: 'Aubergine Night',
      value: DARK_PURPLE,
    },
    [LIGHT_BLUE]: {
      name: 'Sea (Default)',
      value: LIGHT_BLUE,
    },
  }

  private renderer: Renderer2
  private colorScheme: string
  // Define prefix for clearer and more readable class names in scss files
  private colorSchemePrefix = 'color-scheme-'

  constructor(rendererFactory: RendererFactory2) {
    // Create new renderer from renderFactory, to make it possible to use renderer2 in a service
    this.renderer = rendererFactory.createRenderer(null, null)
  }

  currentActive(): ColorScheme {
    return Object.assign(ColorSchemeService.AVAILABLE_SCHEMES[this.colorScheme])
  }

  getAvailableSchemes(): ColorScheme[] {
    return Object.values(ColorSchemeService.AVAILABLE_SCHEMES)
  }

  load(): void {
    this.getColorScheme()
    this.renderer.addClass(
      document.body,
      this.colorSchemePrefix + this.colorScheme
    )
  }

  update(scheme: string): void {
    const currentScheme = this.colorScheme
    this.setColorScheme(scheme)

    // Remove the old color-scheme class
    this.renderer.removeClass(
      document.body,
      this.colorSchemePrefix + currentScheme
    )
    // Add the new / current color-scheme class
    this.renderer.addClass(document.body, this.colorSchemePrefix + scheme)
  }

  private getColorScheme(): void {
    if (localStorage.getItem(THEME_PREFERENCE_KEY)) {
      this.colorScheme = localStorage.getItem(THEME_PREFERENCE_KEY)
    } else {
      this.colorScheme = ColorSchemeService.DEFAULT_COLOR
    }
  }

  private setColorScheme(scheme: string): void {
    this.colorScheme = scheme
    localStorage.setItem(THEME_PREFERENCE_KEY, scheme)
  }
}
