import { JsonObject, JsonProperty } from 'json2typescript'
import { DropdownData } from 'shared/base/ht-dropdown/ht-dropdown.component'

export const DEFAULT_CONTACT_REASON_CATEGORY: HubtypeContactReasonCategory = {
  id: 'default-contact-reason-category',
  name: 'No category',
}
export const SYSTEM_CONTACT_REASONS = [
  'discarded',
  'discarded_by_user',
  'discarded_by_system',
]

export enum TopTenContactReasonsOrderBy {
  CASES = 'num_cases',
  AVERAGE_RATING = 'avg_rating',
}

export const TOP_TEN_CONTACT_REASONS_ORDER_BY_OPTIONS: DropdownData[] = [
  {
    id: TopTenContactReasonsOrderBy.CASES,
    value: 'Number of cases',
  },
  {
    id: TopTenContactReasonsOrderBy.AVERAGE_RATING,
    value: 'Highest CSAT',
  },
]

@JsonObject
export class HubtypeContactReason {
  @JsonProperty('id', String, false)
  public id: string = undefined

  @JsonProperty('name', String, false)
  public name?: string = undefined

  @JsonProperty('category_id', String, true)
  public categoryId?: string = undefined

  @JsonProperty('created_by_system', Boolean, true)
  public created_by_system?: boolean = false

  constructor() {}
}

@JsonObject
export class HubtypeContactReasonCategory {
  @JsonProperty('id', String, false)
  public id: string = undefined

  @JsonProperty('name', String, false)
  public name: string = undefined

  @JsonProperty('contact_reasons', [HubtypeContactReason], true)
  public contact_reasons?: HubtypeContactReason[] = []

  constructor() {}
}

@JsonObject
export class HubtypeContactReasonTopItem {
  @JsonProperty('contact_reason_id', String, false)
  public contactReasonId: string = undefined

  @JsonProperty('contact_reason_name', String, false)
  public contactReasonName: string = undefined

  @JsonProperty('project_name', String, false)
  public projectName: string = undefined

  @JsonProperty('num_cases', Number, false)
  public numCases: number = undefined

  @JsonProperty('num_rated_cases', Number, false)
  public numRatedCases: number = undefined

  @JsonProperty('avg_rating', Number, true)
  public avgRating: number = undefined

  constructor(contactReasonName = '', numCases = 0) {
    this.contactReasonName = contactReasonName
    this.numCases = numCases
  }
  getPropertyGivenOrderBy(orderBy: TopTenContactReasonsOrderBy) {
    switch (orderBy) {
      case TopTenContactReasonsOrderBy.CASES:
        return this.numCases
      case TopTenContactReasonsOrderBy.AVERAGE_RATING:
        return this.avgRating
    }
  }
}

export const sortContactReasonCategories = (a, b) => {
  if (a.name === DEFAULT_CONTACT_REASON_CATEGORY.name) {
    return -1
  }
  if (b.name === DEFAULT_CONTACT_REASON_CATEGORY.name) {
    return 1
  }

  return a.name.localeCompare(b.name)
}
