import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { CompleteProfileComponent } from 'shared/complete-profile/complete-profile.component'
import { NoAuthPageComponent } from 'shared/no-auth-page/no-auth-page.component'
import { OneTimeLoginComponent } from 'shared/one-time-login/one-time-login.component'
import { SetNewPasswordComponent } from 'shared/set-new-password/set-new-password.component'
import { SALESFORCE_ROOT_PATH } from './constants/integrations'
import { AuthGuard } from './services/auth-guard.service'
import { CampaignsGuard } from './services/campaigns.guard'
import { MustChangePasswordGuard } from './services/must-change-password.guard'
import {
  AdminRoleGuard,
  ManagerOrAdminRoleGuard,
} from './services/role-guard.service'
import { HubtypeAIUrl } from './constants'

const appRoutes: Routes = [
  // This breaks AOT compiler, solution: https://github.com/angular/angular-cli/issues/4192
  // { path: 'bots', loadChildren: () => BotsModule, canActivate: [AuthGuard]},
  // { path: 'desk', loadChildren: () => DeskModule, canActivate: [AuthGuard]},
  // { path: 'organization', loadChildren: () => OrganizationModule, canActivate: [AuthGuard]}, //TODO Only admin users
  {
    path: 'bots',
    loadChildren: () => import('./bots/bots.module').then(m => m.BotsModule),
    canActivate: [AuthGuard, AdminRoleGuard, MustChangePasswordGuard],
  },
  {
    path: 'ai',
    loadChildren: () => import('./ai/ai.module').then(m => m.AIModule),
    canActivate: [AuthGuard, AdminRoleGuard, MustChangePasswordGuard],
    data: {
      breadcrumbs: [{ label: 'AI', link: [HubtypeAIUrl.IntentModels] }],
    },
  },
  {
    path: 'campaigns',
    loadChildren: () =>
      import('./campaigns/campaigns.module').then(m => m.CampaignsModule),
    data: { breadcrumbs: [{ label: 'Campaigns', link: '/campaigns' }] },
    canActivate: [AuthGuard, CampaignsGuard, MustChangePasswordGuard],
  },
  {
    path: 'desk',
    loadChildren: () => import('./desk/desk.module').then(m => m.DeskModule),
    canActivate: [AuthGuard, MustChangePasswordGuard],
  },
  {
    path: 'analytics',
    loadChildren: () =>
      import('./insights/insights.module').then(m => m.InsightsModule),
    canActivate: [AuthGuard, ManagerOrAdminRoleGuard, MustChangePasswordGuard],
    data: { breadcrumbs: [{ label: 'Analytics', link: '/analytics' }] },
  },
  {
    path: 'upgrades',
    loadChildren: () =>
      import('./upgrades/upgrades.module').then(m => m.UpgradesModule),
    canActivate: [AuthGuard, ManagerOrAdminRoleGuard, MustChangePasswordGuard],
    data: { breadcrumbs: [{ label: 'Upgrades', link: '/upgrades' }] },
  },
  {
    path: 'organization',
    loadChildren: () =>
      import('./organization/organization.module').then(
        m => m.OrganizationModule
      ),
    canActivate: [AuthGuard, ManagerOrAdminRoleGuard, MustChangePasswordGuard],
    data: {
      breadcrumbs: [
        { label: 'Organization Settings', link: ['/organization'] },
      ],
    },
  }, // TODO Only admin users
  {
    path: 'profile',
    loadChildren: () =>
      import('./profile/profile.module').then(m => m.ProfileModule),
    canActivate: [AuthGuard, MustChangePasswordGuard],
  },
  {
    path: 'reset-password',
    component: NoAuthPageComponent,
    children: [{ path: '', component: OneTimeLoginComponent }],
  },
  {
    path: 'forgot-password',
    component: NoAuthPageComponent,
    children: [{ path: '', component: OneTimeLoginComponent }],
  },
  {
    path: 'create-password',
    component: NoAuthPageComponent,
    children: [
      {
        path: '',
        component: OneTimeLoginComponent,
        data: { isAnInvite: true },
      },
    ],
  },
  {
    path: 'auth',
    component: NoAuthPageComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'reset-password',
        component: SetNewPasswordComponent,
        data: { isAnInvite: false },
      },
      {
        path: 'create-password',
        component: SetNewPasswordComponent,
        data: { isAnInvite: true },
      },
    ],
  },
  {
    path: 'complete-profile',
    component: NoAuthPageComponent,
    canActivate: [AuthGuard],
    children: [{ path: '', component: CompleteProfileComponent }],
  },
  { path: '', redirectTo: 'desk', pathMatch: 'full' },
  {
    path: SALESFORCE_ROOT_PATH,
    redirectTo: 'desk',
    pathMatch: 'full',
  },
  {
    path: 'design-system',
    loadChildren: () =>
      import('./design-system/design-system.module').then(
        m => m.DesignSystemModule
      ),
  },
]

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
