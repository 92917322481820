/* eslint-disable no-inner-declarations */
import { environment } from 'environment/environment'
import { HubtypeUser } from 'models/hubtype-user'
import { AnalyticsEvents } from '../constants/analytics'

declare global {
  interface Window {
    analytics: any
  }
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace Analytics {
  enum EventType {
    Event = 'event',
    IdentifyUser = 'user',
    PageView = 'page',
  }

  let organizationId

  function isDefined(): boolean {
    return Boolean(window.analytics)
  }

  function track(id: string, type: EventType, data?: any) {
    if (!isDefined()) {
      return
    }
    switch (type) {
      case EventType.Event:
        window.analytics.track(id, data)
        return
      case EventType.IdentifyUser:
        window.analytics.identify(id, data)
        return
      case EventType.PageView:
        window.analytics.page(id, data)
        return
      default:
        console.error(`Track of invalid type invoked ${type}`)
    }
  }

  export function event(id: string, data: any = {}) {
    const analyticsId = environment.segment_token
    if (!analyticsId) {
      // eslint-disable-next-line no-console
      console.info('Event', id, data)
    }
    track(id, EventType.Event, {
      ...data,
      organizationId,
    })
  }

  export function load() {
    if (!isDefined()) {
      console.error('Missing scripts to load Analytics feature')
      return
    }
    const analyticsId = environment.segment_token
    if (!analyticsId) {
      console.warn(
        'Missing Analytics token. Analytics will not report any data'
      )
    }
    window.analytics.load(analyticsId)
  }

  export function user(userId: string, data: HubtypeUser) {
    const user = {
      userId: data.id,
      organizationId: data.organization_id,
      role: data.roleName,
    }
    organizationId = data.organization_id
    track(userId, EventType.IdentifyUser, user)
  }
  export function view(id: string, data: any = {}) {
    track(id, EventType.PageView, {
      ...data,
      organizationId,
      screenWidth: window?.screen?.width,
      screenHeight: window?.screen?.height,
    })
  }

  export function reset() {
    organizationId = undefined
  }
}

export function trackHtAnalyticsAPIError(path: string, error: any) {
  Analytics.event(AnalyticsEvents.ANALYTICS_API_DATA_FAIL, {
    api_call_path: path,
    api_call_error_statuts_code: error.code,
  })
}
