import moment from 'moment'
import { getDuration } from 'utils/time-utils'

export type TimeUnit = 'y' | 'mo' | 'w' | 'd' | 'h' | 'min' | 's' | 'ms'
const UNIT_SEQUENCE: TimeUnit[] = ['y', 'mo', 'd', 'h', 'min', 's', 'ms']

function getAllUnitsFromDuration(value: moment.Duration): {
  [key: string]: number
} {
  return {
    y: value.years(),
    m: value.months(),
    w: value.weeks(),
    d: value.days(),
    h: value.hours(),
    min: value.minutes(),
    s: value.seconds(),
    ms: value.milliseconds(),
  }
}

export class TimeUnitFormatter {
  static format(value: number, unit: TimeUnit): string {
    if (!value) {
      return '0s'
    }
    const diff = getDuration(0, TimeUnitFormatter.calculateMillis(value, unit))
    const durations = getAllUnitsFromDuration(diff)
    const parts: string[] = []

    for (const cur of UNIT_SEQUENCE) {
      if (parts.length === 2) {
        break
      }
      const durationByUnit = durations[cur]
      if (durationByUnit >= 1) {
        parts.push(`${durationByUnit}${cur}`)
      }
    }
    return parts.join(' ')
  }

  private static calculateMillis(value: number, unit: TimeUnit) {
    switch (unit) {
      case 'y':
        return value * 365 * 24 * 3600 * 1000
      case 'w':
        return value * 7 * 24 * 3600 * 1000
      case 'd':
        return value * 24 * 3600 * 1000
      case 'h':
        return value * 3600 * 1000
      case 'min':
        return value * 60 * 1000
      case 's':
        return value * 1000
      default:
        return value
    }
  }
}
