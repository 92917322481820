import { Action } from '@ngrx/store'

export const MAUS_GET = '[Stats] MAUs Get'
export const MAUS_RECEIVE = '[Stats] MAUs Received'
export const MAUS_ERROR = '[Stats] MAUs Load Error'

/**
 * Every action is comprised of at least a type and an optional
 * payload. Expressing actions as classes enables powerful
 * type checking in reducer functions.
 *
 * See Discriminated Unions: https://www.typescriptlang.org/docs/handbook/advanced-types.html#discriminated-unions
 */
export class MausGetAction implements Action {
  readonly type = MAUS_GET

  constructor(public readonly payload: number) {}
}

/**
 * Export a type alias of all actions in this action group
 * so that reducers can easily compose action types
 */
export type Actions = MausGetAction
