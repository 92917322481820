<div class="avatar-wrapper">
  <div
    class="avatar"
    role="avatar-component"
    [style.background-image]="
      'url(' + (user.pic || 'assets/images/anonymous_user.png') + ')'
    "
  >
    <div
      *ngIf="color || user.status"
      class="secondary {{ user.status }}"
      [style.background]="color"
    ></div>
    <div *ngIf="provider" class="chat-avatar-provider {{ provider }}"></div>
  </div>
</div>

<div class="user-info">
  <div class="text-body-small bold"
    >{{ user.first_name }} {{ user.last_name }}</div
  >
  <div
    [attr.data-testid]="'email-' + user.email"
    [ngClass]="{
      'text-body-small': user.first_name === '' && user.last_name === '',
      'text-small': user.first_name !== '' || user.last_name !== ''
    }"
    >{{ user.email }}</div
  >
</div>
