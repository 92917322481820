import { Component, Inject, OnInit } from '@angular/core'
import { UntypedFormBuilder, Validators } from '@angular/forms'
import { Store } from '@ngrx/store'
import {
  compareControlValidator,
  customEmailValidator,
  emailAndPasswordSimilarityValidation,
  handleServerError,
  validateAllFormFields,
} from 'models/form'
import { first, switchMap } from 'rxjs'
import { CustomError } from 'src/app/constants/errors'
import { FeedbackService } from 'src/app/services/feedback.service'
import * as auth from '../../../actions/auth'
import { HubtypeUser, ROLE_ADMIN } from '../../../models/hubtype-user'
import * as fromRoot from '../../../reducers'
import { AuthService } from '../../../services/hubtype-api/auth.service'
import { UserService } from '../../../services/hubtype-api/user.service'

@Component({
  selector: 'signup-form',
  templateUrl: './signup-form.component.html',
  styleUrls: ['./signup-form.component.scss'],
})
export class SignupFormComponent implements OnInit {
  form = this.fb.group({
    email: ['', [Validators.required, customEmailValidator()]],
    password: [
      '',
      [
        Validators.required,
        Validators.minLength(12),
        Validators.maxLength(64),
        emailAndPasswordSimilarityValidation(),
      ],
    ],
    checkPassword: [
      '',
      [
        Validators.required,
        compareControlValidator('password', 'Passwords do not match'),
      ],
    ],
    organization: ['', [Validators.required, Validators.minLength(3)]],
    agree: [false, [Validators.requiredTrue]],
    updates: [false],
  })

  constructor(
    private store: Store<fromRoot.State>,
    private fb: UntypedFormBuilder,
    private feedbackService: FeedbackService,
    @Inject('userService') private userService: UserService,
    @Inject('authService') private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.cleanFormErrorsOnValueChange()
  }

  onSubmit() {
    validateAllFormFields(this.form)

    if (!this.form.valid) {
      return
    }

    this.signup()
  }

  private cleanFormErrorsOnValueChange() {
    this.form.valueChanges.subscribe(() => {
      this.form.setErrors(null)
    })
  }

  private getUserFromForm(): HubtypeUser {
    const { email, organization, password, updates } = this.form.value
    const user = new HubtypeUser()
    user.email = email
    user.role = ROLE_ADMIN
    user.org_name = organization
    user.password = password
    user.campaign.receive_permission = updates

    return user
  }

  private signup() {
    const user = this.getUserFromForm()

    this.userService
      .signUpUser(user)
      .pipe(
        first(),
        switchMap(() => this.authService.login(user.email, user.password))
      )
      .subscribe({
        next: oauth => {
          this.store.dispatch(new auth.LoginAction(oauth))
        },
        error: err => {
          handleServerError({
            error: err,
            form: this.form,
            feedbackService: this.feedbackService,
            customUnhandledError: CustomError.SIGNUP_ERROR,
            lookup: { detail: 'password' },
          })
        },
      })
  }
}
