/* eslint-disable @typescript-eslint/naming-convention */
import { Any, JsonObject, JsonProperty } from 'json2typescript'
import moment from 'moment'
import { HubtypeCase } from './hubtype-case'

export interface ReportSeries {
  name: string // Date string
  series: ReportValue[]
}

export interface ReportValue {
  name: string
  value: number
}

export function mergeNullToDiscarded(values: ReportValue[]): ReportValue[] {
  if (!values) {
    return []
  }
  const nullIndex = values.findIndex(rv => rv.name == null)
  const numNulls = nullIndex >= 0 ? values[nullIndex].value : 0
  const discarded = values.find(rv => rv.name == 'discarded')
  if (!discarded && nullIndex < 0) {
    return values
  }
  if (discarded) {
    discarded.value = numNulls + discarded.value
  } else {
    values.push({ name: 'discarded', value: numNulls })
  }
  if (nullIndex >= 0) {
    values.splice(nullIndex, 1)
  }
  return values
}

@JsonObject
export class HubtypeSLAs {
  /*
  * "total_cases": ...,
    "total_interactions": ...,
    "avg_resolution_time": ...,
    "avg_speed_of_answer": ...,
    "avg_response_time": ...,
    "avg_cases_per_hour": ...,
    "speed_of_answer_count": {
        "service_level_warning": ...,
        "service_level_alarm": ...,
        "null": ...,
        "service_level_safe": ...,
        "discarded": ...
    },
    "resolution_type_count": {
        "Information": ...,
        "discarded": ...,
        ...
    },
    "channel_count": {
        "telegram": ...
        ...
    },
    "service_level_by_date": [
        {
            "day": ...,
            "service_level_safe": ...,
            "service_level_warning": ...,
            "service_level_alarm": ...
        },
        ...
    ]

  * */

  static STATS_ALARM_KEY = 'service_level_alarm'
  static STATS_NA_KEY = 'not_applicable'
  static STATS_SAFE_KEY = 'service_level_safe'
  static STATS_WARN_KEY = 'service_level_warning'

  @JsonProperty('agent_ids', Any, true)
  agent_ids?: [any] = undefined
  @JsonProperty('agent_messages', Any, true)
  agent_messages?: any = undefined
  @JsonProperty('avg_response_time', Any, true)
  avg_response_time?: any = undefined
  @JsonProperty('avg_resolution_time', Any, true)
  avg_resolution_time?: any = undefined
  @JsonProperty('avg_cases_per_hour', Any, true)
  avg_cases_per_hour?: any = undefined
  @JsonProperty('avg_speed_of_answer', Any, true)
  avg_speed_of_answer?: any = undefined
  @JsonProperty('total_cases', Any, true)
  total_cases?: any = undefined
  @JsonProperty('total_interactions', Any, true)
  total_interactions?: any = undefined
  @JsonProperty('channel_count', Any, true)
  channel_count?: any = undefined
  @JsonProperty('resolution_type_count', Any, true)
  resolution_type_count?: ReportValue[] = undefined
  @JsonProperty('service_level_by_date', Any, true)
  service_level_by_date?: [any] = undefined
  @JsonProperty('speed_of_answer_count', Any, true)
  speed_of_answer_count?: ReportValue[] = undefined
  @JsonProperty('average_agent_speed_of_answer', Any, true)
  average_agent_speed_of_answer?: any = undefined
  @JsonProperty('average_enduser_speed_of_answer', Any, true)
  average_enduser_speed_of_answer?: any = undefined
  @JsonProperty('case_id', String, true)
  case_id?: string = undefined
  @JsonProperty('created_at', Any, true)
  created_at?: any = undefined
  @JsonProperty('enduser_messages', String, true)
  enduser_messages?: string = undefined
  @JsonProperty('initial_agent_speed_of_answer', Number, true)
  initial_agent_speed_of_answer?: number = undefined
  @JsonProperty('initial_service_level', String, true)
  initial_service_level?: string = undefined
  @JsonProperty('project_id', String, true)
  project_id?: string = undefined
  @JsonProperty('provider', String, true)
  provider?: string = undefined
  @JsonProperty('queue_id', String, true)
  queue_id?: string = undefined
  @JsonProperty('resolution_status', String, true)
  resolution_status?: string = undefined
  @JsonProperty('resolution_time', String, true)
  resolution_time?: string = undefined
  @JsonProperty('resolved_at', Any, true)
  resolved_at?: any = undefined
  @JsonProperty('status', Any, true)
  status?: any = undefined
  @JsonProperty('type', Any, true)
  type?: any = undefined

  constructor() {}
}

@JsonObject
export class HubtypeRealtimeCase {
  @JsonProperty('agent_ids', Any, true)
  agent_ids?: [string] = undefined
  @JsonProperty('agent_messages', Number, true)
  agent_messages?: number = undefined
  @JsonProperty('average_agent_speed_of_answer', Number, true)
  average_agent_speed_of_answer?: number = undefined
  @JsonProperty('average_enduser_speed_of_answer', Number, true)
  average_enduser_speed_of_answer?: number = undefined
  @JsonProperty('case_id', String, true)
  case_id?: string = undefined
  @JsonProperty('created_at', String, true)
  created_at?: string = undefined
  @JsonProperty('enduser_messages', Number, true)
  enduser_messages?: number = undefined
  @JsonProperty('endusers_count', Number, true)
  endusers_count?: number = undefined
  @JsonProperty('initial_agent_speed_of_answer', Number, true)
  initial_agent_speed_of_answer?: number = undefined
  @JsonProperty('initial_service_level', String, true)
  initial_service_level?: string = undefined
  @JsonProperty('provider', String, true)
  provider?: string = undefined
  @JsonProperty('queue_id', String, true)
  queue_id?: string = undefined
  @JsonProperty('resolution_status', String, true)
  resolution_status?: string = undefined
  @JsonProperty('resolution_time', Number, true)
  resolution_time?: number = undefined
  @JsonProperty('resolved_at', String, true)
  resolved_at?: string = undefined
  @JsonProperty('status', String, true)
  status?: string = undefined
  @JsonProperty('type', String, true)
  type?: string = undefined

  constructor() {}

  public get created_at_moment() {
    return moment(this.created_at)
  }

  public get resolved_at_moment() {
    return moment(this.resolved_at)
  }

  public get is_attending(): boolean {
    return this.status === HubtypeCase.STATUS_ATTENDING
  }

  public get is_waiting(): boolean {
    return this.status === HubtypeCase.STATUS_WAITING
  }

  public get is_idle(): boolean {
    return this.status === HubtypeCase.STATUS_IDLE
  }

  public get is_solved() {
    return this.status === HubtypeCase.STATUS_RESOLVED
  }
}
