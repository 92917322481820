import { OAuth } from './oauth'

export type IntegrationEventType = 'authenticated' | 'init' | 'readyhook'

export interface IntegrationEvent {
  type: IntegrationEventType
  payload: any
}

interface CaseDataLoadEvent {
  auth: OAuth
  caseId: string
}

export class IntegrationAuthEvent implements IntegrationEvent {
  type: IntegrationEventType

  constructor(public payload: OAuth) {
    this.type = 'authenticated'
  }
}

export class IntegrationCaseEvent implements IntegrationEvent {
  type: IntegrationEventType

  constructor(public payload: CaseDataLoadEvent) {
    this.type = 'init'
  }
}

export class IntegrationReadyEvent implements IntegrationEvent {
  type: IntegrationEventType
  payload: { caseId: string; resourceId: string }

  constructor() {
    this.type = 'readyhook'
  }
}
