import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { Subscription, timer } from 'rxjs'
import { Task } from 'shared/entities/task'
import {
  TaskFinishAction,
  TaskNewAction,
  TaskUpdateAction,
} from '../actions/tasks'
import { State } from '../reducers'

@Injectable({
  providedIn: 'root',
})
export class TaskExecutorService {
  private activeTimers: SubscriptionTaskId[] = []

  constructor(private store: Store<State>) {}

  runTaskAtInterval(task: Task, callback: () => void, interval: number) {
    const subscription = timer(0, interval).subscribe(() => {
      callback()
    })
    this.activeTimers.push({
      taskId: task.taskId,
      subscription,
    })
    this.store.dispatch(new TaskNewAction(task))
  }

  finishTask(task: Task) {
    this.store.dispatch(new TaskFinishAction(task))
    const timerSubscription = this.activeTimers.find(
      at => at.taskId === task.taskId
    )
    if (!timerSubscription) {
      console.warn(`No active timer found under id '${task.taskId}'`)
      return
    }
    timerSubscription.subscription.unsubscribe()
  }

  updateTask(task: Task) {
    this.store.dispatch(new TaskUpdateAction(task))
  }
}

interface SubscriptionTaskId {
  taskId: string
  subscription: Subscription
}
