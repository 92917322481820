import { Injectable } from '@angular/core'
import { Subject, Subscription } from 'rxjs'
import { Feedback, FeedbackButtonConfig } from '../models/feedback'

@Injectable({
  providedIn: 'root',
})
export class FeedbackService {
  private queue: Subject<Feedback>

  constructor() {
    this.queue = new Subject()
  }

  error(
    code: string,
    message: string,
    error?: Error,
    buttonConfig?: FeedbackButtonConfig
  ) {
    this.queue.next(Feedback.error(code, message, error, buttonConfig))
  }

  info(message: string) {
    this.queue.next(Feedback.create(message, 'info'))
  }

  success(message: string, buttonConfig?: FeedbackButtonConfig) {
    this.queue.next(Feedback.create(message, 'success', buttonConfig))
  }

  send(feedback: Feedback) {
    this.queue.next(feedback)
  }

  observe(
    next?: (value: Feedback) => void,
    err?: (error: any) => void
  ): Subscription {
    return this.queue.asObservable().subscribe(next, err)
  }
}
