import { JsonObject, JsonProperty } from 'json2typescript'
import moment from 'moment'
import { TimezonePipe } from '../pipes/timezone.pipe'

export const EXCEPTION_STATUS_OPEN = 'open'
export const EXCEPTION_STATUS_CLOSED = 'closed'
export const EXCEPTION_STATUS_OPEN_LABEL = 'Open'
export const EXCEPTION_STATUS_CLOSED_LABEL = 'Closed'

export interface ExceptionStatus {
  value: typeof EXCEPTION_STATUS_OPEN | typeof EXCEPTION_STATUS_CLOSED
  label:
    | typeof EXCEPTION_STATUS_OPEN_LABEL
    | typeof EXCEPTION_STATUS_CLOSED_LABEL
}

function getShiftsString(timeShifts: HubtypeShift[]) {
  return timeShifts
    .map(({ start_time, end_time }) => {
      const start = getTimeString(start_time)
      const end = getTimeString(end_time)
      return `${start}-${end}`
    })
    .join(', ')
}

function getTimeString(time: { hour: number; minute: number }): string {
  const hour = time.hour < 10 ? `0${time.hour}` : time.hour
  const minute = time.minute < 10 ? `0${time.minute}` : time.minute
  return `${hour}:${minute}`
}

@JsonObject
export class HubtypeTimeShift {
  @JsonProperty('hour', Number)
  public hour: number = undefined
  @JsonProperty('minute', Number)
  public minute: number = undefined

  public get timeString(): string {
    return getTimeString(this)
  }
}

@JsonObject
export class HubtypeShift {
  @JsonProperty('start_time', HubtypeTimeShift)
  public start_time: HubtypeTimeShift = undefined
  @JsonProperty('end_time', HubtypeTimeShift)
  public end_time: HubtypeTimeShift = undefined
}

@JsonObject
export class HubtypeWeeklySchedule {
  @JsonProperty('day', String)
  public day: string = undefined
  @JsonProperty('time_shifts', [HubtypeShift])
  public time_shifts: HubtypeShift[] = []

  public get shiftsString(): string {
    return getShiftsString(this.time_shifts)
  }
}

@JsonObject
export class HubtypeExceptions {
  @JsonProperty('name', String)
  public name = ''

  @JsonProperty('status', String)
  public status: ExceptionStatus['value'] = EXCEPTION_STATUS_OPEN

  @JsonProperty('day_of_month', Number)
  public day_of_month: number = undefined

  @JsonProperty('month', Number)
  public month: number = undefined

  @JsonProperty('year', Number)
  public year: number = undefined

  @JsonProperty('time_shifts', [HubtypeShift])
  public time_shifts: HubtypeShift[] = []

  public get shiftsString(): string {
    return getShiftsString(this.time_shifts)
  }

  public get date(): Date {
    return this.year && this.month && this.day_of_month
      ? new Date(this.year, this.month - 1, this.day_of_month)
      : null
  }
}

@JsonObject
export class HubtypeScheduleSettings {
  @JsonProperty('timezone', String)
  public timezone? = 'UTC'
  @JsonProperty('weekly_schedule', [HubtypeWeeklySchedule], true)
  public weekly_schedule?: HubtypeWeeklySchedule[] = []
  @JsonProperty('exceptions', [HubtypeExceptions], true)
  public exceptions?: HubtypeExceptions[] = []

  public get timezoneName(): string {
    return new TimezonePipe().transform(this.timezone)
  }

  public get todaysSchedule(): string {
    const today = moment().day()
    const scheduleStrings = []
    const todaysException = this.exceptions.find(exception =>
      moment(exception.date).isSame(moment(), 'day')
    )
    const todaysSchedule = this.weekly_schedule.find(
      schedule => schedule.day === `${today}`
    )
    if (todaysException || todaysSchedule) {
      scheduleStrings.push(
        (todaysException || todaysSchedule)?.shiftsString || 'N/A'
      )
      if (todaysException) {
        scheduleStrings.push(`(${todaysException.name})`)
      }
      return scheduleStrings.join(' ')
    }
    return 'N/A'
  }
}
