import { Action } from '@ngrx/store'
import { Task } from 'shared/entities/task'

export const TASK_PUSH_NEW = '[Tasks] New Task Started'
export const TASK_UPDATE = '[Tasks] Task Update'
export const TASK_FINISH = '[Tasks] Task Finished'
export const TASK_DELETE = '[Tasks] Task Delete'

/**
 * Every action is comprised of at least a type and an optional
 * payload. Expressing actions as classes enables powerful
 * type checking in reducer functions.
 *
 * See Discriminated Unions: https://www.typescriptlang.org/docs/handbook/advanced-types.html#discriminated-unions
 */
export class TaskNewAction implements Action {
  readonly type = TASK_PUSH_NEW

  constructor(public readonly payload: Task) {}
}

export class TaskFinishAction implements Action {
  readonly type = TASK_FINISH

  constructor(public readonly payload: Task) {}
}

export class TaskDeleteAction implements Action {
  readonly type = TASK_DELETE

  constructor(public readonly payload: string) {}
}

export class TaskUpdateAction implements Action {
  readonly type = TASK_UPDATE

  constructor(public readonly payload: Task) {}
}

/**
 * Export a type alias of all actions in this action group
 * so that reducers can easily compose action types
 */
export type Actions =
  | TaskNewAction
  | TaskDeleteAction
  | TaskFinishAction
  | TaskUpdateAction
