import { Component, Inject, OnDestroy, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { HubtypeBot } from 'models/hubtype-bot'
import { Subject } from 'rxjs'
import { first } from 'rxjs/operators'
import { BotService } from 'src/app/services/hubtype-api/bot.service'
import { CustomError } from '../constants/errors'
import { FeedbackService } from '../services/feedback.service'
import { ProviderAccountService } from '../services/hubtype-api/provider-account.service'

@Component({
  selector: 'app-apple-integration',
  templateUrl: 'apple-integration.component.html',
  styleUrls: ['apple-integration.component.scss'],
})
export class AppleIntegrationComponent implements OnInit, OnDestroy {
  BIZ_ID_PARAM = 'id'
  BIZ_NAME_PARAM = 'name'
  BIZ_LOGO_PARAM = 'logo'
  bizId = ''
  bizName = ''
  bizLogo = ''
  selectedBotId = ''
  showSelectBotError = false
  bots: HubtypeBot[]
  private ngUnsubscribe: Subject<void> = new Subject<void>()

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private feedbackService: FeedbackService,
    @Inject('botService') private botService: BotService,
    @Inject('providerAccountService')
    private providerAccountService: ProviderAccountService
  ) {}

  ngOnInit() {
    this.route.queryParams.pipe(first()).subscribe(params => {
      this.bizId = params[this.BIZ_ID_PARAM]
      this.bizName = params[this.BIZ_NAME_PARAM]
      this.bizLogo = params[this.BIZ_LOGO_PARAM]
    })
    this.botService.getAllStoredBots().subscribe(bots => {
      this.bots = bots
    })
  }

  connect() {
    if (!this.selectedBotId) {
      this.showSelectBotError = true
      return
    }
    this.providerAccountService
      .connectAppleAccount(this.bizName, this.selectedBotId, this.bizId)
      .subscribe(
        pa => {
          this.router.navigate([`bots/${this.selectedBotId}/integrations`])
        },
        err => {
          this.feedbackService.error(
            CustomError.INTEGRATION_ERROR.code,
            CustomError.INTEGRATION_ERROR.message,
            err
          )
        }
      )
  }

  onSelectBot(bot: HubtypeBot) {
    this.showSelectBotError = !bot?.id
    this.selectedBotId = bot?.id
  }
  ngOnDestroy() {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }
}
