import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'truncateLongString',
})
export class TruncateLongStringPipe implements PipeTransform {
  private readonly DELIMITER = '...'

  transform(value, leftCharacters: number, rightCharacters?: number): any {
    if (!value) {
      return ''
    }

    const safeLeftCharacters: number = Number(leftCharacters)
    const safeRightCharacters: number = Number(rightCharacters)

    let result =
      safeLeftCharacters > 0 && value.length > safeLeftCharacters
        ? `${value.slice(0, safeLeftCharacters)}${this.DELIMITER}`
        : safeLeftCharacters === 0
          ? this.DELIMITER
          : value

    result =
      safeRightCharacters > 0 &&
      value.length > safeLeftCharacters + safeRightCharacters
        ? `${result}${value.slice(-safeRightCharacters)}`
        : result

    return result
  }
}
