import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Optional,
  Output,
  Self,
} from '@angular/core'
import { ControlValueAccessor, NgControl } from '@angular/forms'
import { getMessageErrors } from 'models/form'

@Component({
  selector: 'ht-input',
  templateUrl: './ht-input.component.html',
  styleUrls: ['./ht-input.component.scss'],
})
export class HtInputComponent implements ControlValueAccessor {
  @Input() icon: string
  @Input() placeholder = ''
  @Input() type = 'text'
  @Input() width = ''
  @Input() disabled = false
  @Input() required = false
  @Input() label?: string
  @Input() isTextArea = false
  @Input() noErrorMessage = false
  @Input() fullWidth = false
  @Input() min = ''
  @Input() max = ''
  @Input() helpText = ''

  @Output()
  onchange = new EventEmitter<string>()
  @Output()
  onblur = new EventEmitter<string>()

  value = ''
  error = ''
  constructor(@Self() @Optional() private control: NgControl) {
    if (this.control) {
      this.control.valueAccessor = this
    }
  }

  @HostBinding('class.is-disabled') get isDisabled() {
    return this.control?.disabled || this.disabled
  }
  @HostBinding('class.is-full-width') get isFullWidth() {
    return this.fullWidth
  }
  @HostBinding('class.has-error') get getHasError() {
    return (
      this.control &&
      !this.control.valid &&
      this.control.errors &&
      this.control.touched
    )
  }

  public get showError(): boolean {
    if (this.control && !this.noErrorMessage) {
      const hasError =
        !this.control.valid && this.control.errors && this.control.touched
      if (hasError) {
        this.error = getMessageErrors(this.control.errors)
      }

      return hasError
    }
    return false
  }

  onChange = value => {}
  onTouched = () => {}

  onInput(value: string) {
    this.value = value
    this.onTouched()
    this.onChange(this.value)
    this.onchange.emit(value)
  }

  handleBlur(value) {
    this.onblur.emit(value)
  }

  writeValue(value: string): void {
    this.value = value || ''
  }
  registerOnChange(onChange: any) {
    this.onChange = onChange
  }
  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled
  }
}
