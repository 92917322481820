import { JsonObject, JsonProperty } from 'json2typescript'

@JsonObject('HubtypeChatSummary')
export class HubtypeChatSummary {
  @JsonProperty('id', String, true)
  public id = ''
  @JsonProperty('text', String, true)
  public text = ''
  @JsonProperty('created_at', String, true)
  public createdAt: string = undefined
  @JsonProperty('case_id', String, true)
  public caseId: string = undefined
  @JsonProperty('last_message_used_id', String, true)
  public lastMessageId: string = undefined
  @JsonProperty('num_messages_used', Number, true)
  public numberOfMessagesUsed: number = undefined

  constructor() {}
}
