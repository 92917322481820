import { Action } from '@ngrx/store'
import { DateRange } from 'models/date-range'
import { AnalyticsProjectFilters } from 'models/hubtype-analytics'
import { BadgeRefresh } from '../services/gateways/analytics.gateway'

export enum InsightsActionType {
  SET_DATE_FROM = '[INSIGHTS] SET DATE FROM',
  SET_PROJECT_FILTERS = '[INSIGHTS] SET PROJECT FILTERS',
  SET_PROJECT_BADGES = '[INSIGHTS][BADGES] SET PROJECT BADGES',
  SET_QUEUE_BADGES = '[INSIGHTS][BADGES] SET QUEUE BADGES',
  SET_PROVIDER_BADGES = '[INSIGHTS][BADGES] SET PROVIDER BADGES',
}

export class SetDateFrom implements Action {
  readonly type = InsightsActionType.SET_DATE_FROM

  constructor(public payload: DateRange) {}
}

export class SetProjectFilters implements Action {
  readonly type = InsightsActionType.SET_PROJECT_FILTERS

  constructor(public payload: AnalyticsProjectFilters) {}
}
export class SetProjectBadges implements Action {
  readonly type = InsightsActionType.SET_PROJECT_BADGES

  constructor(public payload: BadgeRefresh) {}
}

export class SetQueueBadges implements Action {
  readonly type = InsightsActionType.SET_QUEUE_BADGES

  constructor(public payload: BadgeRefresh) {}
}
export class SetProvidersBadges implements Action {
  readonly type = InsightsActionType.SET_PROVIDER_BADGES

  constructor(public payload: BadgeRefresh) {}
}

export type InsightsAction =
  | SetDateFrom
  | SetProjectFilters
  | SetProjectBadges
  | SetQueueBadges
  | SetProvidersBadges
