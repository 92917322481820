import { HubtypeFolder } from './hubtype-folder'

export interface FolderItemProps {
  key?: string
  sortBy?: string
}

export class Folder<T> {
  public readonly id: string
  public name: string
  public readonly createdAt: string
  public readonly createdBy: string

  public path: string
  protected parent: Folder<T> | null
  private children: Folder<T>[]
  private items: T[]
  private itemProps = {
    key: 'id',
    sortBy: 'name',
  }

  constructor(model: HubtypeFolder) {
    this.id = model.id
    this.name = model.name
    this.createdAt = model.created_at
    this.createdBy = model.created_by
    this.children = []
    this.items = []
    this.path = this.name
  }

  addChild(folder: Folder<T>) {
    this.addAndSortCollection(folder, this.children)
  }

  addItem(item: T) {
    this.addAndSortCollection(item, this.items, this.itemProps.key)
  }

  addItems(items: T[]) {
    items.forEach(item => {
      this.addAndSortCollection(item, this.items, this.itemProps.key)
    })
  }

  getChildren(): Folder<T>[] {
    return this.children
  }

  getItems(): T[] {
    return this.items
  }

  getParent(): Folder<T> {
    return this.parent
  }

  setChildren(folders: Folder<T>[]) {
    this.children = folders
  }

  setItems(items: T[]) {
    this.items = items
  }

  setItemProps(props: FolderItemProps) {
    this.itemProps = {
      ...this.itemProps,
      ...props,
    }
  }

  setParent(folder: Folder<T>) {
    if (folder) {
      this.parent = folder
    }
  }

  setPath(folders: HubtypeFolder[], folder: HubtypeFolder) {
    this.path = this.getFolderPath(folders, folder)
  }

  private getFolderPath(
    folders: HubtypeFolder[],
    folder: HubtypeFolder
  ): string {
    let path = [this.name]
    while (folder.parent) {
      folder = folders.find(f => f.id == folder.parent)
      if (!folder) {
        break
      }
      path.push(folder.name)
    }
    return path.reverse().join('/')
  }

  equals(folder: Folder<T>): boolean {
    return folder.id === this.id
  }

  toFolderOf<U>(newItemProps?: FolderItemProps): Folder<U> {
    const copy = Object.assign(new Folder<U>({}), this)
    copy.setItems([])
    if (newItemProps) {
      copy.setItemProps(newItemProps)
    }
    return copy
  }

  hasItems(): boolean {
    return Boolean(this.items.length)
  }

  toEntity(): HubtypeFolder {
    const model = new HubtypeFolder()
    model.id = this.id
    model.name = this.name
    model.created_at = this.createdAt
    model.created_by = this.createdBy
    model.parent = this.parent && this.parent.id
    return model
  }

  private addAndSortCollection(
    item: T | Folder<T>,
    collection: any[],
    key: string = 'id'
  ): Folder<T>[] | T[] {
    const itemIsObject = typeof item === 'object'
    if (
      collection.find(f => {
        if (typeof f === 'object') {
          return f[key] === item[key]
        }
        return f === item
      })
    ) {
      return collection
    }
    collection.push(item)
    return itemIsObject
      ? collection.sort((f1, f2) =>
          f1[this.itemProps.sortBy] > f2[this.itemProps.sortBy] ? 1 : -1
        )
      : collection.sort()
  }
}
