import { Component, NgZone, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { Store } from '@ngrx/store'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import * as fromRoot from '../../reducers'

@Component({
  selector: 'app-register',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignupComponent implements OnInit {
  private ngUnsubscribe: Subject<void> = new Subject<void>()

  constructor(
    private router: Router,
    private store: Store<fromRoot.State>,
    private zone: NgZone
  ) {}

  ngOnInit() {
    this.store
      .select(fromRoot.getMeState)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(me => {
        if (me && !me.must_change_password) {
          const url = `/${me.preferred_product}`
          this.zone.run(() => this.router.navigate([url]))
        } else if (me && me.must_change_password) {
          const url = '/profile'
          this.zone.run(() => this.router.navigate([url]))
        } else {
          return
        }
      })
  }
}
