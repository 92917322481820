import { Injectable } from '@angular/core'
import { Router } from '@angular/router'

declare let Notification

@Injectable()
export class DesktopNotifications {
  constructor(private router: Router) {}

  notify(theBody: string, theTitle: string, time: any, caseId: string) {
    const options = {
      body: theBody,
      icon: 'favicon.ico',
    }
    const notification = new Notification(theTitle, options)
    setTimeout(notification.close.bind(notification), time * 1000)
    if (caseId) {
      notification.onclick = event => {
        window.focus()
        this.router.navigate([`desk/inbox/${caseId}`])
      }
    }
  }

  public isPermissionGrantedValue() {
    return this.checkCompatibility() && Notification.permission === 'granted'
  }

  public isPermissionDefaul() {
    return this.checkCompatibility() && Notification.permission == 'default'
  }

  public checkCompatibility() {
    return !!('Notification' in window)
  }

  public isPermissionGranted(permission) {
    return permission === 'granted'
  }

  public requestPermission(callback) {
    return this.checkCompatibility() && Notification.requestPermission(callback)
  }

  public allowNotifications() {
    if (!this.checkCompatibility()) {
      console.log('Notification API not available in this browser.')
      return false
    }
    return this.requestPermission(permission => {
      if (this.isPermissionGranted(permission)) {
        return true
      } else {
        return false
      }
    })
  }
}
