<header><hubtype-logo href="https://www.hubtype.com/"></hubtype-logo></header>
<main><router-outlet></router-outlet><ng-content></ng-content></main>
<footer>
  <nav>
    <ol>
      <li>
        <a href="https://www.hubtype.com/terms/privacy-policy" target="_blank">
          Privacy Policy
        </a>
      </li>
      <li>
        <a href="https://www.hubtype.com/terms/cookies-policy" target="_blank">
          Cookie Policy
        </a>
      </li>
      <li>
        <a
          href="https://www.hubtype.com/terms/terms-of-service"
          target="_blank"
        >
          Terms and service
        </a>
      </li>
      <li>
        <a href="https://www.hubtype.com/terms/security-policy" target="_blank">
          Security Policy
        </a>
      </li>
    </ol>
    <ol>
      <li>
        <a href="https://www.linkedin.com/company/hubtype/" target="_blank">
          Linkedin
        </a>
      </li>
      <li>
        <a href="https://twitter.com/hubtype" target="_blank">Twitter</a>
      </li>
      <li>©{{ year }}<span> All Rights Reserved</span></li>
    </ol>
  </nav>
</footer>
