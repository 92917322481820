export function limitLength(text: string, maxLength: number): string {
  if (!text) {
    return ''
  }
  return text.length > maxLength
    ? text.slice(0, maxLength - 3).concat('...')
    : text
}
export function isWordInText(text: string, word: string): boolean {
  return text && word
    ? text.toLowerCase().match(word.toLowerCase()) !== null
    : false
}

export function normalizeUnicode(text: string): string {
  return text ? text.normalize('NFD').replace(/[\u0300-\u036f]/g, '') : ''
}

export function containsNormalizedText(text: string, word: string): boolean {
  if (!text) {
    return false
  }
  if (!word) {
    return true
  }
  return normalizeUnicode(text)
    .toLowerCase()
    .includes(normalizeUnicode(word).toLowerCase())
}

export function randomWord(length) {
  //word based on joined sequence of random numbers, turned to base 36
  return (
    [...Array(length)]
      // eslint-disable-next-line no-bitwise
      .map(i => (~~(Math.random() * 36)).toString(36))
      .join('')
  )
}

export function capitalizeFirstLetter(value: string): string {
  if (!value) {
    return ''
  }
  return value.charAt(0).toUpperCase() + value.slice(1)
}

export function getStringSimilarityNumber(
  first: string,
  second: string,
  decimals = 2
): string {
  first = first.replace(/\s+/g, '')
  second = second.replace(/\s+/g, '')

  if (first === second) {
    return '1' // identical or empty
  }
  if (first.length < 2 || second.length < 2) {
    return '0' // if either is a 0-letter or 1-letter string
  }

  let firstBigrams = new Map()
  for (let i = 0; i < first.length - 1; i++) {
    const bigram = first.substring(i, i + 2)
    const count = firstBigrams.has(bigram) ? firstBigrams.get(bigram) + 1 : 1

    firstBigrams.set(bigram, count)
  }

  let intersectionSize = 0
  for (let i = 0; i < second.length - 1; i++) {
    const bigram = second.substring(i, i + 2)
    const count = firstBigrams.has(bigram) ? firstBigrams.get(bigram) : 0

    if (count > 0) {
      firstBigrams.set(bigram, count - 1)
      intersectionSize++
    }
  }

  const similarity = ((2.0 * intersectionSize) /
    (first.length + second.length - 2)) as number

  return (similarity * 100).toFixed(decimals)
}

export function queryStringToObject(queryString: string): {
  [key: string]: string
} {
  const obj: { [key: string]: string } = {}

  const keyValuePairs = queryString.split('&')
  for (const pair of keyValuePairs) {
    const [key, value] = pair.split('=')
    if (!key || !value) {
      throw new Error('Query string with wrong format')
    }
    obj[key] = value
  }

  return obj
}
