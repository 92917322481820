import produce, { Draft } from 'immer'
import { HubtypeTemplate } from 'models/hubtype-template'
import { HubtypeWhatsappTemplate } from 'models/hubtype-whatsapp-template'
import { currentMillis } from 'utils/time-utils'
import {
  TEMPLATES_HUBTYPE_ERROR,
  TEMPLATES_HUBTYPE_GET,
  TEMPLATES_HUBTYPE_RECEIVE,
  TEMPLATES_WHATSAPP_ERROR,
  TEMPLATES_WHATSAPP_GET,
  TEMPLATES_WHATSAPP_RECEIVE,
} from '../actions/templates'
import { ExpirableData } from './shared.state'
import { TemplatesState } from './templates.state'

function newStateItem<T>(data: T, fresh = false): ExpirableData<T> {
  return {
    loading: false,
    data,
    timestamp: currentMillis(),
    fresh,
  }
}
export const initialState: TemplatesState = {
  hubtype: newStateItem<HubtypeTemplate[]>([]),
  whatsapp: newStateItem<HubtypeWhatsappTemplate[]>([]),
}

export function reducer(state?: TemplatesState, action: any = {}) {
  return produce((draftState: Draft<TemplatesState>, appraisalAction: any) => {
    switch (appraisalAction.type) {
      case TEMPLATES_HUBTYPE_GET:
        draftState.hubtype = {
          ...newStateItem([], false),
          loading: true,
        }
        return draftState
      case TEMPLATES_HUBTYPE_RECEIVE:
        draftState.hubtype = newStateItem(appraisalAction.payload, true)
        return draftState
      case TEMPLATES_HUBTYPE_ERROR:
        draftState.hubtype = {
          loading: false,
          error: appraisalAction.payload,
          ...newStateItem([], true),
        }
        return draftState
      case TEMPLATES_WHATSAPP_GET:
        draftState.whatsapp = {
          ...newStateItem([], false),
          loading: true,
        }
        return draftState
      case TEMPLATES_WHATSAPP_RECEIVE:
        draftState.whatsapp = newStateItem(appraisalAction.payload, true)
        return draftState
      case TEMPLATES_WHATSAPP_ERROR:
        draftState.whatsapp = {
          loading: false,
          error: appraisalAction.payload,
          ...newStateItem([], true),
        }
        return draftState
      default:
        return draftState
    }
  }, initialState)(state, action)
}
