<div [ngClass]="{ disabled, checked }" [class]="type">
  <label class="checkbox-container" role="checkbox" [tabindex]="tabIndex">
    <input type="checkbox" [(ngModel)]="checked" [disabled]="disabled" />
    <span class="checkmark" [class]="style"></span>
    <div class="checkbox-label">
      <ng-content></ng-content>
      <div class="error-message" *ngIf="showError && error">
        {{ error }}
      </div>
    </div>
  </label>
</div>
