import { Component, Input, OnChanges } from '@angular/core'
import { HubtypeProviderAccount } from 'models/hubtype-provider-account'
import { HubtypeUser } from 'models/hubtype-user'

@Component({
  selector: 'user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss'],
})
export class UserAvatarComponent implements OnChanges {
  @Input() user: HubtypeUser
  @Input() provider: HubtypeProviderAccount
  color: string

  ngOnChanges() {
    this.computeColor()
  }

  computeColor() {
    this.color = this.user?.isAvailable
      ? '#6dcd39'
      : this.user?.must_change_password || this.user?.isOffline
        ? '#666666'
        : this.user?.isAway
          ? '#f5a623'
          : '#ec4343'
  }
}
