import { Actions, TOOGLE_NAVBAR_COLLAPSE } from '../actions/userSettings'
import { UserSettingsState } from './user-settings.state'

export const initialState: UserSettingsState = {
  isNavBarCollapse: true,
}

export function reducer(
  state = initialState,
  action: Actions
): UserSettingsState {
  switch (action.type) {
    case TOOGLE_NAVBAR_COLLAPSE:
      return {
        ...state,
        isNavBarCollapse: !state.isNavBarCollapse,
      }
    default:
      return state
  }
}
