import { Inject, Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { HubtypeActiveUsers } from '../../models/hubtype-active-users'
import { ConverterService } from '../converter.service'
import { HubtypeApiService } from './hubtype-api.service'

@Injectable({
  providedIn: 'root',
})
export class StatsService {
  constructor(
    @Inject('apiService') private apiService: HubtypeApiService,
    @Inject('convertService') private convertService: ConverterService
  ) {}

  getMaus(year: number): Observable<HubtypeActiveUsers[]> {
    return this.apiService
      .get('/analytics/maus_history/', { year })
      .pipe(
        map(response =>
          this.convertService.jsonConvert.deserializeArray(
            response,
            HubtypeActiveUsers
          )
        )
      )
  }
}
