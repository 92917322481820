export enum SortDirection {
  ASC = 'asc',
  DESC = 'desc',
}

export enum SortType {
  WAITING_TIME = 'waitingTime',
  CREATED_AT = 'createdAt',
}

export class HubtypeCaseSort {
  sortDirection: SortDirection
  sortBy: SortType
}
