import { Directive, ElementRef, HostBinding } from '@angular/core'

@Directive({
  selector: '[click]',
})
export class ClickStyleDirective {
  @HostBinding('style.cursor') cursor = 'pointer'

  constructor(private el: ElementRef) {}
}
