import { Any, JsonObject, JsonProperty } from 'json2typescript'

@JsonObject
export class HubtypeFolder {
  @JsonProperty('id', String, false)
  public id?: string = undefined

  @JsonProperty('name', String, false)
  public name?: string = undefined

  @JsonProperty('parent', String, true)
  public parent?: string = undefined

  @JsonProperty('created_at', Any, true)
  public created_at?: string = undefined

  @JsonProperty('created_by', Any, true)
  public created_by?: string = undefined

  constructor() {}
}
