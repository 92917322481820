/* eslint-disable @typescript-eslint/naming-convention */
import { Any, JsonObject, JsonProperty } from 'json2typescript'
import { DropdownData } from 'shared/base/ht-dropdown/ht-dropdown.component'
import { containsNormalizedText } from 'utils/string-utils'
import { HubtypeTranslationSettings } from './hubtype-translation-language'

// import { HubtypeQueue } from './hubtype-queue';
// import { HubtypeProject } from './hubtype-project';

export const ROLE_ADMIN = 'role_admin'
export const ROLE_MANAGER = 'role_manager'
export const ROLE_AGENT = 'role_agent'

export const ROLE_OPTIONS = [
  { id: ROLE_ADMIN, value: 'Admin' },
  { id: ROLE_MANAGER, value: 'Manager' },
  { id: ROLE_AGENT, value: 'Agent' },
] as DropdownData[]

export const STATUS_OFFLINE = 'status_offline'
export const STATUS_AVAILABLE = 'status_available'
export const STATUS_AWAY = 'status_away'
export const STATUS_BUSY = 'status_busy'

export enum EmailStatus {
  DEACTIVATED = 'deactivated',
  ACTIVE = 'active',
  PENDING = 'pending',
  NOT_FOUND = 'not_found',
}

export function userSort(a: HubtypeUser, b: HubtypeUser): number {
  const aProp = (a.first_name + a.last_name + a.email)
    .trim()
    .toLocaleLowerCase()
  const bProp = (b.first_name + b.last_name + b.email)
    .trim()
    .toLocaleLowerCase()

  if (aProp < bProp) {
    return -1
  } else {
    return 1
  }
}

export function userSortByStatus(a: HubtypeUser, b: HubtypeUser): number {
  if ((!a.isOffline && !b.isOffline) || (a.isOffline && b.isOffline)) {
    return userSort(a, b)
  }
  if (!a.isOffline && b.isOffline) {
    return -1
  }
}

export function userFilter(statusFilter: string[], textFilter: string) {
  return user =>
    user &&
    ((user.isOffline && statusFilter.includes(STATUS_OFFLINE)) ||
      (user.isBusy && statusFilter.includes(STATUS_BUSY)) ||
      (user.isAway && statusFilter.includes(STATUS_AWAY)) ||
      (user.isAvailable && statusFilter.includes(STATUS_AVAILABLE))) &&
    (containsNormalizedText(user.name, textFilter) ||
      containsNormalizedText(user.email, textFilter))
}

@JsonObject
export class HubtypeUserMinimal {
  @JsonProperty('id', String, true)
  public id?: string = undefined
  @JsonProperty('username', String, true)
  public username?: string = undefined
  @JsonProperty('email', String, true)
  public email?: string = undefined
  @JsonProperty('first_name', String, true)
  public first_name?: string = undefined
  @JsonProperty('last_name', String, true)
  public last_name?: string = undefined
  @JsonProperty('pic', String, true)
  public pic?: string = undefined
}

@JsonObject
export class HubtypeUser extends HubtypeUserMinimal {
  static statusList = [
    { key: STATUS_AVAILABLE, label: 'Available' },
    { key: STATUS_AWAY, label: 'Away' },
    { key: STATUS_BUSY, label: 'Busy' },
    { key: STATUS_OFFLINE, label: 'Offline' },
  ]
  static roleOptions: DropdownData[] = ROLE_OPTIONS

  @JsonProperty('campaign', Any, true)
  public campaign?: any = {}
  @JsonProperty('agree', Boolean, true)
  public agree?: boolean = undefined
  @JsonProperty('password', String, true)
  public password?: string = undefined
  @JsonProperty('role', String, true)
  public role?: string = undefined
  @JsonProperty('must_change_password', Boolean, true)
  public must_change_password?: boolean = undefined
  @JsonProperty('created_by', Any, true)
  public created_by?: any = undefined
  @JsonProperty('org_name', String, true)
  public org_name?: string = undefined
  @JsonProperty('status', String, true)
  public status?: string = undefined
  @JsonProperty('organization_id', String, true)
  public organization_id?: string = undefined
  @JsonProperty('notifications_new_case', Boolean, true)
  public notifications_new_case?: boolean = undefined
  @JsonProperty('notifications_new_message', Boolean, true)
  public notifications_new_message?: boolean = undefined
  @JsonProperty('notifications_duration', Any, true)
  public notifications_duration?: any = undefined
  @JsonProperty('is_staff', Boolean, true)
  public is_staff?: boolean = undefined
  @JsonProperty('is_read_only', Boolean, true)
  public is_read_only?: boolean = undefined
  @JsonProperty('preferred_product', String, true)
  public preferred_product?: string = undefined
  @JsonProperty('queues', [Any], true)
  public queues?: any[] = undefined
  @JsonProperty('projects', [Any], true)
  public projects?: any[] = undefined
  @JsonProperty('advisors_settings', Any, true)
  public advisors_settings: any = {}
  @JsonProperty('is_active', Boolean, true)
  public is_active?: boolean = true
  @JsonProperty('translation_settings', HubtypeTranslationSettings, true)
  public translation_settings?: HubtypeTranslationSettings = undefined
  @JsonProperty('analytics_access', Boolean, true)
  public analytics_access?: boolean = undefined
  @JsonProperty('has_translation_service', Boolean, true)
  public has_translation_service?: boolean = false
  @JsonProperty('num_cases_for_busy', Number, true)
  public num_cases_for_busy?: number = undefined
  @JsonProperty('campaigns_exceptional_access', Boolean, true)
  public campaigns_exceptional_access?: boolean = false

  constructor() {
    super()
  }

  public get is_admin() {
    return this.role === ROLE_ADMIN
  }

  public get is_agent() {
    return this.role === ROLE_AGENT
  }

  public get is_manager() {
    return this.role === ROLE_MANAGER
  }

  public get isAdvisorsEnabled() {
    return this.advisors_settings['enabled'] || false
  }

  public get isBusy(): boolean {
    return this.status === STATUS_BUSY
  }

  public get isAvailable(): boolean {
    return this.status === STATUS_AVAILABLE
  }

  public get isAway(): boolean {
    return this.status === STATUS_AWAY
  }

  public get isOffline(): boolean {
    return this.status === STATUS_OFFLINE
  }

  public get roleName(): string {
    switch (this.role) {
      case ROLE_ADMIN:
        return 'Admin'
      case ROLE_MANAGER:
        return 'Manager'
      case ROLE_AGENT:
        return 'Agent'
    }
  }

  public get name(): string {
    if (this.first_name) {
      return `${this.first_name} ${this.last_name}`
    }
    if (this.username) {
      return this.username
    }
    return this.email
  }

  public getEmail(): string {
    return this.email
  }

  public canDeleteUserWithRole(roleToDelete: string): boolean {
    if (this.is_admin) {
      return true
    }
    if (this.is_manager && roleToDelete === ROLE_AGENT) {
      return true
    }
    return false
  }

  public canChangeUserWithRole(roleToChange: string): boolean {
    if (this.is_agent) {
      return false
    }
    if (this.is_manager && roleToChange !== ROLE_AGENT) {
      return false
    }
    return true
  }

  public canInviteUserWithRole(roleToInvite: string): boolean {
    if (this.is_admin) {
      return true
    }
    if (
      this.is_manager &&
      (roleToInvite === ROLE_AGENT || roleToInvite === ROLE_MANAGER)
    ) {
      return true
    }
    return false
  }

  public getRolesToEdit() {
    return ROLE_OPTIONS.filter(r => this.canInviteUserWithRole(r.id))
  }

  public hasQueuePermission(queueId: string): boolean {
    if (!queueId) {
      return false
    }
    return (
      this.is_admin ||
      (this.is_manager &&
        this.projects.filter(p => p.queues.find(q => q === queueId)).length) ||
      (this.is_agent && this.queues.find(q => q.id === queueId))
    )
  }
  isAssignedToProject(projectId: string): boolean {
    return this.projects?.findIndex(p => p.id === projectId) >= 0
  }
  isAssignedToQueue(queueId: string): boolean {
    return this.queues?.findIndex(q => q.id === queueId) >= 0
  }

  public toUserMeUpdateFields(): UserMeUpdateFields {
    return {
      username: this.username,
      first_name: this.first_name,
      last_name: this.last_name,
      email: this.email,
      notifications_duration: this.notifications_duration,
      notifications_new_case: this.notifications_new_case,
      notifications_new_message: this.notifications_new_message,
      translation_settings: this.translation_settings,
    }
  }

  public toUserUpdateFields(): UserUpdateFields {
    return {
      id: this.id,
      email: this.email,
      first_name: this.first_name,
      last_name: this.last_name,
      role: this.role,
      has_translation_service: this.has_translation_service,
      num_cases_for_busy: this.num_cases_for_busy,
    }
  }
}

@JsonObject
export class HubtypeUserFacebookPage {
  @JsonProperty('id', String, true)
  public id: string = undefined
  @JsonProperty('name', String, true)
  public name: string = undefined
  // the pic doesn't come from the Back End but it's injected in FacebookComponent.refreshPages()
  @JsonProperty('pic', String, true)
  public pic: string = undefined
}

@JsonObject
export class HubtypeUserStatusEmail {
  @JsonProperty('email', String, true)
  public email: string = undefined
  @JsonProperty('email_status', String, true)
  public emailStatus: EmailStatus = undefined
  @JsonProperty('is_from_another_organization', Boolean, true)
  public isFromAnotherOrganization: boolean = undefined

  @JsonProperty('id', String, true)
  public id?: string = undefined

  @JsonProperty('role', String, true)
  public role?: string = undefined

  @JsonProperty('first_name', String, true)
  public first_name?: string = undefined

  @JsonProperty('last_name', String, true)
  public last_name?: string = undefined
}

export interface UserMeUpdateFields {
  username: string
  first_name: string
  last_name: string
  email: string
  notifications_duration: number
  notifications_new_case: boolean
  notifications_new_message: boolean
  translation_settings: HubtypeTranslationSettings
}

export interface UserUpdateFields {
  id: string
  email: string
  first_name: string
  last_name: string
  role: string
  num_cases_for_busy: number
  has_translation_service: boolean
}

@JsonObject
export class FacebookUserInfo {
  @JsonProperty('email', String, true)
  public email: string = undefined
  @JsonProperty('first_name', String, true)
  public firstName: string = undefined
  @JsonProperty('last_name', String, true)
  public lastName: string = undefined
  @JsonProperty('id', String, true)
  public id: string = undefined
}
