import { Any, JsonObject, JsonProperty } from 'json2typescript'
import {
  COMPONENT_TYPES_WITH_VARS,
  HubtypeWhatsappTemplateComponent,
  WhatsAppComponentType,
  WhatsAppHeaderFormat,
} from './facebook-whatsapp-template'
import { AnyHubtypeTemplate, TemplateButton } from './hubtype-template-any'
import { HubtypeTemplateVariable } from './hubtype-template-variable'

// https://developers.facebook.com/docs/whatsapp/business-management-api/message-templates/
@JsonObject
export class HubtypeWhatsappTemplate extends AnyHubtypeTemplate {
  @JsonProperty('components', Any, true)
  public components?: HubtypeWhatsappTemplateComponent[] = []

  @JsonProperty('language', String, true)
  public language?: string = undefined

  @JsonProperty('status', String, true)
  public status?: string = undefined

  @JsonProperty('category', String, true)
  public category?: string = undefined

  MEDIA_FILE_NAME_PATTERN = /-tmpid-(.*)-tmpid-(.*)/

  public variables: HubtypeTemplateVariable[] = []

  constructor() {
    super()
    this.isWhatsApp = true
  }

  get icon(): string {
    return 'assets/images/whatsapp-template.png'
  }

  // To be compatible with HubtypeTemplate
  public get text(): string {
    const body = this.getComponent(WhatsAppComponentType.BODY)
    return body.text
  }

  getLanguage(): string {
    return this.language
  }

  getFooter(): string {
    const footer = this.getComponent(WhatsAppComponentType.FOOTER)
    return footer?.text ? footer.text : ''
  }

  getHeader(): string {
    const header = this.getComponent(WhatsAppComponentType.HEADER)
    return header?.text ? header.text : ''
  }

  getMediaHeader(): WhatsAppHeaderFormat | null {
    const header = this.getComponent(WhatsAppComponentType.HEADER)

    const format = header?.format
    if (format === WhatsAppHeaderFormat.TEXT_FORMAT) {
      return null
    }
    return format
  }

  isOwnedMediaFileFromUrl(url: string): boolean {
    const urlMatch = url?.match(this.MEDIA_FILE_NAME_PATTERN)
    return urlMatch?.length && urlMatch[1] === this.id
  }

  getMediaFileNameFromUrl(url: string): string {
    const urlMatch = url?.match(this.MEDIA_FILE_NAME_PATTERN)
    return this.isOwnedMediaFileFromUrl(url) ? urlMatch[2] : ''
  }

  getBody(): string {
    return this.text
  }

  getSourceBody(): string {
    return this.text
  }

  getButtons(): TemplateButton[] {
    const component = this.getComponent(WhatsAppComponentType.BUTTONS)
    if (!component) {
      return []
    }
    return component.buttons.map(but => ({
      type: but.type,
      value: but.text,
    }))
  }

  getComponent(type: WhatsAppComponentType): HubtypeWhatsappTemplateComponent {
    return this.components.find(c => c.type === type)
  }

  getVariable(index: number): HubtypeTemplateVariable {
    return this.getVariables()[index]
  }

  getVariables(): HubtypeTemplateVariable[] {
    if (this.variables.length) {
      return this.variables
    }
    const vars = this.components
      .filter(comp => COMPONENT_TYPES_WITH_VARS.includes(comp.type))
      .map(comp => comp.variables || [])
      .reduce((arr, list) => arr.concat(...list), [])
      .map(v => {
        const value = v.type === 'name' ? 'name' : v.value
        return {
          type: v.type,
          value,
        }
      })

    this.variables = vars
    return vars
  }

  isCampaignsSelectable(): boolean {
    // TODO: Do we need to filter templates with buttons?
    return this.components
      .filter(comp => !COMPONENT_TYPES_WITH_VARS.includes(comp.type))
      .every(
        comp =>
          !comp.text?.match(AnyHubtypeTemplate.PLACEHOLDER_VARIABLES_REGEX)
      )
  }
}

export interface TemplateMediaFile {
  type: WhatsAppHeaderFormat
  data: string | Blob
  fileName: string
  templateId: string
}
