import { Any, JsonObject, JsonProperty } from 'json2typescript'
import moment from 'moment'
import { containsNormalizedText } from 'utils/string-utils'
import { HubtypeModelConfig } from './hubtype-model-config'
import { HubtypeProviderAccount } from './hubtype-provider-account'
import { HubtypeUser, HubtypeUserMinimal } from './hubtype-user'
import { HubtypeScheduleSettings } from './schedule-settings-model-config'

// TODO: deprecate status_open/close and use QUEUE_OPEN/CLOSED instead on queue status
// user for queue status only, NOT USE THIS
export const STATUS_OPEN = 'status_open'
export const STATUS_CLOSED = 'status_closed'
export const STATUS_OPEN_LABEL = 'Open'
export const STATUS_CLOSED_LABEL = 'Closed'

export const QUEUE_STATUS_LABELS = {
  [STATUS_OPEN]: STATUS_OPEN_LABEL,
  [STATUS_CLOSED]: STATUS_CLOSED_LABEL,
}

export interface QueueStatus {
  value: typeof STATUS_OPEN | typeof STATUS_CLOSED
  label: typeof STATUS_OPEN_LABEL | typeof STATUS_CLOSED_LABEL
}

export const MANUAL_OPENING_MODE = 'Manual'
export const SCHEDULED_OPENING_MODE = 'Scheduled'
export const MANUAL_OPENING_MODE_LABEL = 'Manual'
export const SCHEDULED_OPENING_MODE_LABEL = 'Scheduled'

export const OPENING_MODE_LABELS = {
  [MANUAL_OPENING_MODE]: MANUAL_OPENING_MODE_LABEL,
  [SCHEDULED_OPENING_MODE]: SCHEDULED_OPENING_MODE_LABEL,
}
export interface QueueOpeningModes {
  value: typeof MANUAL_OPENING_MODE | typeof SCHEDULED_OPENING_MODE
  label: typeof MANUAL_OPENING_MODE_LABEL | typeof SCHEDULED_OPENING_MODE_LABEL
}
export const QUEUE_OPENING_MODES: QueueOpeningModes[] = [
  { value: MANUAL_OPENING_MODE, label: MANUAL_OPENING_MODE_LABEL },
  { value: SCHEDULED_OPENING_MODE, label: SCHEDULED_OPENING_MODE_LABEL },
]

export const LOW_PRIORITY = 'low'
export const MEDIUM_PRIORITY = 'medium'
export const HIGH_PRIORITY = 'high'
export const LOW_PRIORITY_LABEL = 'Low'
export const MEDIUM_PRIORITY_LABEL = 'Medium'
export const HIGH_PRIORITY_LABEL = 'High'
export interface QueuePriority {
  value: typeof LOW_PRIORITY | typeof MEDIUM_PRIORITY | typeof HIGH_PRIORITY
  viewValue:
    | typeof LOW_PRIORITY_LABEL
    | typeof MEDIUM_PRIORITY_LABEL
    | typeof HIGH_PRIORITY_LABEL
  numericValue: number
}
export const QUEUE_PRIORITIES: QueuePriority[] = [
  { value: LOW_PRIORITY, viewValue: LOW_PRIORITY_LABEL, numericValue: 0 },
  { value: MEDIUM_PRIORITY, viewValue: MEDIUM_PRIORITY_LABEL, numericValue: 1 },
  { value: HIGH_PRIORITY, viewValue: HIGH_PRIORITY_LABEL, numericValue: 2 },
]

interface MinifiedQueue {
  id?: string
  name: string
}

@JsonObject
export class HubtypeBasicQueue implements MinifiedQueue {
  @JsonProperty('id', String, true)
  public id?: string = undefined
  @JsonProperty('name', String, true)
  public name: string = undefined
  @JsonProperty('project_name', String, true)
  public project_name: string = undefined
  @JsonProperty('project_id', String, true)
  public project_id: string = undefined
  @JsonProperty('status', String, true)
  public status?: QueueStatus['value'] = undefined

  public get statusName(): string {
    return QUEUE_STATUS_LABELS[this.status]
  }

  public get toggled(): boolean {
    return this.status === STATUS_OPEN
  }

  public get isOpen(): boolean {
    return this.status === STATUS_OPEN
  }

  public get isClosed(): boolean {
    return this.status === STATUS_CLOSED
  }
  static sort(a: MinifiedQueue, b: MinifiedQueue) {
    return a.name > b.name ? 1 : -1
  }

  public toggle() {
    if (this.status === STATUS_OPEN) {
      this.status = STATUS_CLOSED
    } else {
      this.status = STATUS_OPEN
    }
  }
}

export class HubtypeScheduleQueue extends HubtypeBasicQueue {
  @JsonProperty('opening_mode', String, true)
  public opening_mode: QueueOpeningModes['value'] = MANUAL_OPENING_MODE
  @JsonProperty('schedule_settings', HubtypeScheduleSettings, true)
  public schedule_settings?: HubtypeScheduleSettings = undefined

  public get timezoneName(): string {
    return this.schedule_settings?.timezoneName
  }

  public get todaysSchedule(): string {
    if (
      this.opening_mode === SCHEDULED_OPENING_MODE &&
      this.schedule_settings
    ) {
      return this.schedule_settings.todaysSchedule
    }
    return 'N/A'
  }

  public get openingModeName(): string {
    return OPENING_MODE_LABELS[this.opening_mode]
  }
}

@JsonObject
export class HubtypeListQueue extends HubtypeScheduleQueue {
  @JsonProperty('num_assigned_agents', Number, true)
  public num_assigned_agents?: number = undefined
}

@JsonObject
export class HubtypeQueue extends HubtypeScheduleQueue {
  @JsonProperty('pic', String, true)
  public pic?: string = undefined // TODO: this attribute does not exists, only put here for AOT compilation
  @JsonProperty('agents', [HubtypeUser], true)
  public agents?: HubtypeUserMinimal[] = undefined
  @JsonProperty('provider_accounts', [HubtypeProviderAccount], true)
  public provider_accounts?: HubtypeProviderAccount[] = undefined
  @JsonProperty('model_config', HubtypeModelConfig, true)
  public model_config?: HubtypeModelConfig = undefined
  @JsonProperty('waiting_cases_count', Number, true)
  public waiting_cases_count?: number = undefined
  @JsonProperty('idle_cases_count', Number, true)
  public idle_cases_count?: number = undefined
  @JsonProperty('attending_cases_count', Number, true)
  public attending_cases_count?: number = undefined
  @JsonProperty('max_waiting_time', Any, true)
  public max_waiting_time?: any = undefined

  public agentsConnected: number
  constructor() {
    super()
  }

  public get max_waiting_time_moment() {
    return moment(this.max_waiting_time)
  }

  public get isDisabled(): boolean {
    return false
  }

  public toQueueData(projectName: string): QueueData {
    return {
      id: this.id,
      name: this.name,
      project: projectName,
    }
  }

  public setUpdatedFields(data: HubtypeQueueUpdated) {
    const fields = Object.keys(data)
    fields.forEach(field => {
      if (field != 'id') {
        this[field] = data[field]
      }
    })
  }

  public setAgentsConnectd(users: HubtypeUser[]) {
    this.agentsConnected = users.filter(
      user =>
        user.is_agent &&
        (user.isBusy || user.isAvailable) &&
        user.queues.find(q => q.id === this.id)
    )?.length
  }
}

export class DashboardHubtypeQueue {
  @JsonProperty('name', String, true)
  public name: string = undefined
  @JsonProperty('sla', String, true)
  public sla?: string = undefined
  @JsonProperty('cases_rec', Number, true)
  public cases_rec?: number = undefined
  @JsonProperty('cases_wait', Number, true)
  public cases_wait?: number = undefined
  @JsonProperty('cases_idle', Number, true)
  public cases_idle?: number = undefined
  @JsonProperty('cases_att', Number, true)
  public cases_attending?: number = undefined
  @JsonProperty('cases_resolv', Number, true)
  public cases_resolv?: number = undefined
  @JsonProperty('avg_initial_resp', String, true)
  public avg_initial_resp?: string = undefined
  @JsonProperty('avg_resp', String, true)
  public avg_resp?: string = undefined
  @JsonProperty('avg_resol', String, true)
  public avg_resol?: string = undefined
  @JsonProperty('max_waiting', String, true)
  public max_waiting?: string = undefined
  @JsonProperty('agents_available', String, true)
  public agents_available?: string = undefined

  constructor() {}
}

export class HubtypeQueueCounter {
  @JsonProperty('queue_id', String, true)
  public queue_id?: string = undefined
  @JsonProperty('waiting_cases_count', Number, true)
  public waiting_cases_count?: number = undefined
  @JsonProperty('idle_cases_count', Number, true)
  public idle_cases_count?: number = undefined
  @JsonProperty('attending_cases_count', Number, true)
  public attending_cases_count?: number = undefined

  constructor() {}
}

export interface HubtypeQueueUpdated {
  id: string
  status: string
  name: string
  created_by_id: string
  project_id: string
  project_name: string
  provider_accounts: HubtypeProviderAccount[]
  model_config: HubtypeModelConfig
  waiting_cases_count: number
  max_waiting_time: string
}

export interface QueueData {
  id: string
  name: string
  project: string
}

export function queueFilter(statusFilter: string[], textFilter: string) {
  return (queue: HubtypeListQueue) =>
    ((queue && queue.isOpen && statusFilter.includes(STATUS_OPEN)) ||
      (queue.isClosed && statusFilter.includes(STATUS_CLOSED))) &&
    containsNormalizedText(queue.name, textFilter)
}
