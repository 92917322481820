import { OverlayModule } from '@angular/cdk/overlay'
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { RouterModule } from '@angular/router'
import { NgxPaginationModule } from 'ngx-pagination'
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader'
import { SharedBrandModule } from 'shared/shared-brand/shared-brand.module'
import { SharedCoreModule } from 'shared/shared-core/shared-core.module'
import { HtAreaComponent } from './ht-area/ht-area.component'
import { HtChipComponent } from './ht-chip/ht-chip.component'
import { HtBreadcrumbComponent } from './ht-breadcrumb/ht-breadcrumb.component'
import { HtBtnComponent } from './ht-btn/ht-btn.component'
import {
  HtButtonAiComponent,
  HtButtonBorderlessComponent,
  HtButtonComponent,
  HtButtonConfirmComponent,
  HtButtonDangerComponent,
  HtButtonIconComponent,
  HtButtonPrimaryComponent,
  HtButtonSecondaryComponent,
  HtButtonTertiaryComponent,
} from './ht-button/ht-button.component'
import { HtCheckboxListComponent } from './ht-checkbox-list/ht-checkbox-list.component'
import { HtCheckboxComponent } from './ht-checkbox/ht-checkbox.component'
import { HtContentHeaderComponent } from './ht-content-header/ht-content-header.component'
import { HtDatePickerAnalyticsComponent } from './ht-date-picker-analytics/ht-date-picker-analytics.component'
import { HtDatePickerComponent } from './ht-date-picker/ht-date-picker.component'
import { HtDropdownCheckboxComponent } from './ht-dropdown-checkbox/ht-dropdown-checkbox.component'
import { HtDropdownLinkComponent } from './ht-dropdown-link/ht-dropdown-link.component'
import { HtDropdownComponent } from './ht-dropdown/ht-dropdown.component'
import { HtEmptyCaseComponent } from './ht-empty-case/ht-empty-case.component'
import { HtExpansionPanelComponent } from './ht-expansion-panel/ht-expansion-panel.component'
import { HtFileInputComponent } from './ht-file-input/ht-file-input.component'
import { HtFormFieldCardComponent } from './ht-form-field-card/ht-form-field-card.component'
import { HtInfoMessageComponent } from './ht-info-message/ht-info-message.component'
import { HtInputComponent } from './ht-input/ht-input.component'
import { HtMetricChipComponent } from './ht-metric-chip/ht-metric-chip.component'
import { HtModalComponent } from './ht-modal/ht-modal.component'
import { HtMultiselectDropdownComponent } from './ht-multiselect-dropdown/ht-multiselect-dropdown.component'
import { HtPercentageListComponent } from './ht-percentage-list/ht-percentage-list.component'
import { HtPhoneInputComponent } from './ht-phone-input/ht-phone-input.component'
import { HtRadiobuttonListComponent } from './ht-radiobutton-list/ht-radiobutton-list.component'
import { HtRadiobuttonComponent } from './ht-radiobutton/ht-radiobutton.component'
import { HtSearchBarComponent } from './ht-search-bar/ht-search-bar.component'
import { HtSingleSelectDropdownComponent } from './ht-single-dropdown/ht-single-dropdown.component'
import { HtSwitchComponent } from './ht-switch/ht-switch.component'
import { HtTabComponent } from './ht-tab/ht-tab.component'
import { HtTableFooterComponent } from './ht-table-footer/ht-table-footer.component'
import { HtTableHeaderComponent } from './ht-table-header/ht-table-header.component'
import { HtTableTinyComponent } from './ht-table-tiny/ht-table-tiny.component'
import { HtTableComponent } from './ht-table/ht-table.component'
import { TableColumnDirective } from './ht-table/table-column/table-column-directive'
import { TableColumnComponent } from './ht-table/table-column/table-column.component'
import { TableRowComponent } from './ht-table/table-row/table-row.component'
import { HubtypeLogoComponent } from './hubtype-logo/hubtype-logo.component'
import { HtMetricChipValueComponent as HtMetricChipValueComponent } from './ht-metric-chip/ht-metric-chip-value.component'
import {
  HtDialogComponent,
  HtDialogBodyComponent,
  HtDialogFooterComponent,
} from './ht-dialog/ht-dialog.component'
import { HtBadgeComponent } from './ht-badge/ht-badge.component'
import { HtChipListComponent } from './ht-chip-list/ht-chip-list.component'
import { HtFilterChipComponent } from './ht-filter-chip/ht-filter-chip.component'
import { HtBannerComponent } from './ht-banner/ht-banner.component'
import { HtFilterChipGroupComponent } from './ht-filter-chip-group/ht-filter-chip-group.component'
import { HtChipDSComponent } from './ht-chip-ds/ht-chip-ds.component'

@NgModule({
  declarations: [
    HtBtnComponent,
    HtTabComponent,
    HtBreadcrumbComponent,
    HtInputComponent,
    HtFileInputComponent,
    HtTableComponent,
    TableRowComponent,
    TableColumnComponent,
    TableColumnDirective,
    HtTableHeaderComponent,
    HtTableFooterComponent,
    HtDropdownComponent,
    HtDropdownLinkComponent,
    HtPercentageListComponent,
    HtCheckboxComponent,
    HtDropdownCheckboxComponent,
    HtEmptyCaseComponent,
    HtModalComponent,
    HtDialogComponent,
    HtDialogBodyComponent,
    HtDialogFooterComponent,
    HtRadiobuttonComponent,
    HtRadiobuttonListComponent,
    HtSwitchComponent,
    HtAreaComponent,
    HtDatePickerAnalyticsComponent,
    HtDatePickerComponent,
    HtSearchBarComponent,
    HtMultiselectDropdownComponent,
    HtFilterChipComponent,
    HtFilterChipGroupComponent,
    HtSingleSelectDropdownComponent,
    HtTableTinyComponent,
    HtPhoneInputComponent,
    HtContentHeaderComponent,
    HtCheckboxListComponent,
    HtExpansionPanelComponent,
    HtChipComponent,
    HtChipDSComponent,
    HtChipListComponent,
    HtBadgeComponent,
    HtBannerComponent,
    HtButtonComponent,
    HtButtonPrimaryComponent,
    HtButtonSecondaryComponent,
    HtButtonTertiaryComponent,
    HtButtonBorderlessComponent,
    HtButtonIconComponent,
    HtButtonDangerComponent,
    HtButtonAiComponent,
    HtButtonConfirmComponent,
    HubtypeLogoComponent,
    HtMetricChipComponent,
    HtMetricChipValueComponent,
    HtInfoMessageComponent,
    HtFormFieldCardComponent,
  ],
  exports: [
    HtBtnComponent,
    HtButtonComponent,
    HtButtonPrimaryComponent,
    HtButtonSecondaryComponent,
    HtButtonAiComponent,
    HtButtonConfirmComponent,
    HtButtonTertiaryComponent,
    HtButtonBorderlessComponent,
    HtButtonIconComponent,
    HtButtonDangerComponent,
    HtTabComponent,
    HtBreadcrumbComponent,
    HtInputComponent,
    HtFileInputComponent,
    HtTableComponent,
    TableRowComponent,
    HtTableHeaderComponent,
    HtTableFooterComponent,
    TableColumnComponent,
    TableColumnDirective,
    HtDropdownComponent,
    HtDropdownLinkComponent,
    HtPercentageListComponent,
    HtCheckboxComponent,
    HtCheckboxListComponent,
    HtDropdownCheckboxComponent,
    HtEmptyCaseComponent,
    HtModalComponent,
    HtDialogComponent,
    HtDialogBodyComponent,
    HtDialogFooterComponent,
    HtRadiobuttonComponent,
    HtRadiobuttonListComponent,
    HtSwitchComponent,
    HtAreaComponent,
    HtDatePickerAnalyticsComponent,
    HtDatePickerComponent,
    HtSearchBarComponent,
    HtMultiselectDropdownComponent,
    HtSingleSelectDropdownComponent,
    HtFilterChipComponent,
    HtFilterChipGroupComponent,
    HtTableTinyComponent,
    HtPhoneInputComponent,
    HtContentHeaderComponent,
    HtExpansionPanelComponent,
    HtChipComponent,
    HtChipDSComponent,
    HtBadgeComponent,
    HtBannerComponent,
    HtChipListComponent,
    HubtypeLogoComponent,
    HtMetricChipComponent,
    HtMetricChipValueComponent,
    HtInfoMessageComponent,
    HtFormFieldCardComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    FormsModule,
    NgxPaginationModule,
    NgxSkeletonLoaderModule,
    SharedCoreModule,
    SharedBrandModule,
    OverlayModule,
  ],
})
export class BaseModule {}
