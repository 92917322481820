import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core'

export namespace ButtonProps {
  export type Color = 'primary' | 'secondary'

  export enum Font {
    Normal = 14,
    Small = 12,
    Tiny = 10,
  }

  export enum Size {
    L = 'l',
    M = 'm',
    S = 's',
  }
}

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent implements OnInit, OnChanges, AfterViewInit {
  @Input()
  clazz = ''
  @Input()
  color: ButtonProps.Color = 'primary'
  @Input()
  disabled = false
  @Input()
  forceFocus: number | boolean
  @Input()
  isLoading = false

  @Input()
  font: ButtonProps.Font
  @Input()
  size: ButtonProps.Size = ButtonProps.Size.M
  @Input()
  text = ''
  @Input()
  icon = ''

  @Output()
  btnClick = new EventEmitter<Event>()
  @Output()
  btnFocus = new EventEmitter<boolean>()

  @ViewChild('htButton', { static: false })
  element: ElementRef<HTMLButtonElement>

  allClasses = ''
  recentlyClicked = false
  spinnerDiameter = 15

  constructor() {}

  @HostListener('click', ['$event']) onHostClick(event: Event) {
    event.stopPropagation()
    return false
  }

  ngOnInit() {
    if (!this.font) {
      // Presets
      switch (this.size) {
        case ButtonProps.Size.S:
          this.font = ButtonProps.Font.Tiny
          this.spinnerDiameter = 10
          break
        case ButtonProps.Size.M:
          this.font = ButtonProps.Font.Small
          this.spinnerDiameter = 15
          break
        case ButtonProps.Size.L:
        default:
          this.font = ButtonProps.Font.Normal
          this.spinnerDiameter = 20
      }
    }
    this.setClass()
  }

  ngAfterViewInit() {
    if (this.forceFocus) {
      this.element?.nativeElement.focus()
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.clazz || changes.color || changes.size) {
      this.setClass()
    }
    if (
      changes.forceFocus &&
      !changes.forceFocus.isFirstChange() &&
      changes.forceFocus.currentValue !== changes.forceFocus.previousValue
    ) {
      this.element?.nativeElement.focus()
    }
    if (changes.loading?.previousValue !== changes.loading?.currentValue) {
      this.element?.nativeElement.blur()
    }
  }

  onClickButton($event: Event) {
    if (!this.recentlyClicked && !this.isLoading) {
      this.btnClick.emit($event)
    }
    this.recentlyClicked = true
    setTimeout(() => {
      this.recentlyClicked = false
    }, 300)
  }

  private setClass(): void {
    this.allClasses =
      `ht-btn btn-${this.color} btn-font-${this.font} btn-size-${this.size} ${this.clazz}`.trim()
  }
}
