import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

export enum HtBtnTypes {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  GHOST = 'ghost',
  GHOST_SECONDARY = 'ghost-secondary',
  PRIMARY_RED = 'primary-red',
}

export enum HtBtnSizes {
  TINY = 'tiny',
  SMALL = 'small',
  LARGE = 'large',
  DEFAULT = 'default',
}
@Component({
  selector: 'ht-btn',
  templateUrl: './ht-btn.component.html',
  styleUrls: ['./ht-btn.component.scss'],
})
export class HtBtnComponent implements OnInit {
  @Input()
  text: string

  @Input()
  type: HtBtnTypes = HtBtnTypes.PRIMARY

  @Input()
  isSubmit = false

  @Input()
  disabled = false

  @Input()
  icon = ''

  @Input()
  iconRight = ''

  @Input()
  iconPosition: 'left' | 'right' = 'left'

  @Input()
  hidden = false

  @Input()
  size: HtBtnSizes = HtBtnSizes.DEFAULT

  @Input()
  fullWidth?: boolean

  @Input()
  customRole?: string = 'button'

  @Output()
  onclick = new EventEmitter<any>()

  constructor() {}

  ngOnInit(): void {
    this.setIconsStyle()
  }

  setIconsStyle() {
    // if the icon does not include the style, add fa-solid class (ex: fa-check)
    if (this.icon && !this.icon.split(' ')[1]) {
      this.icon = 'fa-solid ' + this.icon
    }
    if (this.iconRight && !this.iconRight.split(' ')[1]) {
      this.iconRight = 'fa-solid ' + this.iconRight
    }
  }
}
