import { Pipe, PipeTransform } from '@angular/core'
import { MarkdownString } from '../utils/markdown'

const BOLD_REGEX = /(\ |^)(\*[^\ ][^*]+[^\ ]\*)([^\w]|$)/
const CROSSLINE_REGEX = /(\ |^)(\~[^\ ][^*]+[^\ ]\~)([^\w]|$)/
const ITALIC_REGEX = /(\ |^)(\_[^\ ][^_]+[^\ ]\_)([^\w]|$)/
const BREAKLINE_REGEX = /\n/g

@Pipe({
  name: 'whatsappMarkdown',
})
export class WhatsappMarkdownPipe implements PipeTransform {
  transform(value: string, ...args: any[]): any {
    if (!value) {
      return ''
    }
    const content = new MarkdownString(value)
    return content
      .formatHtml(BOLD_REGEX, 'b')
      .formatHtml(ITALIC_REGEX, 'i')
      .formatHtml(CROSSLINE_REGEX, 'del')
      .format(BREAKLINE_REGEX, '<br>')
      .toString()
  }
}
