import { Any, JsonObject, JsonProperty } from 'json2typescript'
import { WhatsAppHeaderFormat } from './facebook-whatsapp-template'
import { AnyHubtypeTemplate, TemplateButton } from './hubtype-template-any'
import { HubtypeTemplateVariable } from './hubtype-template-variable'

export const TEMPLATE_SORTING_PROPERTIES = ['name']

@JsonObject
export class HubtypeTemplate extends AnyHubtypeTemplate {
  @JsonProperty('organization', String, true)
  public organization?: string = undefined

  @JsonProperty('text', String, false)
  public text?: string = undefined

  @JsonProperty('lang', String, true)
  public lang?: string = undefined

  @JsonProperty('modified_at', Any, true)
  public modified_at?: string = undefined

  @JsonProperty('created_at', Any, true)
  public created_at?: string = undefined

  @JsonProperty('folder', String, true)
  public folder?: string = undefined

  // TODO: add ProviderAccount type

  public sourceText: string

  constructor() {
    super()
    this.isWhatsApp = false
  }

  // to be compatible with HubtypeWhatsappTemplate
  public get language(): string {
    return this.lang
  }

  get icon(): string {
    return ''
  }

  public get pic(): string | null {
    return null
  }

  getLanguage(): string {
    return this.lang
  }
  getFooter(): string {
    return ''
  }

  getHeader(): string {
    return ''
  }

  getMediaHeader(): WhatsAppHeaderFormat {
    return null
  }

  getMediaFileNameFromUrl(url: string): string {
    return ''
  }

  isOwnedMediaFileFromUrl(url: string): boolean {
    return false
  }

  getBody(): string {
    return this.text
  }

  getButtons(): TemplateButton[] {
    return []
  }

  getSourceBody(): string {
    if (!this.sourceText) {
      this.sourceText = this.text
    }
    return this.sourceText
  }

  getVariable(index: number): HubtypeTemplateVariable {
    return {
      value: '',
      type: 'manual',
    }
  }

  isCampaignsSelectable(): boolean {
    return false
  }

  updateText(text: string) {
    if (!this.sourceText) {
      this.sourceText = this.text
    }
    this.text = text
  }
}
