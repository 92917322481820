import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FlexLayoutModule } from '@angular/flex-layout'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { BaseModule } from 'shared/base/base.module'
import { AppMaterialModule } from '../app-material.module'
import { SharedComponentModule } from '../shared/shared-components.module'
import { AuthRoutingModule } from './auth-routing.module'
import { LoginFormComponent } from './login/login-form/login-form.component'
import { LoginComponent } from './login/login.component'
import { ResetPwdComponent } from './reset-pwd/reset-pwd.component'
import { SignupFormComponent } from './signup/signup-form/signup-form.component'
import { SignupComponent } from './signup/signup.component'

@NgModule({
  declarations: [
    LoginComponent,
    LoginFormComponent,
    ResetPwdComponent,
    SignupComponent,
    SignupFormComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    SharedComponentModule,
    AuthRoutingModule,
    AppMaterialModule,
    BaseModule,
  ],
})
export class AuthModule {}
