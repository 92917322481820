import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { TinybirdApiService } from '../hubtype-api/tinybird-api.service'
import { LOGOUT } from 'src/app/actions/auth'
import { tap } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class TinybirdApiEffects {
  $clearTokenOnLogout = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LOGOUT),
        tap(() => this.tinybirdApiService.clearToken())
      ),
    { dispatch: false }
  )

  constructor(
    private actions$: Actions,
    private tinybirdApiService: TinybirdApiService
  ) {}
}
