import { Any, JsonObject, JsonProperty } from 'json2typescript'
import { HubtypeModelConfig } from './hubtype-model-config'
import { HubtypeQueue } from './hubtype-queue'
import { HubtypeUserMinimal } from './hubtype-user'

@JsonObject
export class HubtypeProject {
  @JsonProperty('id', String, true)
  public id?: string = undefined
  @JsonProperty('name', String, true)
  public name?: string = undefined
  @JsonProperty('managers', Any, true)
  public managers?: HubtypeUserMinimal[] = undefined
  @JsonProperty('queues', Any, true)
  public queues?: HubtypeQueue[] = undefined
  @JsonProperty('pic', String, true)
  public pic?: string = undefined
  @JsonProperty('model_config', Any, true)
  public model_config?: HubtypeModelConfig = undefined
  @JsonProperty('mandatory_contact_reasons', Boolean, true)
  public mandatory_contact_reasons?: boolean = false
  @JsonProperty('only_one_contact_reason_allowed', Boolean, true)
  public only_one_contact_reason_allowed?: boolean = false
  @JsonProperty('no_autoassign_offline_webchats_enabled', Any, true)
  public no_autoassign_offline_webchats_enabled?: boolean = false

  constructor() {}

  public get color(): string {
    let c = '0'
    if (this.id) {
      c = (this.id.charCodeAt(0) % 4).toString()
    }
    return 'color' + c
  }
}
