import { Action } from '@ngrx/store'
import { HubtypeTemplate } from 'models/hubtype-template'
import { HubtypeWhatsappTemplate } from 'models/hubtype-whatsapp-template'

export const TEMPLATES_HUBTYPE_GET = '[Templates] Hubtype Get'
export const TEMPLATES_HUBTYPE_RECEIVE = '[Templates] Hubtype Received'
export const TEMPLATES_HUBTYPE_ERROR = '[Templates] Hubtype Load Error'
export const TEMPLATES_WHATSAPP_GET = '[Templates] WhatsApp Get'
export const TEMPLATES_WHATSAPP_RECEIVE = '[Templates] WhatsApp Received'
export const TEMPLATES_WHATSAPP_ERROR = '[Templates] WhatsApp Load Error'

/**
 * Every action is comprised of at least a type and an optional
 * payload. Expressing actions as classes enables powerful
 * type checking in reducer functions.
 *
 * See Discriminated Unions: https://www.typescriptlang.org/docs/handbook/advanced-types.html#discriminated-unions
 */
export class TemplatesHubtypeGetAction implements Action {
  readonly type = TEMPLATES_HUBTYPE_GET

  constructor() {}
}

export class TemplatesHubtypeReceiveAction implements Action {
  readonly type = TEMPLATES_HUBTYPE_RECEIVE

  constructor(public readonly payload: HubtypeTemplate[]) {}
}

export class TemplatesWhatsAppGetAction implements Action {
  readonly type = TEMPLATES_WHATSAPP_GET

  constructor() {}
}

export class TemplatesHubtypeErrorAction implements Action {
  readonly type = TEMPLATES_WHATSAPP_ERROR

  constructor(public readonly payload: unknown) {}
}

export class TemplatesWhatsAppReceiveAction implements Action {
  readonly type = TEMPLATES_WHATSAPP_RECEIVE

  constructor(public readonly payload: HubtypeWhatsappTemplate[]) {}
}

export class TemplatesWhatsAppErrorAction implements Action {
  readonly type = TEMPLATES_WHATSAPP_ERROR

  constructor(public readonly payload: unknown) {}
}

/**
 * Export a type alias of all actions in this action group
 * so that reducers can easily compose action types
 */
export type Actions = TemplatesHubtypeGetAction | TemplatesWhatsAppGetAction
