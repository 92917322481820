import { Component, Inject, OnInit } from '@angular/core'
import { UntypedFormBuilder, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { Store } from '@ngrx/store'
import {
  customEmailValidator,
  handleServerError,
  validateAllFormFields,
} from 'models/form'
import { HubtypeUser } from 'models/hubtype-user'
import { finalize, first } from 'rxjs/operators'
import { CustomError } from 'src/app/constants/errors'
import { FeedbackService } from 'src/app/services/feedback.service'
import * as authActions from '../../actions/auth'
import * as fromRoot from '../../reducers'
import { UserService } from '../../services/hubtype-api/user.service'

@Component({
  selector: 'complete-profile',
  templateUrl: 'complete-profile.component.html',
  styleUrls: ['complete-profile.component.scss'],
})
export class CompleteProfileComponent implements OnInit {
  form = this.fb.group({
    email: ['', [Validators.required, customEmailValidator()]],
    username: [''],
    firstName: [''],
    lastName: [''],
  })
  isLoading = false
  user: HubtypeUser

  constructor(
    private router: Router,
    private fb: UntypedFormBuilder,
    private store: Store<fromRoot.State>,
    private feedbackService: FeedbackService,
    @Inject('userService') private userService: UserService
  ) {}

  ngOnInit() {
    this.setInitialFormValues()
  }

  onSubmit() {
    if (!validateAllFormFields(this.form)) {
      return
    }
    const user = this.updateUserFromForm()

    this.isLoading = true
    this.userService
      .update(user)
      .pipe(
        first(),
        finalize(() => (this.isLoading = false))
      )
      .subscribe({
        next: result => {
          this.store.dispatch(new authActions.SetMeAction(result))
          this.router.navigate(['desk'])
        },
        error: err => {
          handleServerError({
            error: err,
            form: this.form,
            feedbackService: this.feedbackService,
            customUnhandledError: CustomError.PROFILE_UPDATE,
          })
        },
      })
  }

  private setInitialFormValues() {
    this.store
      .select(fromRoot.getMeState)
      .pipe(first(Boolean))
      .subscribe(me => {
        this.user = me
        this.setProfileFormValues(me)
      })
  }

  private setProfileFormValues(user: HubtypeUser) {
    this.form.patchValue({
      email: user.email,
      username: user.username,
      firstName: user.first_name,
      lastName: user.last_name,
    })
  }

  private updateUserFromForm(): HubtypeUser {
    const { email, username, firstName, lastName } = this.form.value

    this.user.email = email
    this.user.username = username
    this.user.first_name = firstName
    this.user.last_name = lastName

    return this.user
  }
}
