export class Task {
  private id: string
  private startedAt: number
  private finishedAt: number
  private error: string

  constructor(
    private name: string,
    private meta: any,
    doStart = true
  ) {
    if (doStart) {
      this.startedAt = new Date().getTime()
      this.id = `${name}-${this.startedAt}`
      return
    }
    this.id = name
  }

  get taskId(): string {
    return this.id
  }

  get isError(): boolean {
    return Boolean(this.error)
  }

  get isFinished(): boolean {
    return Boolean(this.finishedAt)
  }

  get finishedTimestap(): number {
    return this.finishedAt
  }

  get startedTimestamp(): number {
    return this.startedAt
  }

  start() {
    if (this.startedAt && this.finishedAt) {
      throw new Error(`The task ${this.name} was already completed.`)
    } else if (this.startedAt) {
      throw new Error(`The task ${this.name} was already started.`)
    }
    this.startedAt = new Date().getTime()
  }

  finish() {
    if (!this.startedAt) {
      throw new Error(`The task ${this.name} was never started.`)
    }
    this.finishedAt = new Date().getTime()
  }

  getError(): string {
    return this.error
  }

  getMeta(): any {
    return this.meta
  }

  getName(): string {
    return this.name
  }

  setError(error: string) {
    this.error = error
  }

  setId(id: string) {
    this.id = id
  }

  setFinished(timestamp: number) {
    this.finishedAt = timestamp
  }

  setStarted(timestamp: number) {
    this.startedAt = timestamp
  }
}
