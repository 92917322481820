import MarkdownIt from 'markdown-it'

export class MarkdownString {
  constructor(private content: string) {}

  format(lookup: RegExp | string, replacement: string): MarkdownString {
    return new MarkdownString(this.content.replace(lookup, replacement))
  }

  formatHtml(lookup: RegExp, htmlElementValue: string): MarkdownString {
    let matcher = this.content.match(lookup)
    if (!matcher) {
      return this
    }

    const callback = matcher.input ? this.groupFormat : this.iterativeFormat
    const formatted = callback(this.content, lookup, htmlElementValue)

    return new MarkdownString(formatted)
  }

  toString(): string {
    return this.content
  }

  private iterativeFormat(
    content: string,
    lookup: RegExp,
    htmlElementValue: string
  ): string {
    let formatted = content
    let matcher = formatted.match(lookup)
    if (!matcher) {
      return content
    }
    for (let match of matcher) {
      const replacement = `<${htmlElementValue}>${match.substring(
        1,
        match.length - 1
      )}</${htmlElementValue}>`
      formatted = formatted.replace(match, replacement)
    }
    return formatted
  }

  private groupFormat(
    content: string,
    lookup: RegExp,
    htmlElementValue: string
  ): string {
    const LOOP_ITERATION_LIMIT = 50

    let formatted = content
    let matcher = formatted.match(lookup)
    let i = 0
    while (matcher && i < LOOP_ITERATION_LIMIT) {
      if (!matcher[2]) {
        break
      }

      const match = matcher[2]
      const replacement = `<${htmlElementValue}>${match.substring(
        1,
        match.length - 1
      )}</${htmlElementValue}>`

      formatted = formatted.replace(match, replacement)
      matcher = formatted.match(lookup)
    }
    return formatted
  }
}

export namespace Markdown {
  const md = new MarkdownIt({
    html: true,
    linkify: true,
    typographer: true,
  })
  // Support opening links in new tabs: https://github.com/markdown-it/markdown-it/blob/master/docs/architecture.md#renderer
  const renderer =
    md.renderer.rules.link_open ||
    // eslint-disable-next-line
    function (tokens, idx, options, env, self) {
      return self.renderToken(tokens, idx, options)
    }
  md.renderer.rules.link_open = (tokens, idx, options, env, self) => {
    const aIndex = tokens[idx].attrIndex('target')
    if (aIndex < 0) {
      tokens[idx].attrPush(['target', '_blank'])
    } else {
      tokens[idx].attrs[aIndex][1] = '_blank'
    }
    return renderer(tokens, idx, options, env, self)
  }

  export function getMarkdown() {
    return md
  }
}
