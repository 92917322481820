import { Action } from '@ngrx/store'
import { HubtypeQueue } from 'models/hubtype-queue'
import { HubtypeUser } from 'models/hubtype-user'

export const CASE_QUEUES_GET = '[Shared] Get Case Queues'
export const CASE_QUEUES_RECEIVE = '[Shared] Receive Case Queues'
export const CASE_QUEUES_ERROR = '[Shared] Error Case Queues'

export const USERS_GET = '[Shared] Get Users'
export const USERS_READY = '[Shared] Ready Users'
export const USERS_RECEIVE = '[Shared] Receive Users'
export const USERS_ERROR = '[Shared] Error Users'

export class CaseQueuesGetAction implements Action {
  readonly type = CASE_QUEUES_GET

  constructor() {}
}

export class CaseQueuesReceiveAction implements Action {
  readonly type = CASE_QUEUES_RECEIVE

  constructor(public payload: HubtypeQueue[]) {}
}

export class UsersGetAction implements Action {
  readonly type = USERS_GET

  constructor() {}
}

export class UsersReceiveAction implements Action {
  readonly type = USERS_RECEIVE

  constructor(public payload: HubtypeUser[]) {}
}

export type Actions =
  | CaseQueuesGetAction
  | CaseQueuesReceiveAction
  | UsersGetAction
  | UsersReceiveAction
