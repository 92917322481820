import { Component, Input } from '@angular/core'
import { HubtypeProviderAccount } from '../../../models/hubtype-provider-account'

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
})
export class AvatarComponent {
  @Input() pic?: string
  @Input() color?: string
  @Input() status?: string
  @Input() provider: HubtypeProviderAccount

  constructor() {}
}
