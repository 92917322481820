import { Pipe, PipeTransform } from '@angular/core'
import moment from 'moment'

// TODO: It might be convenient to create a constant file for this
const DEFAULT_FORMAT = 'LL'
const CUSTOM_FORMATS: { [custom: string]: string } = {
  HLLL: 'MMMM Do YYYY, H:mm',
  HLLLY: 'MMMM Do YYYY H:mm',
  full: 'D/MM/YYYY, HH:mm ([GMT]Z)',
}
const YEAR_CHECK_FORMATS = ['HLLL']

@Pipe({
  name: 'moment',
})
export class MomentPipe implements PipeTransform {
  transform(value: moment.Moment | string | number, ...args: any[]): string {
    const momentValue = moment(value)
    let format = args[0]
    if (!format) {
      return momentValue.format(DEFAULT_FORMAT)
    }
    if (CUSTOM_FORMATS[format] !== undefined) {
      format = this.translateCustomFormat(momentValue, format)
    }
    return momentValue.format(format)
  }

  private translateCustomFormat(value: moment.Moment, customFormat: string) {
    let format = CUSTOM_FORMATS[customFormat]

    if (
      YEAR_CHECK_FORMATS.includes(customFormat) &&
      value.year() === moment().year()
    ) {
      format = format.replace(/\ [Yy]+/, '')
    }
    return format
  }
}
