import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'hourminutes',
})
export class HourMinutesPipe implements PipeTransform {
  transform(value: number): any {
    if (value) {
      const sec = value
      let hours = Math.floor(sec / 3600)
      let minutes = Math.floor((sec - hours * 3600) / 60)

      // add 0 if value < 10; Example: 2 => 02

      return hours + 'h ' + minutes + 'm'
    }
    return value
  }
}
