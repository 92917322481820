<div class="flex-container">
  <div class="inner">
    <div class="title">Welcome, {{ bizName }}</div>
    <div class="info">
      Select which bot do you want to connect to Apple Messages
    </div>
    <div><img [src]="bizLogo | safe: 'resourceUrl'" /></div>

    <div class="select-bot-section">
      <div class="bot-connect">Bot to connect</div>
      <div>
        <app-selector
          *ngIf="bots"
          [values]="bots"
          label="Select one of your bots"
          emptyLabel="Select one of your bots"
          valueLabel="name"
          (selectValue)="onSelectBot($event)"
        >
        </app-selector>
      </div>
      <div class="bot-error" *ngIf="showSelectBotError">
        <mat-icon class="error-icon">error</mat-icon>Select which bot do you
        want to use
      </div>
      <app-button
        color="primary"
        text="Connect"
        size="m"
        (btnClick)="connect()"
      ></app-button>
    </div>
  </div>
</div>
