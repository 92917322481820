import { JsonObject, JsonProperty } from 'json2typescript'

@JsonObject
export class HubtypeModelConfig {
  @JsonProperty('initial_message', String, true)
  public initial_message?: string = undefined
  @JsonProperty('closed_message', String, true)
  public closed_message?: string = undefined
  @JsonProperty('num_cases_for_busy', Number, true)
  public num_cases_for_busy?: number = undefined
  @JsonProperty('time_idle', String, true)
  public time_idle?: string = undefined
  @JsonProperty('time_case_resolved', String, true)
  public time_case_resolved?: string = undefined
  @JsonProperty('time_waiting_sla_1', String, true)
  public time_waiting_sla_1?: string = undefined
  @JsonProperty('time_waiting_sla_2', String, true)
  public time_waiting_sla_2?: string = undefined
  @JsonProperty('target_sla', Number, true)
  public target_sla?: number = undefined
  @JsonProperty('time_case_closed', String, true)
  public time_case_closed?: string = undefined
  @JsonProperty('availability_threshold_waiting_cases', Number, true)
  public availability_threshold_waiting_cases?: number = undefined
  @JsonProperty('prioritisation', String, true)
  public prioritisation?: string = undefined
}
