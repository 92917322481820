import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'percentageWithSign',
})
export class PercentageWithSignPipe implements PipeTransform {
  transform(value: any): any {
    if (isNaN(value)) return null

    value = Math.round(value * 100) / 100
    return (value > 0 ? '+' : '') + value + '%'
  }
}
