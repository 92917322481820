import {
  Component,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core'

export const enum HtButtonVariant {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
  BORDERLESS = 'borderless',
  ICON = 'icon',
  DANGER = 'danger',
  AI = 'ai',
  CONFIRM = 'confirm',
}

export const enum HtButtonSize {
  LARGE = 'large',
  MEDIUM = 'medium',
  SMALL = 'small',
  TINY = 'tiny',
}

@Component({
  selector: '[ht-button]',
  templateUrl: './ht-button.component.html',
  styleUrls: ['./ht-button.component.scss'],
  host: {
    '[attr.aria-disabled]': 'isDisabled || isLoading',
  },
})
export class HtButtonComponent implements OnInit, OnChanges {
  @HostBinding('class') variantAndSizeClass = []
  @HostBinding('class.ht-button--full-width') @Input() fullWidth = false
  @HostBinding('class.ht-button--icon-only') @Input() iconOnly = false
  @HostBinding('class.ht-button--is-loading') @Input() isLoading = false

  @Input() isDisabled = false
  @Input() loadingText = ''
  @Input() size = HtButtonSize.MEDIUM
  @Input() variant = HtButtonVariant.PRIMARY

  hideContent = false

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isLoading) {
      this.updateHideContent()
    }
    if (changes.variant || changes.size) {
      this.updateSizeAndVariantClass()
    }
  }

  ngOnInit(): void {
    this.updateHideContent()
    this.updateSizeAndVariantClass()
  }

  activateLoading(): void {
    this.isLoading = true
    this.updateHideContent()
  }

  deactivateLoading(): void {
    this.isLoading = false
    this.updateHideContent()
  }

  private updateHideContent(): void {
    this.hideContent = this.isLoading && this.variant === HtButtonVariant.ICON
  }

  private updateSizeAndVariantClass(): void {
    this.variantAndSizeClass = [
      'ht-button',
      `ht-button--${this.size || HtButtonSize.MEDIUM}`,
      `ht-button--${this.variant || HtButtonVariant.PRIMARY}`,
    ]
  }
}

@Component({
  selector: '[ht-button-primary]',
  templateUrl: './ht-button.component.html',
  styleUrls: ['./ht-button.component.scss'],
})
export class HtButtonPrimaryComponent extends HtButtonComponent {
  constructor() {
    super()
    this.variant = HtButtonVariant.PRIMARY
  }
}

@Component({
  selector: '[ht-button-secondary]',
  templateUrl: './ht-button.component.html',
  styleUrls: ['./ht-button.component.scss'],
})
export class HtButtonSecondaryComponent extends HtButtonComponent {
  constructor() {
    super()
    this.variant = HtButtonVariant.SECONDARY
  }
}

@Component({
  selector: '[ht-button-tertiary]',
  templateUrl: './ht-button.component.html',
  styleUrls: ['./ht-button.component.scss'],
})
export class HtButtonTertiaryComponent extends HtButtonComponent {
  constructor() {
    super()
    this.variant = HtButtonVariant.TERTIARY
  }
}

@Component({
  selector: '[ht-button-borderless]',
  templateUrl: './ht-button.component.html',
  styleUrls: ['./ht-button.component.scss'],
})
export class HtButtonBorderlessComponent extends HtButtonComponent {
  constructor() {
    super()
    this.variant = HtButtonVariant.BORDERLESS
  }
}

@Component({
  selector: '[ht-button-icon]',
  templateUrl: './ht-button.component.html',
  styleUrls: ['./ht-button.component.scss'],
})
export class HtButtonIconComponent extends HtButtonComponent {
  constructor() {
    super()
    this.variant = HtButtonVariant.ICON
  }
}

@Component({
  selector: '[ht-button-danger]',
  templateUrl: './ht-button.component.html',
  styleUrls: ['./ht-button.component.scss'],
})
export class HtButtonDangerComponent extends HtButtonComponent {
  constructor() {
    super()
    this.variant = HtButtonVariant.DANGER
  }
}

@Component({
  selector: '[ht-button-ai]',
  templateUrl: './ht-button.component.html',
  styleUrls: ['./ht-button.component.scss'],
})
export class HtButtonAiComponent extends HtButtonComponent {
  constructor() {
    super()
    this.variant = HtButtonVariant.AI
  }
}

@Component({
  selector: '[ht-button-confirm]',
  templateUrl: './ht-button.component.html',
  styleUrls: ['./ht-button.component.scss'],
})
export class HtButtonConfirmComponent extends HtButtonComponent {
  constructor() {
    super()
    this.variant = HtButtonVariant.CONFIRM
  }
}
