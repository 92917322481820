import { Pipe, PipeTransform } from '@angular/core'
@Pipe({ name: 'bigNumber' })
export class BigNumberPipe implements PipeTransform {
  transform(value: number): string {
    if (value >= 1000000) {
      const millions = (value / 1000000).toFixed(value % 1000000 === 0 ? 0 : 2)
      return millions + 'M'
    } else if (value >= 1000) {
      const thousands = (value / 1000).toFixed(value % 1000 === 0 ? 0 : 2)
      return thousands + 'K'
    } else {
      return value.toString()
    }
  }
}
