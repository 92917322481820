import { animate, state, style, transition, trigger } from '@angular/animations'
import { Component, Input } from '@angular/core'

@Component({
  selector: 'app-splash',
  templateUrl: './splash.component.html',
  styleUrls: ['./splash.component.scss'],
  animations: [
    trigger('splashFade', [
      state('void', style({ opacity: 0 })),
      state('*', style({ opacity: 1 })),
      transition(':enter', animate('100ms ease-in')),
      transition(':leave', animate('800ms ease-out')),
    ]),
  ],
})
export class SplashComponent {
  @Input() splash = false
}
