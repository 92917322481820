/* eslint-disable max-len */
import { Any, JsonObject, JsonProperty } from 'json2typescript'
import moment from 'moment'
import { HubtypeProviderAccount } from './hubtype-provider-account'
import { HubtypeUser } from './hubtype-user'
import { Paginator } from './paginator'

@JsonObject
export class HubtypeBotUpdate {
  @JsonProperty('count', String, true)
  public id?: string = undefined
  @JsonProperty('created_at', Any, true)
  public created_at?: any = undefined
  @JsonProperty('modified_at', Any, true)
  public modified_at?: any = undefined
  @JsonProperty('published_at', Any, true)
  public published_at?: any = undefined
  @JsonProperty('comment', String, true)
  public comment?: string = undefined
  @JsonProperty('last_modified_by', HubtypeUser, true)
  public last_modified_by?: HubtypeUser = undefined
  @JsonProperty('published_by', HubtypeUser, true)
  public published_by?: HubtypeUser = undefined
  @JsonProperty('version', Any, true)
  public version?: any = undefined

  constructor() {}
}

@JsonObject
export class FlowBuilderSettings {
  @JsonProperty('accessToken', String, true)
  public accessToken?: string = undefined

  @JsonProperty('manageToken', String, true)
  public manageToken?: string = undefined

  @JsonProperty('spaceId', String, true)
  public spaceId?: string = undefined

  @JsonProperty('environment', String, true)
  public environment?: string = undefined

  @JsonProperty('cmsType', String, true)
  public cmsType?: string = undefined

  constructor() {}

  public get is_valid(): boolean {
    if (this.cmsType === 'flow-builder-backend') {
      return true
    }
    return Boolean(
      this.accessToken && this.manageToken && this.spaceId && this.environment
    )
  }

  public toObject() {
    const object = {}
    Object.keys(this).map((key: string) => {
      object[key] = this[key]
    })
    return object
  }
}

@JsonObject
export class HubtypeBot {
  @JsonProperty('id', String, true)
  public id?: string = undefined
  @JsonProperty('name', String, true)
  public name?: string = undefined
  @JsonProperty('framework', String, true)
  public framework?: string = undefined
  @JsonProperty('created_at', Any, true)
  public created_at?: any = undefined
  @JsonProperty('created_by', Any, true)
  public created_by?: any = undefined
  @JsonProperty('active_users', Any, true)
  public active_users?: any = undefined
  @JsonProperty('zendesk_is_active', Boolean, true)
  public zendesk_is_active?: boolean = undefined
  @JsonProperty('zendesk_sitename', String, true)
  public zendesk_sitename?: string = undefined
  @JsonProperty('zendesk_email', String, true)
  public zendesk_email?: string = undefined
  @JsonProperty('zendesk_token', String, true)
  public zendesk_token?: string = undefined
  @JsonProperty('is_debug', Boolean, true)
  public is_debug?: boolean = undefined
  @JsonProperty('last_update', HubtypeBotUpdate, true)
  public last_update?: HubtypeBotUpdate = undefined
  @JsonProperty('organization', String, true)
  public organization?: string = undefined
  @JsonProperty('provider_accounts', [HubtypeProviderAccount], true)
  public provider_accounts?: HubtypeProviderAccount[] = undefined
  @JsonProperty('flow_builder_settings', FlowBuilderSettings, true)
  public flow_builder_settings?: FlowBuilderSettings = undefined

  constructor() {}

  public get is_botonic() {
    // Leaving this temporarily as the API will not return the "framework" field
    // when the backend refactor is deployed
    return !this.framework || this.framework == 'framework_botonic'
  }

  public get is_botson() {
    return this.framework == 'framework_botson'
  }

  public get pic() {
    return 'assets/images/botonic_react_logo.png'
  }
}

@JsonObject
export class HubtypeBotUpdatePaginator implements Paginator<HubtypeBotUpdate> {
  @JsonProperty('count', Number, true)
  public count: number = undefined
  @JsonProperty('next', String, true)
  public next: string = undefined
  @JsonProperty('previous', String, true)
  public previous: string = undefined
  @JsonProperty('results', [HubtypeBotUpdate], true)
  public results: [HubtypeBotUpdate] = undefined

  constructor() {}
}

@JsonObject
export class HubtypeBotStatsPeriod {
  @JsonProperty('start_date', Any, true)
  public start_date?: any = undefined
  @JsonProperty('end_date', Any, true)
  public end_date?: any = undefined
  @JsonProperty('new_sessions', Number, true)
  public new_sessions?: number = undefined
  @JsonProperty('users', Number, true)
  public users?: number = undefined
  @JsonProperty('interactions', Number, true)
  public interactions?: number = undefined
  @JsonProperty('messages', Number, true)
  public messages?: number = undefined
  @JsonProperty('active_users', Number, true)
  public active_users?: number = undefined
  @JsonProperty('active_sessions', Number, true)
  public active_sessions?: number = undefined
  @JsonProperty('handovers', Number, true)
  public handovers?: number = undefined
  @JsonProperty('pushes', Number, true)
  public pushes?: number = undefined
  @JsonProperty('new_users', Number, true)
  public new_users?: number = undefined

  constructor() {}
}

@JsonObject
export class HubtypeBotStats {
  @JsonProperty('start_date', Any, true)
  public start_date: any = undefined
  @JsonProperty('end_date', Any, true)
  public end_date: any = undefined
  @JsonProperty('users', Number, true)
  public users: number = undefined
  @JsonProperty('sessions', Number, true)
  public sessions?: number = undefined
  @JsonProperty('pushes', Number, true)
  public pushes?: number = undefined
  @JsonProperty('handovers', Number, true)
  public handovers?: number = undefined
  @JsonProperty('interactions', Number, true)
  public interactions?: number = undefined
  @JsonProperty('messages', Number, true)
  public messages?: number = undefined
  @JsonProperty('active_users', Number, true)
  public active_users?: number = undefined
  @JsonProperty('new_users', Number, true)
  public new_users?: number = undefined
  @JsonProperty('new_sessions', Number, true)
  public new_sessions?: number = undefined
  @JsonProperty('active_sessions', Number, true)
  public active_sessions?: number = undefined
  @JsonProperty('periods', [HubtypeBotStatsPeriod], true)
  public periods?: HubtypeBotStatsPeriod[] = undefined

  constructor() {}

  public get totals() {
    return [
      {
        name: 'Users',
        value: +this.users,
      },
      {
        name: 'Sessions',
        value: +this.sessions,
      },
      {
        name: 'Handovers',
        value: +this.handovers,
      },
      {
        name: 'Interactions',
        value: +this.interactions,
      },
      {
        name: 'Messages',
        value: +this.messages,
      },
    ]
  }

  public get results() {
    const results = []
    for (const period of this.periods) {
      results.push({
        name: moment(period.start_date).format('DD-MM-YYYY'),
        series: [
          { name: 'Users', value: +period.users },
          { name: 'Interactions', value: +period.interactions },
          { name: 'Messages', value: +period.messages },
        ],
      })
    }
    return results
  }
}
