import { MatLegacySnackBarConfig as MatSnackBarConfig } from '@angular/material/legacy-snack-bar'

type FeedbackType = 'error' | 'info' | 'success' | 'unset'

export class FeedbackButtonConfig {
  onButtonActionClick: any
  buttonText: string
}

export class Feedback {
  private options: MatSnackBarConfig = {
    duration: 6000,
    horizontalPosition: 'right',
    verticalPosition: 'top',
  }

  public constructor(
    public readonly message: string,
    public readonly type: FeedbackType,
    public readonly code?: string,
    public readonly error?: Error,
    public readonly buttonConfig?: FeedbackButtonConfig,
    public readonly duration?: number
  ) {
    if (duration) {
      this.options.duration = duration
    }
  }

  static create(
    message: string,
    type: FeedbackType = 'info',
    buttonConfig?: FeedbackButtonConfig
  ): Feedback {
    return new Feedback(message, type, null, null, buttonConfig)
  }

  static error(
    code: string,
    message: string,
    error?: Error,
    buttonConfig?: FeedbackButtonConfig
  ): Feedback {
    if (!code) {
      console.warn(
        'Anonymous error was reported. Please provide it with Error Code'
      )
    }
    return new Feedback(message, 'error', code, error, buttonConfig)
  }

  get config(): MatSnackBarConfig {
    return this.options
  }

  isError(): boolean {
    return this.type === 'error'
  }

  isInfo(): boolean {
    return this.type === 'info'
  }

  isSuccess(): boolean {
    return this.type === 'success'
  }

  hasType(): boolean {
    return this.type !== 'unset'
  }
}
