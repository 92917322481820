import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core'
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select'
import { find, groupBy, isEqual } from 'lodash'

@Component({
  selector: 'app-selector',
  templateUrl: './selector.component.html',
  styleUrls: ['./selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectorComponent implements OnInit, OnChanges {
  @Input()
  multiple = false
  @Input()
  defaultValues: any[] = []
  @Input()
  emptyLabel = 'All'
  @Input()
  hint: string
  @Input()
  label = 'Select'
  @Input()
  values: any[] = []
  @Input()
  valueLabel = 'name'
  @Input()
  sortBy: string = undefined
  @Input()
  groupBy = undefined

  @Output()
  selectValue = new EventEmitter<any>()

  groupOptions: GroupSelector
  options: SelectorOption[] = []
  selected: any | any[]

  constructor() {
    this.isSameOption = this.isSameOption.bind(this)
  }

  ngOnInit() {
    this.formatContent()
    this.selectDefaultValues()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      (changes['values'] && changes['values'].currentValue) ||
      (changes['groupBy'] && changes['groupBy'].currentValue)
    ) {
      this.formatContent()
    }
  }

  isSameOption(curValue: any, oldValue: any): boolean {
    return (
      (curValue?.id && curValue?.id === oldValue?.id) ||
      isEqual(curValue, oldValue)
    )
  }

  onChangeOption(option: MatSelectChange) {
    this.selectValue.emit(option.value)
  }

  private formatOptions(options: any[]): SelectorOption[] {
    let dir = 1
    let sort = this.sortBy || this.valueLabel
    if (sort.startsWith('-')) {
      dir = -1
      sort = sort.substr(1)
    }

    return options
      .sort((a, b) => {
        if (typeof a === 'object') {
          return a[sort] > b[sort] ? dir : -1 * dir
        }
        return a > b ? dir : -1 * dir
      })
      .map(value => ({
        label: this.getLabel(value),
        value,
      }))
  }

  private formatContent() {
    if (!this.values) {
      return
    }
    if (this.groupBy) {
      this.groupOptions = groupBy(this.values, this.groupBy)
      for (const group of Object.keys(this.groupOptions)) {
        this.groupOptions[group] = this.formatOptions(this.groupOptions[group])
      }
      return
    }
    this.options = this.formatOptions(this.values)
  }

  private getLabel(value: any): string {
    return typeof value === 'object' ? value[this.valueLabel] : value
  }

  private selectDefaultValues() {
    if (this.defaultValues.length) {
      const needle = this.defaultValues
      const values = this.groupBy
        ? [...Object.values(this.groupOptions)].flat()
        : this.options

      this.selected = values
        .filter((item: SelectorOption) => {
          if (typeof item.value === 'object') {
            return find(needle, item.value)
          }
          return needle.includes(item.value)
        })
        .map((item: SelectorOption) => item.value)

      if (!this.multiple && this.selected.length) {
        this.selected = this.selected[0]
      }
    }
  }
}

interface SelectorOption {
  label: string
  value: any
}

interface GroupSelector {
  [key: string]: SelectorOption[]
}
