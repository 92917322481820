export const appOutOfFocus = (): boolean =>
  document.hidden || !document.hasFocus()

// Inspiration reference:
//https://stackoverflow.com/questions/24501358/how-to-set-a-header-for-a-http-get-request-and-trigger-file-download/24523253#24523253
export function downloadBlob(
  content: string | Blob,
  contentType: string,
  fileName: string
) {
  const windowUrl = window.URL
  const blob = new Blob([content], { type: contentType })
  const url = windowUrl.createObjectURL(blob)
  const anchor = document.createElement('a')
  anchor.setAttribute('href', url)
  anchor.setAttribute('download', fileName)
  document.body.appendChild(anchor)
  anchor.click()
  anchor.remove()
  windowUrl.revokeObjectURL(url)
}

export function getCookie(name: string) {
  const v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)')
  return v ? v[2] : null
}

export async function generateFileContentHash(file: File): Promise<string> {
  const arrayBuffer = await file.arrayBuffer()
  const hashBuffer = await crypto.subtle.digest('SHA-256', arrayBuffer)
  const hashArray = Array.from(new Uint8Array(hashBuffer))
  return hashArrayToHex(hashArray)
}

function hashArrayToHex(hashArray: number[]): string {
  return hashArray.map(i => i.toString(16).padStart(2, '0')).join('')
}

export function downloadFile(url: string, filename: string) {
  const anchor = document.createElement('a')
  document.body.appendChild(anchor)
  anchor.setAttribute('href', url)
  anchor.setAttribute('download', filename)
  anchor.click()
  anchor.remove()
}
