import { createSelector } from '@ngrx/store'
import {
  CASE_TYPE_NEW,
  CASE_CONNECTION_STATUS_OFFLINE,
  CASE_TYPE_STARTED,
  HubtypeCaseFilter,
  CASE_CONNECTION_STATUS_ONLINE,
} from 'models/hubtype-case-filter'
import { HubtypeCaseSort } from 'models/hubtype-case-sort'
import { HubtypeRealtimeCase } from 'models/hubtype-slas'
import { HubtypeCase } from '../models/hubtype-case'
import { HubtypeMessage } from '../models/hubtype-message'
import { getCasesFilter, getCasesSort, State } from '../reducers'
import { ArchiveCase } from '../services/hubtype-api/case-search.service.dtos'

export enum CaseList {
  ALL = 'all',
  ATTENDING = 'attending',
  IDLE = 'idle',
  WAITING = 'waiting',
}

type CaseListCounts = 'attending_count' | 'waiting_count' | 'idle_count'
export interface NavbarProjectQueueFilter {
  projectId: string
  projectName: string
  totalQueues: number
  queues: {
    queueId: string
    queueName: string
  }[]
}
export interface DeskFilters {
  dateRange: DateRange
}
interface DateRange {
  from: number | null
  to: number | null
}

export const DEFAULT_FILTERS: DeskFilters = {
  dateRange: {
    from: null,
    to: null,
  },
}

export interface DeskState {
  inbox: {
    attending: HubtypeCase[]
    idle: HubtypeCase[]
    waiting: HubtypeCase[]
    selectedCase: HubtypeCase | null
    selectedCaseMessages: HubtypeMessage[]
    casesFilter: HubtypeCaseFilter | null
    casesSort: HubtypeCaseSort | null
    waitingCasesLoading: number
    closeCaseChatWindow: boolean
    navbarCollapse: boolean
    navbarFilters: NavbarProjectQueueFilter[]
  }
  dashboard: {
    cases: HubtypeRealtimeCase[]
  }
  archive: {
    filters: DeskFilters
    cases: ArchiveCase[]
    selectedCase: ArchiveCase | null
    selectedCaseMessages: HubtypeMessage[]
    page: number
    prev: string | null
    next: string | null
  }
  reporting: {
    filters: DeskFilters
  }
}

// Selectors

const selectInbox = (state: State) => state.desk?.inbox

export const getWaitingList = (state: State) => state.desk.inbox.waiting
export const getAttendingList = (state: State) => state.desk.inbox.attending
export const getIdleList = (state: State) => state.desk.inbox.idle
export const getWaitingCasesLoading = (state: State) =>
  state.desk.inbox.waitingCasesLoading

export const getNavbarFilter = (state: State) => state.desk.inbox.navbarFilters
export const selectCasesByList = (list: CaseList) =>
  createSelector(selectInbox, inbox => inbox[list] || [])

export const selectCountByCaseList = (list: CaseListCounts) =>
  createSelector(selectInbox, inbox => inbox[list] || 0)

/** Cases list filtered */
export const getIdleListFiltered = createSelector(
  getIdleList,
  getCasesFilter,
  getCasesSort,
  (caselist: HubtypeCase[], filter: HubtypeCaseFilter, sort: HubtypeCaseSort) =>
    //Filter + sort + uniqueCaseId
    applyCasesFilter(caselist, filter)
      .sort(HubtypeCase.sortCriteria(sort))
      .filter(HubtypeCase.uniqueList)
)

export const getAttendingListFiltered = createSelector(
  getAttendingList,
  getCasesFilter,
  getCasesSort,
  (caselist: HubtypeCase[], filter: HubtypeCaseFilter, sort: HubtypeCaseSort) =>
    //Filter + sort + uniqueCaseId
    applyCasesFilter(caselist, filter)
      .sort(HubtypeCase.sortCriteria(sort))
      .filter(HubtypeCase.uniqueList)
)

export const getWaitingListFiltered = createSelector(
  getWaitingList,
  getCasesFilter,
  getCasesSort,
  getNavbarFilter,
  (
    caselist: HubtypeCase[],
    filter: HubtypeCaseFilter,
    sort: HubtypeCaseSort,
    navbarFilter: NavbarProjectQueueFilter[]
  ) =>
    //Filter + filterProject + filterQueue + sort + uniqueCaseId
    applyCasesFilter(caselist, filter)
      .filter(mycase => applyNavbarFilter(mycase, navbarFilter))
      .sort(HubtypeCase.sortCriteria(sort))
      .filter(HubtypeCase.uniqueList)
)

export function applyCasesFilter(
  cases: HubtypeCase[],
  filter: HubtypeCaseFilter
) {
  return (
    cases
      //Channels
      .filter(
        mycase =>
          filter.channels.length === 0 ||
          filter.channels.includes(mycase.provider)
      )
      //Search content
      .filter(
        mycase => filter.content === '' || mycase.contains(filter.content)
      )
      //Types new or started
      .filter(mycase => {
        const hasNewCaseFilter = filter.types.includes(CASE_TYPE_NEW)
        const haStartedCaseFilter = filter.types.includes(CASE_TYPE_STARTED)
        if (
          (!hasNewCaseFilter && !haStartedCaseFilter) ||
          (hasNewCaseFilter && haStartedCaseFilter)
        ) {
          return true
        }
        return mycase.isNew() ? hasNewCaseFilter : haStartedCaseFilter
      })
      //Status
      .filter(mycase => {
        const hasOfflineFilter = filter.status.includes(
          CASE_CONNECTION_STATUS_OFFLINE
        )
        const hasOnlineFilter = filter.status.includes(
          CASE_CONNECTION_STATUS_ONLINE
        )
        if (!hasOfflineFilter && !hasOnlineFilter) {
          return true
        }
        if (!mycase.isWebchat) {
          return false
        }
        return (
          (hasOfflineFilter && !mycase.chat.is_online) ||
          (hasOnlineFilter && mycase.chat.is_online)
        )
      })
  )
}

function applyNavbarFilter(
  mycase: HubtypeCase,
  navbarFilter: NavbarProjectQueueFilter[]
) {
  if (!navbarFilter || navbarFilter.length === 0) {
    return true
  }
  return navbarFilter
    .filter(navFilter => navFilter.projectId === mycase.project_id)
    .some(navFilter =>
      navFilter.queues.some(
        queueFilter => queueFilter.queueId === mycase.queue_id
      )
    )
}

function filterCasesToShowInbox(
  cases: HubtypeCase[],
  meId: string
): HubtypeCase[] {
  return cases.filter(c => !HubtypeCase.isAssigned(c) || c.isAssignedTo(meId))
}
