<div class="no-auth-page-container">
  <div class="title">
    <h1>Sign up for free</h1>
    <p>Connect with your clients no matter the channel</p>
  </div>

  <signup-form></signup-form>

  <p class="hint-text">
    You have an account?
    <button ht-button-borderless routerLink="/sign-in">Sign in</button>
  </p>
</div>
