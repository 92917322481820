<div class="ht-selector">
  <mat-form-field floatLabel="never">
    <mat-label>{{ label }}</mat-label>
    <mat-select
      [(ngModel)]="selected"
      (selectionChange)="onChangeOption($event)"
      [multiple]="multiple"
      [compareWith]="isSameOption"
    >
      <mat-option *ngIf="emptyLabel">{{ emptyLabel }}</mat-option>
      <mat-option
        [hidden]="!options"
        *ngFor="let item of options"
        [value]="item.value"
        >{{ item.label }}</mat-option
      >
      <mat-optgroup
        *ngFor="let group of groupOptions | keyvalue"
        [label]="group.key"
        [hidden]="!groupOptions"
      >
        <mat-option
          [hidden]="!groupOptions"
          *ngFor="let option of group.value"
          [value]="option.value"
        >
          {{ option.label }}
        </mat-option>
      </mat-optgroup>
    </mat-select>
    <mat-hint *ngIf="hint">{{ hint }}</mat-hint>
  </mat-form-field>
</div>
