/* eslint-disable @typescript-eslint/naming-convention */
import { JsonObject, JsonProperty } from 'json2typescript'

@JsonObject
export class OAuth {
  @JsonProperty('access_token', String, true)
  public access_token: string = undefined
  @JsonProperty('refresh_token', String, true)
  public refresh_token: string = undefined
  @JsonProperty('token_type', String, true)
  public token_type: string = undefined
  @JsonProperty('expires_in', Number, true)
  public expires_in: number = undefined
  @JsonProperty('scope', String, true)
  public scope?: string = undefined

  constructor() {}
}

@JsonObject
export class FlowBuilderOAuth {
  @JsonProperty('access_token', String, true)
  public accessToken: string = undefined
  constructor() {}
}
