<button
  role="button"
  [type]="isSubmit ? 'submit' : 'button'"
  [ngClass]="{
    'btn-primary': type === 'primary',
    'btn-secondary': type === 'secondary',
    'btn-ghost': type === 'ghost',
    'btn-ghost-secondary': type === 'ghost-secondary',
    'btn-primary-red': type === 'primary-red',

    'size-tiny': size === 'tiny',
    'size-small': size === 'small',
    'size-large': size === 'large',

    hidden: hidden === true,
    'full-width': fullWidth === true
  }"
  [disabled]="disabled"
  [attr.role]="customRole"
  (click)="onclick.emit($event)"
  (keydown.enter)="onclick.emit($event)"
  (keydown.space)="onclick.emit($event)"
>
  <i *ngIf="icon && iconPosition === 'left'" [ngClass]="icon"></i>
  <ng-content></ng-content>
  <i
    *ngIf="iconRight || (icon && iconPosition === 'right')"
    [ngClass]="iconRight || icon"
  ></i>
</button>
