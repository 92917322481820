/* eslint-disable @typescript-eslint/naming-convention */
import { Any, JsonObject, JsonProperty } from 'json2typescript'

@JsonObject
export class HubtypeTranslationSettings {
  @JsonProperty('enabled', Boolean, true)
  enabled? = false

  @JsonProperty('primary_language', String, true)
  primary_language: string = undefined

  @JsonProperty('always_translate_languages', [String], true)
  always_translate_languages: string[] = undefined

  @JsonProperty('never_translate_languages', [String], true)
  never_translate_languages: string[] = undefined
}

@JsonObject
export class HubtypeMessageTranslation {
  @JsonProperty('written_language', String, true)
  written_language: string = undefined

  @JsonProperty('swapped_language', String, true)
  swapped_language: string = undefined

  @JsonProperty('messages', Any, true)
  messages: { [key: string]: string } = undefined
}
