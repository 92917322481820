import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http'
import { Inject, Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { iif, Observable, throwError } from 'rxjs'
import { catchError, first, switchMap } from 'rxjs/operators'
import { environment } from '../../../environments/environment'
import { OAuth } from '../../models/oauth'
import { getAccessTokenState, State } from '../../reducers'
import { AuthService } from './auth.service'

@Injectable()
export class HubtypeAuthInterceptor implements HttpInterceptor {
  public static readonly AUTH_TOKEN_URI = `${environment.baseURL}/o/token`

  constructor(
    private store: Store<State>,
    @Inject('authService') private authService: AuthService
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const isRequestAHubtypeAuthenticableUri = this.isHubtypeAuthUri(request.url)
    return iif(
      () => isRequestAHubtypeAuthenticableUri,
      this.store.select(getAccessTokenState).pipe(
        first(),
        switchMap(oAuth => next.handle(this.assertHubtypeAuth(request, oAuth)))
      ),
      next.handle(request)
    ).pipe(
      catchError(err => {
        if (
          isRequestAHubtypeAuthenticableUri &&
          this.isUnauthenticatedExpiredTokenError(err)
        ) {
          return this.authService
            .refreshSession()
            .pipe(
              switchMap(oAuth =>
                next.handle(this.assertHubtypeAuth(request, oAuth))
              )
            )
        } else {
          if (
            isRequestAHubtypeAuthenticableUri &&
            this.isUnauthenticatedError(err)
          ) {
            this.authService.logout(null, 'Auth error')
          }
          return throwError(err)
        }
      })
    )
  }

  private assertHubtypeAuth(
    request: HttpRequest<unknown>,
    oAuth: OAuth
  ): HttpRequest<unknown> {
    if (!oAuth) {
      return request
    }
    let newHeaders = request.headers
    newHeaders = newHeaders.set(
      'Authorization',
      `${oAuth.token_type} ${oAuth.access_token}`
    )
    return request.clone({ headers: newHeaders })
  }

  private isHubtypeAuthUri(uri: string): boolean {
    return (
      uri?.startsWith(environment.baseURL) &&
      !uri.startsWith(HubtypeAuthInterceptor.AUTH_TOKEN_URI)
    )
  }

  private isUnauthenticatedError(err: HttpErrorResponse): boolean {
    return err.status === 401
  }

  private isUnauthenticatedExpiredTokenError(err: HttpErrorResponse): boolean {
    /*
     * We consider any 401 error but the cookie related errors as expired token.
     * This can be improved in the future.
     */
    if (err.status !== 401) {
      return false
    } else {
      if (['missing_cookie', 'invalid_cookie'].includes(err.error?.code)) {
        return false
      }
    }
    return true
  }
}
