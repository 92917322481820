import { Action } from '@ngrx/store'
import { HubtypeUser } from '../models/hubtype-user'
import { OAuth } from '../models/oauth'

export const LOGIN = '[Login] Login'
export const LOGOUT = '[Login] Logout'
export const SET_ME = '[Login] Set Me'
export const ERROR = '[Sys] Error'
export const SET_PUSHER_CONNECTION_STATUS = '[Sys] Set pusher connection status'
export const UPDATE_ME_STATUS = '[Update Me] Change status'

/**
 * Every action is comprised of at least a type and an optional
 * payload. Expressing actions as classes enables powerful
 * type checking in reducer functions.
 *
 * See Discriminated Unions: https://www.typescriptlang.org/docs/handbook/advanced-types.html#discriminated-unions
 */
export class LoginAction implements Action {
  readonly type = LOGIN

  constructor(public payload: OAuth) {}
}

export class LogoutAction implements Action {
  readonly type = LOGOUT

  constructor() {}
}

export class SetMeAction implements Action {
  readonly type = SET_ME

  constructor(public payload: HubtypeUser) {}
}

export class UpdateMeStatus implements Action {
  readonly type = UPDATE_ME_STATUS

  constructor(public payload: string) {}
}

export class ServerError implements Action {
  readonly type = ERROR

  constructor(public payload: any) {}
}

export class SetPusherConnectionStatusAction implements Action {
  readonly type = SET_PUSHER_CONNECTION_STATUS

  constructor(public payload: boolean) {}
}

/**
 * Export a type alias of all actions in this action group
 * so that reducers can easily compose action types
 */
export type Actions =
  | LoginAction
  | LogoutAction
  | SetMeAction
  | ServerError
  | SetPusherConnectionStatusAction
  | UpdateMeStatus
