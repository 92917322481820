/* eslint-disable @typescript-eslint/naming-convention */
import { Any, JsonObject, JsonProperty } from 'json2typescript'
import moment from 'moment'
import { getTimeAgo } from '../utils/time-utils'
import { HubtypeCaseSort, SortDirection, SortType } from './hubtype-case-sort'
import { HubtypeChat } from './hubtype-chat'
import { HubtypeMessage } from './hubtype-message'
import { HubtypeProviderAccount } from './hubtype-provider-account'
import { QUEUE_PRIORITIES } from './hubtype-queue'
import { HubtypeUserMinimal } from './hubtype-user'

const DISCARDED_CASE_NAME = 'Discarded'
export const CASE_STATUS = {
  RESOLUTION_DISCARD: 'result_not_solved',
  STATUS_ATTENDING: 'status_attending',
  STATUS_IDLE: 'status_idle',
  STATUS_RESOLVED: 'status_resolved',
  STATUS_WAITING: 'status_waiting',
  TYPIFICATION_DISCARD: 'discarded',
}

@JsonObject
export class ContactReasonWithCategory {
  @JsonProperty('id', String, true)
  public id?: string = undefined
  @JsonProperty('name', String, true)
  public name?: string = undefined
  @JsonProperty('category_name', String, true)
  public category_name?: string = undefined
  @JsonProperty('created_by_system', Boolean, true)
  public created_by_system? = false
}
@JsonObject
export class HubtypeCaseExtraData {
  @JsonProperty('language', String, true)
  public language?: string = undefined
  @JsonProperty('location', String, true)
  public location?: string = undefined
  @JsonProperty('url', String, true)
  public url?: string = undefined
}
@JsonObject
export class HubtypeCase {
  private static REPLY_LIMIT_HOURS = {
    [HubtypeProviderAccount.FACEBOOK]: 24 * 7,
    [HubtypeProviderAccount.INSTAGRAM]: 24 * 7,
    [HubtypeProviderAccount.WHATSAPP]: 24,
  }

  public static RESOLUTION_DISCARD = 'result_not_solved'
  public static STATUS_ATTENDING = 'status_attending'
  public static STATUS_IDLE = 'status_idle'
  public static STATUS_RESOLVED = 'status_resolved'
  public static STATUS_WAITING = 'status_waiting'
  public static TYPIFICATION_DISCARD = 'discarded'
  public static names = {
    [CASE_STATUS.STATUS_IDLE]: 'Idle',
    [CASE_STATUS.STATUS_ATTENDING]: 'Attending',
    [CASE_STATUS.STATUS_WAITING]: 'Waiting',
    [CASE_STATUS.STATUS_RESOLVED]: 'Resolved',
  }
  public static ALL_STATUS = [
    CASE_STATUS.STATUS_IDLE,
    CASE_STATUS.STATUS_ATTENDING,
    CASE_STATUS.STATUS_WAITING,
    CASE_STATUS.STATUS_RESOLVED,
  ]

  @JsonProperty('assigned_to', HubtypeUserMinimal, true)
  public assigned_to?: HubtypeUserMinimal = undefined
  @JsonProperty('average_agent_speed_of_answer', Number, true)
  public average_agent_speed_of_answer?: number = undefined
  @JsonProperty('average_enduser_speed_of_answer', Number, true)
  public average_enduser_speed_of_answer?: number = undefined
  @JsonProperty('chat', HubtypeChat, true)
  public chat?: HubtypeChat = undefined
  @JsonProperty('created_at', Any, true)
  public created_at?: any = undefined
  @JsonProperty('description', String, true)
  public description?: string = undefined
  @JsonProperty('enduserTyping', Boolean, true) // This field just exists in frontend
  public enduserTyping = false
  @JsonProperty('extra_data', HubtypeCaseExtraData, true)
  public extra_data?: HubtypeCaseExtraData = undefined
  @JsonProperty('has_agent_message', Boolean, true)
  public has_agent_message?: boolean = undefined
  @JsonProperty('id', String, true)
  public id?: string = undefined
  @JsonProperty('initial_agent_speed_of_answer', Number, true)
  public initial_agent_speed_of_answer?: number = undefined
  @JsonProperty('initial_service_level', String, true)
  public initial_service_level?: string = undefined
  @JsonProperty('last_message', [HubtypeMessage], true)
  public last_message?: HubtypeMessage[] = undefined
  @JsonProperty('last_unanswered_message', HubtypeMessage, true)
  public last_unanswered_message?: HubtypeMessage = undefined
  //This field is only used in frontend
  @JsonProperty('markedToNotTranslate', Boolean, true)
  public markedToNotTranslate = false
  @JsonProperty('marked_to_translate_by', String, true)
  public marked_to_translate_by?: string = undefined
  @JsonProperty('project_id', String, true)
  public project_id?: string = undefined
  @JsonProperty('project_name', String, true)
  public project_name?: string = undefined
  @JsonProperty('provider', String, true)
  public provider?: string = undefined
  @JsonProperty('provider_account_id', String, true)
  public provider_account_id?: string = undefined
  @JsonProperty('queue_id', String, true)
  public queue_id?: string = undefined
  @JsonProperty('queue_name', String, true)
  public queue_name?: string = undefined
  @JsonProperty('queue_prioritisation', String, true)
  public queue_prioritisation?: string = undefined
  @JsonProperty('resolution_status', String, true)
  public resolution_status?: string = undefined
  @JsonProperty('resolution_time', Number, true)
  public resolution_time?: number = undefined
  @JsonProperty('resolved_at', Any, true)
  public resolved_at?: any = undefined
  @JsonProperty('resolved_by', HubtypeUserMinimal, true)
  public resolved_by?: HubtypeUserMinimal = undefined
  @JsonProperty('status', String, true)
  public status?: string = undefined
  @JsonProperty('type', String, true)
  public type?: string = undefined
  @JsonProperty('unread_messages', Number, true)
  public unread_messages?: number = undefined

  @JsonProperty('contact_reasons', [ContactReasonWithCategory], true)
  public contact_reasons?: ContactReasonWithCategory[] = []

  constructor() {}

  public get chat_name(): string | undefined {
    if (this.chat) {
      const pid = ' (' + this.chat.provider_id + ')'
      if (this.chat.name) {
        return this.chat.name + pid
      }
      if (this.chat.username) {
        return this.chat.username + pid
      }
      return this.chat.provider_id
    }
  }

  public get created_at_moment() {
    return moment(this.created_at)
  }

  public get getClearStatus() {
    return this.status.split('_')[1]
  }

  public get isApple(): boolean {
    return this.provider === HubtypeProviderAccount.APPLE
  }

  public get isFacebook(): boolean {
    return this.provider === HubtypeProviderAccount.FACEBOOK
  }

  public get isGeneric(): boolean {
    return this.provider === HubtypeProviderAccount.GENERIC
  }

  public get isInstagram(): boolean {
    return this.provider === HubtypeProviderAccount.INSTAGRAM
  }

  public get isTelegram(): boolean {
    return this.provider === HubtypeProviderAccount.TELEGRAM
  }

  public get isTwitter(): boolean {
    return this.provider === HubtypeProviderAccount.TWITTER
  }

  public get isWebchat(): boolean {
    return this.provider === HubtypeProviderAccount.WEBCHAT
  }

  public get isWhatsapp(): boolean {
    return this.provider === HubtypeProviderAccount.WHATSAPP
  }

  public get is_attending(): boolean {
    return this.status === CASE_STATUS.STATUS_ATTENDING
  }

  public get is_idle(): boolean {
    return this.status === CASE_STATUS.STATUS_IDLE
  }

  public get is_solved() {
    return this.status === CASE_STATUS.STATUS_RESOLVED
  }

  public get is_waiting(): boolean {
    return this.status === CASE_STATUS.STATUS_WAITING
  }

  public get isDiscarded(): boolean {
    return (
      this.status === CASE_STATUS.STATUS_RESOLVED &&
      this.resolution_status === CASE_STATUS.RESOLUTION_DISCARD
    )
  }

  public get resolved_at_moment() {
    return moment(this.resolved_at)
  }

  public get user_id(): string | null {
    return this.chat ? this.chat.provider_id : ''
  }

  public get user_name(): string | undefined {
    if (this.chat) {
      return this.chat.name || this.chat.username || ''
    }
  }

  public get whoIsAssigned(): string | null {
    if (!this.assigned_to) {
      return ' -- '
    }
    if (this.assigned_to.first_name) {
      return `${this.assigned_to.first_name} ${this.assigned_to.last_name}`
    }
    if (this.assigned_to.username) {
      return this.assigned_to.username
    }
    if (this.assigned_to.email) {
      return this.assigned_to.email
    }
    return ' -- '
  }

  static isAssigned(caze: HubtypeCase): boolean {
    return Boolean(caze?.assigned_to)
  }

  public static sort(a: HubtypeCase, b: HubtypeCase) {
    const unAnsweredA = a.last_unanswered_message?.created_at
    const unAnsweredB = b.last_unanswered_message?.created_at
    if (unAnsweredA && !unAnsweredB) {
      return -1
    } else if (!unAnsweredA && unAnsweredB) {
      return 1
    } else if (unAnsweredA && unAnsweredB) {
      return unAnsweredA < unAnsweredB ? -1 : 1
    }
    return a.created_at < b.created_at ? -1 : 1
  }

  public static sortByPrioritization(caseA: HubtypeCase, caseB: HubtypeCase) {
    const priorityA = QUEUE_PRIORITIES.find(
      priority => priority.value === caseA.queue_prioritisation
    )
    const priorityB = QUEUE_PRIORITIES.find(
      priority => priority.value === caseB.queue_prioritisation
    )
    if (!priorityA) {
      return 1
    }
    if (!priorityB) {
      return -1
    }
    if (priorityA?.numericValue > priorityB?.numericValue) {
      return -1
    } else if (priorityA.numericValue < priorityB.numericValue) {
      return 1
    }
    return 0
  }

  public static sortByWaitingTime(a: HubtypeCase, b: HubtypeCase) {
    const unAnsweredA = a.last_unanswered_message?.created_at
    const unAnsweredB = b.last_unanswered_message?.created_at
    if (unAnsweredA && !unAnsweredB) {
      return -1
    } else if (!unAnsweredA && unAnsweredB) {
      return 1
    } else if (unAnsweredA && unAnsweredB) {
      return unAnsweredA < unAnsweredB ? -1 : 1
    }
    return a.created_at < b.created_at ? -1 : 1
  }

  public static sortByCreationTime(a: HubtypeCase, b: HubtypeCase) {
    const createdA = new Date(a.created_at)
    const createdB = new Date(b.created_at)
    if (createdA && !createdB) {
      return -1
    } else if (!createdA && createdB) {
      return 1
    } else if (createdA && createdB) {
      return createdA < createdB ? -1 : 1
    }
    return a.created_at < b.created_at ? -1 : 1
  }

  public static sortCriteria(order: HubtypeCaseSort) {
    return (a, b) => {
      const firstParam = order.sortDirection === SortDirection.DESC ? a : b
      const secondParam = order.sortDirection === SortDirection.DESC ? b : a

      if (order.sortBy === SortType.CREATED_AT) {
        return (
          this.sortByPrioritization(a, b) ||
          this.sortByCreationTime(firstParam, secondParam)
        )
      } else if (order.sortBy === SortType.WAITING_TIME) {
        return (
          this.sortByPrioritization(a, b) ||
          this.sortByWaitingTime(firstParam, secondParam)
        )
      }

      return 0
    }
  }

  public static statusName(status: string): string {
    return HubtypeCase.names[status]
  }

  public getVisibleStatus(): string {
    if (this.isDiscarded) {
      return DISCARDED_CASE_NAME
    }
    return HubtypeCase.statusName(this.status) || ''
  }

  public static uniqueList = (
    value: HubtypeCase,
    index: number,
    array: HubtypeCase[]
  ) => index === array.findIndex(t => t.id === value.id)

  public canReachOutWithTemplate(): boolean {
    // TODO: What about Instagram?
    return (
      this.provider !== HubtypeProviderAccount.FACEBOOK &&
      this.provider !== HubtypeProviderAccount.APPLE
    )
  }

  public canReplyFreeText(): boolean {
    if (!this.chat.last_message_received || this.chat.is_blocked) {
      return false
    }
    const limit = HubtypeCase.REPLY_LIMIT_HOURS[this.provider]
    if (!limit) {
      return true
    }
    return getTimeAgo(this.chat.last_message_received) < limit
  }

  contains(text: string): boolean {
    if (!text) {
      return text === ''
    }
    const needle = text.toLowerCase()
    const chatName = this.chat_name.toLowerCase()
    return chatName.includes(needle) || this.id.includes(needle)
  }

  public getFreeReplyTimeLimit(): string {
    const limit = HubtypeCase.REPLY_LIMIT_HOURS[this.provider]
    if (!limit) {
      return ''
    } else if (limit > 24) {
      return `${limit / 24} days`
    }
    return `${limit}h`
  }

  public isAssignedTo(userId: string): boolean {
    return this.assigned_to && this.assigned_to.id === userId
  }

  public isInteractable(): boolean {
    return this.is_attending || this.is_idle
  }

  isNew(): boolean {
    return !this.has_agent_message
  }

  public setUpdatedFields(data: ICaseUpdated) {
    const fields = Object.keys(data)
    fields.forEach(field => {
      this[field] = data[field]
    })
  }

  public updateCaseFromLastMessage(
    message: HubtypeMessage,
    isMessageFromSelectedCase: boolean
  ) {
    if (message.is_enduser) {
      this.last_message = [message]
      this.chat.last_message_received = message.created_at
      this.enduserTyping = false
      this.chat.is_blocked = false
      if (!this.last_unanswered_message) {
        this.last_unanswered_message = message
      }

      if (!isMessageFromSelectedCase) {
        this.unread_messages += 1
      }
    } else if (message.is_agent) {
      this.last_message = [message]
      this.has_agent_message = true
      this.last_unanswered_message = undefined
    } else if (message.isChatOnline) {
      this.chat.is_online = true
      this.chat.last_online_status_changed_at = message.created_at
    } else if (message.isChatOffline) {
      this.chat.is_online = false
      this.chat.last_online_status_changed_at = message.created_at
    }
  }
}

export enum DownLoadableCaseExtension {
  TXT = 'txt',
  CSV = 'csv',
  PDF = 'pdf',
}

export interface ICaseAssigned {
  assigned_to_id: string
  id: string
  queue_id: string
}

export interface ICaseUpdated {
  assigned_to: string
  id: string
  queue_id: string
  resolution_status: string
  resolved_at: string
  resolved_by: string
  status: string
}

@JsonObject
export class CaseSyncData {
  @JsonProperty('id', String, true)
  id: string = undefined
  @JsonProperty('last_message_id', String, true)
  lastMessageId: string = null
}

@JsonObject
export class CaseSyncDataList {
  @JsonProperty('attending', [CaseSyncData], true)
  attending: CaseSyncData[] = []
  @JsonProperty('waiting', [CaseSyncData], true)
  waiting: CaseSyncData[] = []
  @JsonProperty('idle', [CaseSyncData], true)
  idle: CaseSyncData[] = []
}
