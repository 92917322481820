import { HttpParams } from '@angular/common/http'
import { Inject, Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { Observable, of } from 'rxjs'
import { filter, first, map, switchMap } from 'rxjs/operators'
import { filterTemplates, TemplateFilterType } from '../../constants/templates'
import {
  HubtypeProviderAccount,
  WebchatSettings,
  WhatsappPhoneNumber,
  WhatsappPhoneNumberStatus,
  WhatsAppSettings,
} from '../../models/hubtype-provider-account'
import {
  FacebookUserInfo,
  HubtypeUserFacebookPage,
} from '../../models/hubtype-user'
import { HubtypeWhatsappTemplate } from '../../models/hubtype-whatsapp-template'
import {
  getAllDistincProviderAccounts,
  getAllProviderAccounts,
  getWhatsappProviderAccounts,
  State,
} from '../../reducers'
import { ConverterService } from '../converter.service'
import { RestService } from '../rest.service'
import { HubtypeApiService } from './hubtype-api.service'

@Injectable()
export class ProviderAccountService {
  private readonly FACEBOOK_API_VERSION = '20.0'
  constructor(
    @Inject('apiService') private apiService: HubtypeApiService,
    @Inject('convertService') private convertService: ConverterService,
    private restService: RestService,
    private store: Store<State>
  ) {}

  // GET: /provider_accounts/
  getAll(): Observable<HubtypeProviderAccount> {
    return this.apiService.get('/provider_accounts/').pipe(
      // TODO PAGINATION
      map<any, HubtypeProviderAccount>(response =>
        this.convertService.jsonConvert.deserializeObject(
          response.results,
          HubtypeProviderAccount
        )
      )
    )
  }

  get(providerId: string): Observable<HubtypeProviderAccount> {
    // TODO: These two methods used here should be private and use this one instead
    return this.getStoredByProviderId(providerId).pipe(
      first(),
      switchMap(pa => {
        if (pa) {
          return of(pa)
        }
        return this.getProvider(providerId)
      })
    )
  }

  // TODO: It should be able to fetch too
  getByProvider(provider: string): Observable<HubtypeProviderAccount> {
    // IMPORTANT: This returns {0, 1}, it's not reliable when n > 1
    return this.store
      .select(getAllProviderAccounts)
      .pipe(map(pas => pas.find(pa => pa.provider === provider)))
  }

  getStoredByProviderId(
    providerId: string
  ): Observable<HubtypeProviderAccount> {
    return this.store
      .select(getAllProviderAccounts)
      .pipe(map(accounts => accounts.find(acc => acc.id === providerId)))
  }

  // POST: /users/set_fb_credentials/
  setFbCredentials(provider, credentials): Observable<FacebookUserInfo> {
    const data = {
      provider,
      credentials,
    }
    return this.apiService
      .post('/users/set_fb_credentials/', data, null, 'v2')
      .pipe(
        map(response =>
          this.convertService.jsonConvert.deserializeObject(
            response,
            FacebookUserInfo
          )
        )
      )
    // map(response => plainToClass<HubtypeUser, Object>(HubtypeUser, response));
  }

  logoutFacebook(): Observable<void> {
    return this.apiService.post('/users/fb_logout/', null, null, 'v2')
  }

  // GET: /users/get_fb_pages/
  getFbPages(provider: string): Observable<HubtypeUserFacebookPage[]> {
    return this.apiService
      .get(
        '/users/get_fb_pages/',
        { provider: encodeURIComponent(provider) },
        'v2'
      )
      .pipe(
        map(response =>
          this.convertService.jsonConvert.deserializeArray(
            response,
            HubtypeUserFacebookPage
          )
        )
      )
  }

  // POST: /provider_accounts/get_whatsapp_phone_numbers/
  getWhatsappPhoneNumbers(
    provider: string,
    authToken: string
  ): Observable<WhatsappPhoneNumber[]> {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const body = { provider, auth_token: authToken }
    return this.apiService
      .post('/provider_accounts/get_whatsapp_phone_numbers/', body)
      .pipe(
        map(response =>
          this.convertService.jsonConvert.deserializeArray(
            response,
            WhatsappPhoneNumber
          )
        )
      )
  }

  // GET: /provider_accounts/<id>/whatsapp_cloud_settings/
  getWhatsappPhoneNumberStatus(
    providerId: string
  ): Observable<WhatsappPhoneNumberStatus> {
    return this.apiService
      .get(`/provider_accounts/${providerId}/whatsapp_cloud_settings/`)
      .pipe(
        map(response =>
          this.convertService.jsonConvert.deserializeObject(
            response,
            WhatsappPhoneNumberStatus
          )
        )
      )
  }

  // POST: /provider_accounts/connect_fb_page/
  connectFbPage(
    pageId,
    autoassign,
    queueId,
    botId
  ): Observable<HubtypeProviderAccount> {
    const data = {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      page_id: pageId,
    }
    if (queueId) {
      data['queue_id'] = queueId
    }
    if (botId) {
      data['bot_id'] = botId
    }
    if (autoassign) {
      data['autoassign'] = true
    }
    return this.apiService
      .post('/provider_accounts/connect_fb_page/', data)
      .pipe(
        map(response =>
          this.convertService.jsonConvert.deserializeObject(
            response['provider_account'],
            HubtypeProviderAccount
          )
        )
      )
    // map(response => plainToClass<HubtypeProviderAccount,Object>(HubtypeProviderAccount,response['provider_account']));
  }

  // POST: /provider_accounts/instagram/
  connectInstagramAccount(
    pageId: string,
    botId: string
  ): Observable<HubtypeProviderAccount> {
    const data = {
      /* eslint-disable @typescript-eslint/naming-convention */
      page_id: pageId,
      bot_id: botId,
      /* eslint-disable @typescript-eslint/naming-convention */
    }
    return this.apiService
      .post('/provider_accounts/instagram/', data)
      .pipe(
        map(response =>
          this.convertService.jsonConvert.deserializeObject(
            response['provider_account'],
            HubtypeProviderAccount
          )
        )
      )
  }

  connectWebchatAccount(
    name: string,
    botId: string,
    credentialsJson: any
  ): Observable<HubtypeProviderAccount> {
    const data = {
      name,
      botId,
      settings: {
        visible: credentialsJson.settings.visible,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        whitelisted_urls: credentialsJson.settings.whitelisted_urls || [],
        // eslint-disable-next-line @typescript-eslint/naming-convention
        scheduled_queue_id: credentialsJson.settings.scheduled_queue_id || null,
      },
    }
    return this.apiService
      .post('/provider_accounts/connect_webchat_channel/', data)
      .pipe(
        map(response => {
          const pa = this.convertService.jsonConvert.deserializeObject(
            response['provider_account'],
            HubtypeProviderAccount
          )
          pa['netlify_url'] = response['netlify_url']
          return pa
        })
      )
  }

  connectAppleAccount(
    name: string,
    botId: string,
    bizId: string
  ): Observable<HubtypeProviderAccount> {
    const data = {
      name,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      bot_id: botId,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      biz_id: bizId,
    }
    return this.apiService
      .post('/provider_accounts/connect_apple_channel/', data)
      .pipe(
        map(response => {
          const pa = this.convertService.jsonConvert.deserializeObject(
            response['provider_account'],
            HubtypeProviderAccount
          )
          return pa
        })
      )
  }

  // GET: /provider_accounts/{provider_id}/
  getProvider(providerId: string): Observable<HubtypeProviderAccount> {
    return this.apiService.get(`/provider_accounts/${providerId}/`).pipe(
      map(response => {
        const pa = this.convertService.jsonConvert.deserializeObject(
          response,
          HubtypeProviderAccount
        )
        return pa
      })
    )
  }

  getDistinctNameProviders() {
    return this.store
      .select(getAllDistincProviderAccounts)
      .pipe(filter(pro => Array.isArray(pro) && pro.length > 0 && pro !== null))
  }
  // POST: /provider_accounts/{provider_id}/webchat_settings/
  updateWebchatSettings(
    providerId: string,
    settings: WebchatSettings
  ): Observable<any> {
    return this.apiService
      .post(`/provider_accounts/${providerId}/webchat_settings/`, {
        settings,
      })
      .pipe(response => response)
  }

  // GET: /provider_accounts/{provider_id}/whatsapp_settings/
  getWhatsappSettings(providerId: string): Observable<any> {
    return this.apiService.get(
      `/provider_accounts/${providerId}/whatsapp_settings/`
    )
  }

  // POST: /provider_accounts/{provider_id}/whatsapp_settings/
  updateWhatsAppSettings(
    providerId: string,
    settings: WhatsAppSettings
  ): Observable<any> {
    return this.apiService.post(
      `/provider_accounts/${providerId}/whatsapp_settings/`,
      settings
    )
  }

  // POST: /provider_accounts/connect_tg_bot/
  connectTgBot(
    botName,
    botToken,
    autoassign,
    queueId,
    botId
  ): Observable<HubtypeProviderAccount> {
    const data = {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      bot_name: botName,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      bot_token: botToken,
    }
    if (queueId) {
      data['queue_id'] = queueId
    }
    if (botId) {
      data['bot_id'] = botId
    }
    if (autoassign) {
      data['autoassign'] = true
    }
    return this.apiService
      .post('/provider_accounts/connect_tg_bot/', data)
      .pipe(
        map(response =>
          this.convertService.jsonConvert.deserializeObject(
            response['provider_account'],
            HubtypeProviderAccount
          )
        )
      )
    // map(response => plainToClass<HubtypeProviderAccount,Object>(HubtypeProviderAccount,response['provider_account']));
  }

  // POST: /provider_accounts/connect_twitter_account/
  connectTwitterAccount(
    username,
    credentials,
    credentialsJson,
    autoassign,
    queueId,
    botId
  ): Observable<HubtypeProviderAccount> {
    const data = {
      username,
      credentials,
    }
    if (queueId) {
      data['queue_id'] = queueId
    }
    if (credentialsJson) {
      data['credentials_json'] = credentialsJson
    }
    if (botId) {
      data['bot_id'] = botId
    }
    if (autoassign) {
      data['autoassign'] = true
    }
    return this.apiService
      .post('/provider_accounts/connect_twitter_account/', data)
      .pipe(
        map(response =>
          this.convertService.jsonConvert.deserializeObject(
            response['provider_account'],
            HubtypeProviderAccount
          )
        )
      )
    // map(response => plainToClass<HubtypeProviderAccount,Object>(HubtypeProviderAccount,response['provider_account']));
  }

  // POST: /v1/provider_accounts/whatsapp_cloud/
  connectWhatsappCloud(
    wabaId: string,
    phoneNumberId: string,
    displayPhoneName: string,
    displayPhoneNumber: string,
    botId: string
  ): Observable<HubtypeProviderAccount> {
    const body = {
      waba_id: wabaId,
      phone_number_id: phoneNumberId,
      display_phone_name: displayPhoneName,
      display_phone_number: displayPhoneNumber,
      bot_id: botId,
    }
    return this.apiService
      .post('/provider_accounts/whatsapp_cloud/', body)
      .pipe(
        map(response =>
          this.convertService.jsonConvert.deserializeObject(
            response['provider_account'],
            HubtypeProviderAccount
          )
        )
      )
    // map(response => plainToClass<HubtypeProviderAccount,Object>(HubtypeProviderAccount,response['provider_account']));
  }

  // POST: /provider_accounts/<id>/whatsapp_cloud_reconnect/
  reconnectWhatsappCloud(
    providerId: string
  ): Observable<HubtypeProviderAccount> {
    return this.apiService
      .post(`/provider_accounts/${providerId}/whatsapp_cloud_reconnect/`, {})
      .pipe(
        map(response =>
          this.convertService.jsonConvert.deserializeObject(
            response,
            HubtypeProviderAccount
          )
        )
      )
  }

  // POST: /provider_accounts/connect_whatsapp_playground/
  connectWhatsappPlaygroundAccount(
    phone,
    queueId,
    botId
  ): Observable<HubtypeProviderAccount> {
    const data = {
      phone,
    }
    if (queueId) {
      data['queue_id'] = queueId
    }
    if (botId) {
      data['bot_id'] = botId
    }
    return this.apiService
      .post('/provider_accounts/connect_whatsapp_playground/', data)
      .pipe(
        map(response =>
          this.convertService.jsonConvert.deserializeObject(
            response['provider_account'],
            HubtypeProviderAccount
          )
        )
      )
    // map(response => plainToClass<HubtypeProviderAccount,Object>(HubtypeProviderAccount,response['provider_account']));
  }

  connectProvider(
    provider: HubtypeProviderAccount
  ): Observable<HubtypeProviderAccount> {
    // TODO: remove unused autoassign parameter from connectXX
    if (provider.provider === HubtypeProviderAccount.FACEBOOK) {
      return this.connectFbPage(
        provider.credentials,
        null,
        provider.queue_id,
        provider.bot_id
      )
    } else if (provider.provider === HubtypeProviderAccount.INSTAGRAM) {
      return this.connectInstagramAccount(provider.credentials, provider.bot_id)
    } else if (provider.provider === HubtypeProviderAccount.TELEGRAM) {
      return this.connectTgBot(
        provider.name,
        provider.credentials,
        null,
        provider.queue_id,
        provider.bot_id
      )
    } else if (provider.provider === HubtypeProviderAccount.TWITTER) {
      return this.connectTwitterAccount(
        provider.name,
        provider.credentials,
        provider.credentials_json,
        null,
        provider.queue_id,
        provider.bot_id
      )
    } else if (provider.provider === HubtypeProviderAccount.WEBCHAT) {
      return this.connectWebchatAccount(
        provider.name,
        provider.bot_id,
        provider.credentials_json
      )
    } else if (provider.provider === HubtypeProviderAccount.WHATSAPP_CLOUD) {
      return this.connectWhatsappCloud(
        provider.credentials_json.waba_id,
        provider.credentials_json.phone_number_id,
        provider.credentials_json.display_phone_name,
        provider.credentials_json.display_phone_number,
        provider.bot_id
      )
    } else if (
      provider.provider === HubtypeProviderAccount.WHATSAPP_PLAYGROUND
    ) {
      return this.connectWhatsappPlaygroundAccount(
        provider.credentials_json.allowed_user_phone,
        provider.queue_id,
        provider.bot_id
      )
    }
  }

  deleteProviderAccount(provider: HubtypeProviderAccount): Observable<any> {
    return this.apiService
      .delete(`/provider_accounts/${provider.id}/`)
      .pipe(map(response => true))
  }

  // POST:  https://graph.facebook.com/vX.X/me/messenger_profile?access_token=PAGE_ACCESS_TOKEN
  enableGetStarted(provider: HubtypeProviderAccount) {
    // Differentiate between diferent credential providers
    const PAGE_ACCESS_TOKEN = provider.credentials_json.page_access_token
    const data = {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      get_started: {
        payload: 'GET_STARTED_PAYLOAD',
      },
    }

    const url = `https://graph.facebook.com/v${this.FACEBOOK_API_VERSION}/me/messenger_profile`
    const options = {
      body: data,
      params: new HttpParams({
        fromObject: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          access_token: PAGE_ACCESS_TOKEN,
        },
      }),
    }
    return this.restService.request('POST', url, options)
  }

  // DELETE:  https://graph.facebook.com/vX.X/me/messenger_profile?access_token=PAGE_ACCESS_TOKEN
  disableGetStarted(provider) {
    // Differentiate between diferent credential providers
    // Facebook:
    const PAGE_ACCESS_TOKEN = provider.credentials_json.page_access_token
    const data = {
      fields: ['get_started'],
    }
    const options = {
      headers: {
        'Content-type': 'application/json;charset=utf-8',
      },
      body: data,
    }
    const url =
      `https://graph.facebook.com/v${this.FACEBOOK_API_VERSION}/me/messenger_profile?access_token=` +
      PAGE_ACCESS_TOKEN

    return this.restService.request('DELETE', url, options)
  }

  // GET:  https://graph.facebook.com/vX.X/me/thread_settings?access_token=PAGE_ACCESS_TOKEN
  obtainGetStarted(provider) {
    // Differentiate between diferent credential providers
    // Facebook:
    const PAGE_ACCESS_TOKEN = provider.credentials_json.page_access_token
    const url: string =
      `https://graph.facebook.com/v${this.FACEBOOK_API_VERSION}/me/messenger_profile?fields=get_started&access_token=` +
      PAGE_ACCESS_TOKEN

    return this.restService.request('GET', url, {})
  }

  getWhatsappTemplates(
    providerAccountId: string,
    filters = TemplateFilterType.ALL
  ): Observable<HubtypeWhatsappTemplate[]> {
    return this.apiService
      .get(`/provider_accounts/${providerAccountId}/templates/`)
      .pipe(
        map(response => {
          const filteredTemplates = filterTemplates(response.data, filters)
          return this.convertService.jsonConvert.deserializeArray(
            filteredTemplates,
            HubtypeWhatsappTemplate
          )
        })
      )
  }

  getStoredWhatsappProviders(
    includePlayground: boolean = false
  ): Observable<HubtypeProviderAccount[]> {
    return this.store.select(getWhatsappProviderAccounts(includePlayground))
  }

  /**
   * @deprecated should use getChannelIcon which uses font awesome
   */
  getProviderIcon(providerName: string) {
    const icon = HubtypeProviderAccount.PROVIDERS_WITH_ICON.includes(
      providerName
    )
      ? providerName
      : 'webchat'
    return `/assets/images/icons/channels/${icon}.svg`
  }
}
