import { JsonObject, JsonProperty } from 'json2typescript'

export interface ContactInfoFieldFilter {
  page: number
  page_size: number
}

@JsonObject
export class ContactInfoField {
  @JsonProperty('id', String, true)
  id = ''

  @JsonProperty('name', String, true)
  name = ''

  @JsonProperty('is_active', Boolean, true)
  isActive = false

  constructor() {}
}

@JsonObject
export class ChatContactInfoField {
  @JsonProperty('contact_info_field_id', String, true)
  id = ''

  @JsonProperty('name', String, true)
  name = ''

  @JsonProperty('value', String, true)
  value = ''

  @JsonProperty('is_active', Boolean, true)
  isActive = false

  constructor() {}
}
