import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'minutesSeconds',
})
export class MinutesSecondsPipe implements PipeTransform {
  transform(value: number): string {
    if (value === null) {
      return '--'
    }
    const hours = Math.floor(value / 3600)
    const timeLeft = Math.floor(value % 3600)
    const minutes = Math.floor(timeLeft / 60)
    const seconds = Math.floor(timeLeft % 60)
    const stringHours = hours ? `${hours}h ` : ''
    const stringMinutes =
      minutes || hours ? `${this.addZeroPrefix(minutes)}m ` : ''
    const stringSeconds = `${this.addZeroPrefix(seconds)}s`
    return `${stringHours}${stringMinutes}${stringSeconds}`
  }

  private addZeroPrefix(num: number): string {
    return num.toString().padStart(2, '0')
  }
}
