import { Observable, Subject, Subscription } from 'rxjs'

/**
 * Logs when a new subscription observes a Subject with many Observers
 */
export class SubscribeMonitor {
  private static LOG_SUBSCRIBES = false
  private static LOG_CALLSTACK = false
  private static OBSERVERS_LOG_THRESHOLD = 25
  private static OBSERVERS_ERROR_THRESHOLD = 200
  private static num_subscribes = 0
  private static origSubscribe

  public enable() {
    console.log('Monitoring subscriptions')
    SubscribeMonitor.origSubscribe = Observable.prototype.subscribe
    Observable.prototype.subscribe = SubscribeMonitor.subscribe
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  static subscribe(this: any, ...args: any[]) {
    if (SubscribeMonitor.LOG_SUBSCRIBES) {
      SubscribeMonitor.num_subscribes++
      console.log('subscribing', SubscribeMonitor.num_subscribes, this, ...args)
      if (SubscribeMonitor.LOG_CALLSTACK) {
        console.log(new Error().stack)
        console.log('----------------------------')
      }
    }

    const subscription: Subscription = SubscribeMonitor.origSubscribe.apply(
      this,
      args
    )

    if (subscription instanceof Subject) {
      SubscribeMonitor.checkNumObservers(subscription)
    } else if (subscription['_subscriptions']) {
      for (const subsub of subscription['_subscriptions']) {
        if (subsub instanceof Subject) {
          SubscribeMonitor.checkNumObservers(subsub)
        }
      }
    }
    return subscription
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  static checkNumObservers(subject: Subject<any>) {
    if (!subject.observers) {
      return
    }
    const numObservers = subject.observers.length
    if (numObservers > SubscribeMonitor.OBSERVERS_LOG_THRESHOLD) {
      console.log('numObservers:' + numObservers)
    }
    if (numObservers > SubscribeMonitor.OBSERVERS_ERROR_THRESHOLD) {
      console.error(
        `Subject has many (${numObservers}) observers. Did you forget a first() or takeUntil(...)? `
      )
      console.error(
        'You should only get big number when loading older messages from a long case. Callstack:'
      )
      console.log(new Error().stack)
    }
  }
}
