import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms'
import { CustomError } from 'src/app/constants/errors'
import { isWordInText } from 'utils/string-utils'
import { AppError } from '../constants/errors'
import { FeedbackService } from '../services/feedback.service'

export interface FormRadioOption {
  label: string
  value: string
}

export interface FormSelectOption {
  index: number
  label: string
  value: string
}

export interface ErrorMessages {
  [key: string]: string
}

export const REQUIRED_ERROR = 'required'
export const EMAIL_ERROR = 'email'
export const NUMBER_ERROR = 'number'
export const MAX_NUMBER_ERROR = 'max'
export const MIN_NUMBER_ERROR = 'min'
export const PATTERN_ERROR = 'pattern'
export const MIN_LENGTH = 'minlength'
export const MAX_LENGTH = 'maxlength'

const DEFAULT_ERROR_MESSAGES: ErrorMessages = {
  [REQUIRED_ERROR]: 'This field is required',
  [EMAIL_ERROR]: 'Invalid email format (ex: name@domain.com)',
  [NUMBER_ERROR]: 'This field must be a number',
  [MAX_NUMBER_ERROR]: 'The maximum value accepted is {1}',
  [MIN_NUMBER_ERROR]: 'The minimum value accepted is {1}',
  [PATTERN_ERROR]: `This value does not have a valid format.`,
  [MIN_LENGTH]: 'The length of the value is too short.',
  [MAX_LENGTH]: 'The length of the value is to loing.',
}

interface HandleServerErrorParams {
  error: any
  form: UntypedFormGroup
  feedbackService?: FeedbackService
  customUnhandledError?: AppError
  lookup?: { [key: string]: string }
}
export function handleServerError({
  error,
  form,
  feedbackService,
  customUnhandledError = CustomError.UNHANDLED_ERROR,
  lookup = {},
}: HandleServerErrorParams): boolean {
  if (form && error.status === 400) {
    let hasValidationError = false
    Object.entries(error.error).forEach(([serverErrorKey, errorMessage]) => {
      const formErrorKey = lookup[serverErrorKey] || serverErrorKey
      const control = form.get(formErrorKey)
      if (control) {
        hasValidationError = true
        control.setErrors({ serverError: errorMessage })
      }
    })
    if (!hasValidationError) {
      form.setErrors({
        serverError: error.error?.detail || customUnhandledError.message,
      })
    }
    return true
  }
  if (feedbackService) {
    feedbackService.error(
      customUnhandledError.code,
      customUnhandledError.message,
      error
    )
  }
}

export function validateAllFormFields(
  formGroup: UntypedFormGroup | UntypedFormArray
) {
  Object.keys(formGroup.controls).forEach(field => {
    const control = formGroup.get(field)
    if (control instanceof UntypedFormControl) {
      control.updateValueAndValidity()
      control.markAsTouched({ onlySelf: true })
    } else if (
      control instanceof UntypedFormGroup ||
      control instanceof UntypedFormArray
    ) {
      control.markAsTouched({ onlySelf: true })
      validateAllFormFields(control)
    }
  })
  return formGroup.valid
}

export function setServerErrors(
  errorResponse: any,
  formControls: {
    [key: string]: AbstractControl
  }
): AbstractControl[] {
  const controlsWithErrors = [] as AbstractControl[]
  if (errorResponse.error) {
    Object.entries(errorResponse.error).forEach(([key, value]) => {
      const control = formControls[key]
      if (control && key !== 'id') {
        control.setErrors({ serverError: value })
        controlsWithErrors.push(control)
      }
    })
  }
  return controlsWithErrors
}

export function getAllTouchedErrors(
  formGroup: UntypedFormGroup | UntypedFormArray
): ValidationErrors {
  let errors = {}
  Object.keys(formGroup.controls).forEach(field => {
    const control = formGroup.get(field)
    if (control instanceof UntypedFormControl) {
      if (control.errors && control.touched) {
        errors = { ...control.errors, ...errors }
      }
    } else if (
      control instanceof UntypedFormGroup ||
      control instanceof UntypedFormArray
    ) {
      if (control.errors && control.touched) {
        errors = { ...control.errors, ...errors }
      }
      errors = { ...getAllTouchedErrors(control), ...errors }
    }
  })
  return errors || null
}

export function getMessageErrors(
  errors: ValidationErrors = {},
  customMessageErrors: ErrorMessages = {}
): string {
  const error = Object.entries(errors)[0]
  if (error) {
    const [key, value] = error
    if (key) {
      if (typeof value === 'string') {
        return value
      }
      // when backend is sendind an array of errors
      if (Array.isArray(value)) {
        return value[0] || ''
      }
      const errorMessages = {
        ...DEFAULT_ERROR_MESSAGES,
        ...customMessageErrors,
        serverError: value,
      }
      // TO DO: implement messages with variables as an object (like in MIN_LENGTH error)
      return replaceMessageVariables(errorMessages[key], [value[key]])
    }
  }

  return null
}

function replaceMessageVariables(message: string, variables: string[]): string {
  if (!message) {
    return null
  }
  let result = message

  if (variables && Array.isArray(variables)) {
    for (let i = 1; i <= variables.length; i++) {
      result = result.replace(`{${i}}`, variables[i - 1])
    }
  }

  return result
}

export function flatFormValues(formValues: any): string {
  return Object.entries(formValues).reduce(
    (acc, entry) => `${acc}${entry[0]}":"${String(entry[1])}";`,
    ''
  )
}

export function customEmailValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    const valid = emailRegex.test(control.value)
    return valid
      ? null
      : {
          email: true,
        }
  }
}

export function compareControlValidator(
  matchingControlName: string,
  errorMessage = 'The values do not match'
) {
  return (control: AbstractControl) => {
    if (!control.parent) {
      return null
    }
    const matchingControl = control.parent.get(matchingControlName)

    if (control.value !== matchingControl.value) {
      return { confirmedValidator: errorMessage }
    } else {
      return null
    }
  }
}

export function emailAndPasswordSimilarityValidation(
  emailControlName = 'email'
) {
  return (control: AbstractControl) => {
    if (!control.parent) {
      return null
    }
    const password = control.value
    const email = control.parent.get(emailControlName).value
    const emailName = email.substring(0, email.indexOf('@'))

    if ([email].includes(password) || isWordInText(password, emailName)) {
      return {
        emailAndPasswordSimilarity: 'Password cannot be similar as your email', // pragma: allowlist-secret
      }
    } else {
      return null
    }
  }
}
