import { JsonObject, JsonProperty } from 'json2typescript'
import { DateConverter } from 'utils/json-utils'

@JsonObject
export class HubtypeRelatedCase {
  @JsonProperty('id', String, true)
  id?: string = undefined

  @JsonProperty('status', String, true)
  status?: string = undefined

  @JsonProperty('created_at', DateConverter, true)
  created_at?: Date = undefined

  @JsonProperty('assigned_to_id', String, true)
  assigned_to_id?: string = undefined

  @JsonProperty('resolved_at', DateConverter, true)
  resolved_at?: Date = undefined

  @JsonProperty('resolved_by_id', String, true)
  resolved_by_id?: string = undefined

  @JsonProperty('resolved_by_name', String, true)
  resolved_by_name?: string = undefined

  @JsonProperty('project_id', String, true)
  project_id?: string = undefined

  @JsonProperty('project_name', String, true)
  project_name?: string = undefined

  @JsonProperty('queue_id', String, true)
  queue_id?: string = undefined

  @JsonProperty('queue_name', String, true)
  queue_name?: string = undefined

  @JsonProperty('type', String, true)
  type?: string = undefined

  @JsonProperty('resolution_status', String, true)
  resolution_status?: string = undefined

  @JsonProperty('rating', Number, true)
  rating?: number = undefined

  @JsonProperty('number_of_notes', Number, true)
  number_of_notes?: number = undefined

  @JsonProperty('number_of_attachments', Number, true)
  number_of_attachments?: number = undefined

  @JsonProperty('description', String, true)
  description?: string = undefined
}
