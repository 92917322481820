<div class="no-auth-page-container" *ngIf="user">
  <div class="title">
    <h1>{{ isAnInvite ? 'Create password' : 'Reset your password' }}</h1>
  </div>

  <form [formGroup]="form" class="ht-form" (ngSubmit)="onSubmit()">
    <ht-input
      type="hidden"
      formControlName="email"
      style="display: none"
    ></ht-input>

    <div class="ht-form-item">
      <div class="ht-form-item-title">New password</div>
      <div class="ht-form-item-control">
        <ht-input
          label="Password"
          type="password"
          formControlName="password"
          [fullWidth]="true"
        ></ht-input>
      </div>
      <div class="help-text">Minimum 12 character long</div>
    </div>

    <div class="ht-form-item">
      <div class="ht-form-item-title">Repeat password</div>
      <div class="ht-form-item-control">
        <ht-input
          label="Repeat password"
          type="password"
          formControlName="checkPassword"
          [fullWidth]="true"
        ></ht-input>
      </div>
      <div class="help-text">Minimum 12 character long</div>
    </div>

    <div class="ht-form-row" *ngIf="form.errors?.serverError">
      <div class="error-alert">
        <i class="fa-solid fa-circle-xmark"></i>
        <span>{{ form.errors?.serverError }}</span>
      </div>
    </div>

    <div class="ht-form-actions">
      <button
        ht-button-primary
        [fullWidth]="true"
        type="submit"
        size="large"
        [isLoading]="isLoading"
      >
        Save password
      </button>
    </div>
  </form>
</div>
