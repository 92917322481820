import produce, { Draft } from 'immer'
import { LOGOUT } from '../actions/auth'
import { MAUS_ERROR, MAUS_RECEIVE } from '../actions/stats'
import { StatsState } from './stats.state'

export const initialState: StatsState = {
  maus: {},
}

export function reducer(state?: StatsState, action: any = {}) {
  return produce((draftState: Draft<StatsState>, appraisalAction: any) => {
    switch (appraisalAction.type) {
      case MAUS_RECEIVE:
        if (!action.payload.year) {
          console.error('Year not provided on "MAUS_RECEIVE" action')
          return draftState
        }
        draftState.maus[action.payload.year] = {
          data: appraisalAction.payload.data,
          timestamp: new Date().getTime(),
        }
        return draftState
      case MAUS_ERROR:
        if (!action.payload.year) {
          console.error('Year not provided on "MAUS_ERROR" action')
          return draftState
        }
        draftState.maus[action.payload.year] = {
          data: null,
          error: appraisalAction.payload.data,
          timestamp: new Date().getTime(),
        }
        return draftState
      case LOGOUT:
        return initialState
      default:
        return draftState
    }
  }, initialState)(state, action)
}
