import { Pipe, PipeTransform } from '@angular/core'
import { TimeUnit, TimeUnitFormatter } from '../entities/time-unit'
import { UnitType } from '../entities/unit-type'

@Pipe({
  name: 'unit',
})
export class UnitPipe implements PipeTransform {
  transform(value: string | number, ...args: unknown[]): string {
    const type = typeof value === 'string' ? 'string' : (args[0] as UnitType)
    switch (type) {
      case 'integer':
        const intVal = value as number
        return Math.round(intVal).toString()
      case 'float':
        if ((value as number) % 1 === 0) {
          return Math.round(value as number).toString()
        }
        const precision = (args[1] as number) || 2
        return (value as number).toFixed(precision)
      case 'percent':
        return `${value}%`
      case 'time':
        const unit: TimeUnit = (args[1] as TimeUnit) || 's'
        return TimeUnitFormatter.format(value as number, unit)
      default:
        return value as string
    }
  }
}
