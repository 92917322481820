/* eslint-disable @typescript-eslint/naming-convention */
import * as defaultProperties from './environment.common'

// qa-int in AWS
export const environment = {
  ...defaultProperties.environment,
  release_name: 'qa-test-int',
  baseURL: 'https://api.int.qa.hubtype.com',
  // Hubtype Desk OAuth2 App
  client_id: 'jFFzmYRoWC1b8eF3hJnYolTNGX8S423jnPFB8oKn',
  pusher_app_key: 'b309a4fecc28e7ea5653',
  pusher_cluster: 'eu',
  pusher_api_prefix: 'qa-test-int',
  segment_token: 'hB6X7XwbTIm1HOKaAolks0k55RMP6rbh',
  facebook_app_id: '630049405514302',
  flowbuilderIframeUrl: 'https://int.flowbuilder.qa.hubtype.com',
}
