export type CaseType = typeof CASE_TYPE_NEW | typeof CASE_TYPE_STARTED

export const CASE_TYPE_NEW = 'new'
export const CASE_TYPE_STARTED = 'started'

export type CaseConnectionStatus =
  | typeof CASE_CONNECTION_STATUS_OFFLINE
  | typeof CASE_CONNECTION_STATUS_ONLINE

export const CASE_CONNECTION_STATUS_OFFLINE = 'offline'
export const CASE_CONNECTION_STATUS_ONLINE = 'online'
export class HubtypeCaseFilter {
  status: CaseConnectionStatus[]
  channels: string[]
  content: string
  types: CaseType[]
}
