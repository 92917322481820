import { Any, JsonObject, JsonProperty } from 'json2typescript'
import moment from 'moment'
import { TagFormulaType, TagSourceType } from '../constants/tags'

export class CampaignTag {
  public readonly tag: string
  public formulaType: TagFormulaType = TagFormulaType.INCLUDE

  constructor(
    public readonly name: string,
    public value: string = ''
  ) {
    this.tag = CampaignTag.format(name, value)
  }

  static parse(formatted: string): CampaignTag {
    const parts = formatted.split(':')
    if (parts.length === 1) {
      return new CampaignTag(formatted)
    }
    return new CampaignTag(parts[0].trim(), parts[1].trim())
  }
  static format(name: string, value: string): string {
    return value ? `${name.toLowerCase()}: ${value.toLowerCase()}` : name
  }

  get hasValue(): boolean {
    return Boolean(this.value)
  }

  isIncludeTag() {
    return this.formulaType === TagFormulaType.INCLUDE
  }

  toggleFormulaType() {
    this.formulaType =
      this.formulaType === TagFormulaType.INCLUDE
        ? TagFormulaType.EXCLUDE
        : TagFormulaType.INCLUDE
  }
}

@JsonObject
export class HubtypeTag {
  public static ADDITIONAL_INFO_TAG_NAME = 'additional info'

  @JsonProperty('name', String, false)
  public name: string = undefined
  @JsonProperty('type', String, true)
  public type: string = TagSourceType.MANUAL
  @JsonProperty('created_at', Any, false)
  public created_at: any = new Date().getTime()

  constructor() {}

  get createdMoment() {
    return moment(this.created_at)
  }
}

@JsonObject
export class HubtypeContactTag {
  @JsonProperty('id', String, false)
  public id: string = undefined
  @JsonProperty('tag', Any, false)
  public tag: HubtypeTag = undefined
  @JsonProperty('created_at', Any, false)
  public created_at: any = new Date().getTime()
  @JsonProperty('tag_value', Any, true)
  public tag_value?: string = undefined

  constructor() {}

  static toCampaignTag(tag: HubtypeContactTag): CampaignTag {
    return new CampaignTag(tag.tag.name, tag.tag_value)
  }

  get createdMoment() {
    return moment(this.created_at)
  }

  get type(): string {
    return this.tag.type
  }
}
