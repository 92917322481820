import { Pipe, PipeTransform } from '@angular/core'
import { MarkdownString } from '../utils/markdown'

const BREAKLINE_REGEX = /\n/g

@Pipe({
  name: 'html',
})
export class HtmlPipe implements PipeTransform {
  transform(value: string, ...args: any[]): any {
    if (!value) {
      return ''
    }
    const content = new MarkdownString(value)
    return content.format(BREAKLINE_REGEX, '<br>').toString()
  }
}
