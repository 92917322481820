<div
  class="avatar"
  role="avatar-component"
  [style.background-image]="
    'url(' + (pic || 'assets/images/anonymous_user.png') + ')'
  "
>
  <div
    *ngIf="color || status"
    class="secondary {{ status }}"
    [style.background]="color"
  ></div>
  <div *ngIf="provider" class="chat-avatar-provider {{ provider }}"></div>
</div>
