<button
  #htButton
  [ngClass]="allClasses"
  [disabled]="disabled"
  (click)="onClickButton($event)"
  (blur)="btnFocus.emit(false)"
  (focus)="btnFocus.emit(true)"
>
  <div [hidden]="isLoading">
    <i *ngIf="icon" class="material-icons btn-icon">{{ icon }}</i>
    <span class="btn-caption">{{ text }}</span>
  </div>
  <div class="btn-spinner" [hidden]="!isLoading">
    <mat-spinner [diameter]="spinnerDiameter"></mat-spinner>
  </div>
</button>
