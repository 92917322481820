import { CommonModule } from '@angular/common'
import { HttpClientModule } from '@angular/common/http'
import { NgModule } from '@angular/core'
import { FlexLayoutModule } from '@angular/flex-layout'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { RouterModule } from '@angular/router'
import { NgxMatIntlTelInputModule } from '../../../lib/ngx-mat-intl-tel-input/ngx-mat-intl-tel-input.module'
import { AppMaterialModule } from '../app-material.module'
// Components
import { AddChannelsComponent } from './add-channels/add-channels.component'
import { FacebookComponent } from './add-channels/facebook/facebook.component'
import { TelegramComponent } from './add-channels/telegram/telegram.component'
import { TwitterComponent } from './add-channels/twitter/twitter.component'
import { WebchatComponent } from './add-channels/webchat/webchat.component'
import { WhatsappPlaygroundComponent } from './add-channels/whatsapp-playground/whatsapp-playground.component'
import { WhatsappComponent } from './add-channels/whatsapp/whatsapp.component'
import { AddIntegrationComponent } from './add-integration/add-integration.component'
import { ZendeskComponent } from './add-integration/zendesk/zendesk.component'
import { AppVersionComponent } from './app-version/app-version.component'
import { AssignAgentsToQueueModalComponent } from './assign-agents-to-queue-modal/assign-agents-to-queue-modal.component'
import { AwayDialogComponent } from './away-dialog/away-dialog.component'
import { BanUserDialogComponent } from './ban-user-dialog/ban-user-dialog.component'
import { BaseModule } from './base/base.module'
import { ChangeBotNameComponent } from './change-bot-name/change-bot-name.component'
import { ChangePasswordComponent } from './change-password/change-password.component'
import { ChannelDetailComponent } from './channel-detail/channel-detail.component'
import { CompleteProfileComponent } from './complete-profile/complete-profile.component'
import { DeleteBotConfirmComponent } from './delete-bot-confirm/delete-bot-confirm.component'
import { BotDeleteOrResolveDialogComponent } from './delete_or_transfer-dialog/bot-delete_or_resolve-dialog.component'
import { EditTemplateDialogComponent } from './edit-template-dialog/edit-template-dialog.component'
import { FilterUserStatusComponent } from './filter-user-status/filter-user-status.component'
import { GenericCRUDComponent } from './generic-crud/generic-crud.component'
import { GenericEditComponent } from './generic-edit/generic-edit.component'
import { GetNameDialogComponent } from './get-name-dialog/get-name-dialog.component'
import { HolidaySelectorComponent } from './holiday-selector/holiday-selector.component'
import { ListChannelsComponent } from './list-channels/list-channels.component'
import { NoAuthPageComponent } from './no-auth-page/no-auth-page.component'
import { PusherStatusHandlerComponent } from './pusher-status-handler/pusher-status-handler.component'
import { QueueListComponent } from './queue-list/queue-list.component'
import { QueueSelectorComponent } from './queue-selector/queue-selector.component'
import { ReachedAccountsLimitDialogComponent } from './reached-accounts-limit-dialog/reached-accounts-limit-dialog.component'
import { RequestLicensesModalComponent } from './request-licenses-modal/request-licenses-modal.component'
import { SetNewPasswordComponent } from './set-new-password/set-new-password.component'
import { SharedBrandModule } from './shared-brand/shared-brand.module'
import { SharedCoreModule } from './shared-core/shared-core.module'
import { UserDetailComponent } from './user-detail/user-detail.component'
import { UserListComponent } from './user-list/user-list.component'
import { WebchatSettingsComponent } from './webchat-settings/webchat-settings.component'
import { WebchatSnippetComponent } from './webchat-snippet/webchat-snippet.component'
import { WhitelistedUrlsComponent } from './whitelisted-urls/whitelisted-urls.component'

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    HttpClientModule,
    FlexLayoutModule,
    NgxMatIntlTelInputModule,
    AppMaterialModule,
    SharedCoreModule,
    SharedBrandModule,
    BaseModule,
  ],
  declarations: [
    AppVersionComponent,
    GetNameDialogComponent,
    GenericCRUDComponent,
    GenericEditComponent,
    EditTemplateDialogComponent,
    TelegramComponent,
    FacebookComponent,
    TwitterComponent,
    WhatsappComponent,
    WhatsappPlaygroundComponent,
    WebchatComponent,
    WebchatSettingsComponent,
    WebchatSnippetComponent,
    ZendeskComponent,
    ListChannelsComponent,
    QueueListComponent,
    QueueSelectorComponent,
    AwayDialogComponent,
    BotDeleteOrResolveDialogComponent,
    AddChannelsComponent,
    ChannelDetailComponent,
    AddIntegrationComponent,
    ChangeBotNameComponent,
    DeleteBotConfirmComponent,
    ChangePasswordComponent,
    HolidaySelectorComponent,
    WhitelistedUrlsComponent,
    UserDetailComponent,
    ReachedAccountsLimitDialogComponent,
    BanUserDialogComponent,
    PusherStatusHandlerComponent,
    AssignAgentsToQueueModalComponent,
    UserListComponent,
    FilterUserStatusComponent,
    SetNewPasswordComponent,
    CompleteProfileComponent,
    NoAuthPageComponent,
    RequestLicensesModalComponent,
  ],
  exports: [
    AppVersionComponent,
    GetNameDialogComponent,
    EditTemplateDialogComponent,
    GenericCRUDComponent,
    GenericEditComponent,
    TelegramComponent,
    FacebookComponent,
    TwitterComponent,
    WhatsappComponent,
    WhatsappPlaygroundComponent,
    WebchatComponent,
    WebchatSettingsComponent,
    WebchatSnippetComponent,
    ZendeskComponent,
    ListChannelsComponent,
    BotDeleteOrResolveDialogComponent,
    QueueListComponent,
    QueueSelectorComponent,
    AwayDialogComponent,
    AddChannelsComponent,
    ChannelDetailComponent,
    AddIntegrationComponent,
    ChangeBotNameComponent,
    DeleteBotConfirmComponent,
    ChangePasswordComponent,
    HolidaySelectorComponent,
    WhitelistedUrlsComponent,
    UserDetailComponent,
    PusherStatusHandlerComponent,
    AssignAgentsToQueueModalComponent,
    UserListComponent,
    FilterUserStatusComponent,
    CompleteProfileComponent,
    NoAuthPageComponent,
    RequestLicensesModalComponent,
  ],
  providers: [],
})
export class SharedComponentModule {}
