import { HubtypeWhatsappTemplate } from '../models/hubtype-whatsapp-template'

const TEMPLATE_APPROVED_STATUS = 'APPROVED'
const TEMPLATE_REJECTED_STATUS = 'REJECTED'

export type TemplateFilter = string[]

export namespace TemplateFilterType {
  export const ALL: TemplateFilter = [
    TEMPLATE_APPROVED_STATUS,
    TEMPLATE_REJECTED_STATUS,
  ]
  export const ACTIVE: TemplateFilter = [TEMPLATE_APPROVED_STATUS]
}
export function filterTemplates(
  templates: HubtypeWhatsappTemplate[],
  filter: TemplateFilter
): HubtypeWhatsappTemplate[] {
  return templates.filter(t => filter.includes(t.status))
}
