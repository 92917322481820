import { Injectable } from '@angular/core'
import { CanActivate } from '@angular/router'
import { Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { first, map } from 'rxjs/operators'
import { FeatureFlag } from '../constants/feature-flags'
import * as fromRoot from '../reducers'

@Injectable({
  providedIn: 'root',
})
export class IntentsGuard implements CanActivate {
  constructor(private store: Store<fromRoot.State>) {}

  canActivate(): Observable<boolean> {
    return this.store.select(fromRoot.getOrganization).pipe(
      first(x => Boolean(x)),
      map(org => Boolean(org.feature_flags_json[FeatureFlag.AI_INTENTS]))
    )
  }
}
