import produce, { Draft } from 'immer'
import { InsightsAction, InsightsActionType } from '../actions/insights'
import { insightsInitialState, InsightsState } from './insights.state'

export function reducer(state: InsightsState, action: InsightsAction) {
  return produce(
    (draftState: Draft<InsightsState>, appraisalAction: InsightsAction) => {
      switch (appraisalAction.type) {
        case InsightsActionType.SET_DATE_FROM:
          draftState.dateFrom = appraisalAction.payload
          break
        case InsightsActionType.SET_PROJECT_FILTERS:
          draftState.projectFilters = appraisalAction.payload
          break

        case InsightsActionType.SET_PROJECT_BADGES:
          draftState.projectBadges = appraisalAction.payload
          break

        case InsightsActionType.SET_QUEUE_BADGES:
          draftState.queuesBadges = appraisalAction.payload
          break
        case InsightsActionType.SET_PROVIDER_BADGES:
          draftState.providersBabges = appraisalAction.payload
          break

        default:
          return draftState
      }
    },
    insightsInitialState
  )(state, action)
}
