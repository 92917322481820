import { environment } from 'environment/environment'

/* eslint-disable space-before-function-paren */
export function initializeUserGuiding(): Promise<void> {
  return new Promise((resolve, reject) => {
    if (!environment.userGuidingContainerId || environment.minimalVersion) {
      resolve()
      return
    }

    // eslint-disable-next-line no-extra-semi
    ;(function (g, u, i, d, e, s) {
      g[e] = g[e] || []
      let k
      let f = u.getElementsByTagName(i)[0]
      k = u.createElement(i)
      k.async = true
      k.src =
        'https://static.userguiding.com/media/user-guiding-' +
        s +
        '-embedded.js'
      f.parentNode.insertBefore(k, f)
      // eslint-disable-next-line curly
      if (g[d]) return
      let ug
      ug = g[d] = { q: [] }
      ug.c = function (n) {
        return function () {
          ug.q.push([n, arguments])
        }
      }
      let m = [
        'previewGuide',
        'finishPreview',
        'track',
        'identify',
        'hideChecklist',
        'launchChecklist',
      ]
      // eslint-disable-next-line @typescript-eslint/prefer-for-of
      for (let j = 0; j < m.length; j += 1) {
        ug[m[j]] = ug.c(m[j])
      }
    })(
      window,
      document,
      'script',
      'userGuiding',
      'userGuidingLayer',
      environment.userGuidingContainerId
    )

    resolve()
  })
}
