import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'no-auth-page',
  templateUrl: './no-auth-page.component.html',
  styleUrls: ['./no-auth-page.component.scss'],
})
export class NoAuthPageComponent implements OnInit {
  year = new Date().getFullYear()
  constructor() {}

  ngOnInit(): void {}
}
