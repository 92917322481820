import { Inject, Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { JsonConvert } from 'json2typescript'
import {
  DEFAULT_CONTACT_REASON_CATEGORY,
  HubtypeContactReason,
  HubtypeContactReasonCategory,
} from 'models/hubtype-contact-reason'
import { Observable, of, throwError } from 'rxjs'
import { catchError, first, map, mergeMap } from 'rxjs/operators'
import { HubtypeProject } from '../../models/hubtype-project'
import { HubtypeUser } from '../../models/hubtype-user'
import * as fromRoot from '../../reducers'
import { Utilities } from '../../shared/utilities'
import { ConverterService } from '../converter.service'
import { FeedbackService } from '../feedback.service'
import { HubtypeApiService } from './hubtype-api.service'

@Injectable()
export class ProjectService {
  jsonConverter: JsonConvert = new JsonConvert()
  constructor(
    protected store: Store<fromRoot.State>,
    private feedbackService: FeedbackService,
    @Inject('apiService') private apiService: HubtypeApiService,
    @Inject('Utilities') private utilities: Utilities,
    @Inject('convertService') private convertService: ConverterService
  ) {}

  get(projectId: string, forceSync = false): Observable<HubtypeProject> {
    if (forceSync) {
      return this.fetch(projectId)
    }

    return this.store.select(fromRoot.getProject(projectId)).pipe(
      mergeMap(project => {
        if (project && project.id === projectId) {
          return of(project)
        }
        return this.fetch(projectId)
      })
    )
  }

  getSelected(): Observable<HubtypeProject | null> {
    return this.store.select(fromRoot.getSelectedProject)
  }

  getAll(forceSync = false): Observable<HubtypeProject[]> {
    if (forceSync) {
      return this.fetchAll()
    }
    return this.store.select(fromRoot.getProjects).pipe(
      mergeMap(projects => {
        if (!projects || projects.length === 0) {
          return this.fetchAll()
        }
        return of(projects)
      })
    )
  }

  // DELETE: /projects/{projectid}
  remove(project: HubtypeProject): Observable<HubtypeProject> {
    return this.apiService
      .delete(`/projects/${project.id}/`)
      .pipe(
        map(response =>
          this.convertService.jsonConvert.deserializeObject(
            project,
            HubtypeProject
          )
        )
      )
    // .map(() => plainToClass<HubtypeProject,Object>(HubtypeProject,project));
  }

  // POST: /projects/`
  addByName(name: string): Observable<HubtypeProject> {
    const body = { name }
    return this.apiService
      .post(`/projects/`, body)
      .pipe(
        map(response =>
          this.convertService.jsonConvert.deserializeObject(
            response,
            HubtypeProject
          )
        )
      )
    // .map(result => plainToClass<HubtypeProject,Object>(HubtypeProject,result));
  }

  update(project: HubtypeProject): Observable<HubtypeProject> {
    const body = project
    return this.apiService
      .put(`/projects/${project.id}/`, body, 'v1', false)
      .pipe(
        map(response =>
          this.convertService.jsonConvert.deserializeObject(
            response,
            HubtypeProject
          )
        )
      )
  }

  addManagers(
    project: HubtypeProject,
    managers: HubtypeUser[]
  ): Observable<{ manager_id: string; project: HubtypeProject }> {
    const managersIds = managers.map(manager => manager.id)
    const body = { manager_id: managersIds }
    return this.apiService.post(`/projects/${project.id}/add_manager/`, body)
  }

  deleteManagers(
    project: HubtypeProject,
    managers: HubtypeUser[]
  ): Observable<{ manager_id: string; project: HubtypeProject }> {
    const managersIds = managers.map(manager => manager.id)
    const body = { manager_id: managersIds }
    return this.apiService.post(`/projects/${project.id}/delete_manager/`, body)
  }

  // POST: /projects/{projectId}/upload_pic/
  uploadPicture(projectId: string, pic: string) {
    const body = new FormData()
    const img_data = this.utilities.dataURLToBlob(pic)
    body.append('pic', img_data, 'pic')
    return this.apiService.post(`/projects/${projectId}/upload_pic/`, body)
  }

  getContactReasonsCategories(
    projectId: string
  ): Observable<HubtypeContactReasonCategory[]> {
    return this.apiService
      .get(`/projects/${projectId}/contact_reasons/tree/`)
      .pipe(
        first(),
        map(response => {
          const withoutCategory = response.without_category as any[]
          const withCategory = response.with_category as any[]
          const categories = [
            {
              ...DEFAULT_CONTACT_REASON_CATEGORY,
              contact_reasons: withoutCategory,
            },
            ...withCategory.map(category => ({
              ...category,
              contact_reasons: category.contact_reasons.map(contactReason => ({
                ...contactReason,
              })),
            })),
          ]
          return this.convertService.jsonConvert.deserializeArray(
            categories,
            HubtypeContactReasonCategory
          )
        })
      )
  }

  createContactReasonCategory(
    projectId: string,
    name: string
  ): Observable<HubtypeContactReasonCategory> {
    return this.apiService
      .post(
        `/projects/${projectId}/contact_reason_categories/`,
        {
          name,
        },
        null,
        'v1',
        false
      )
      .pipe(
        catchError(error => {
          if (error.status === 409) {
            return throwError(() => ({
              error: {
                name: error.error?.detail,
              },
            }))
          }
          this.feedbackService.error(
            '',
            'There has been an error creating the category',
            error
          )
          return throwError(() => error)
        })
      )
      .pipe(
        map(category =>
          this.convertService.jsonConvert.deserializeObject(
            category,
            HubtypeContactReasonCategory
          )
        )
      )
  }

  deleteContactReasonCategory(
    projectId: string,
    categoryId: string
  ): Observable<HubtypeContactReasonCategory> {
    return this.apiService.delete(
      `/projects/${projectId}/contact_reason_categories/${categoryId}/`
    )
  }

  updateContactReasonCategory(
    projectId: string,
    category: HubtypeContactReasonCategory
  ): Observable<HubtypeContactReasonCategory> {
    return this.apiService
      .patch(
        `/projects/${projectId}/contact_reason_categories/${category.id}/`,
        {
          name: category.name,
        },
        'v1',
        false
      )
      .pipe(
        catchError(error => {
          if (error.status === 409) {
            return throwError(() => ({
              error: {
                name: error.error?.detail,
              },
            }))
          }
          this.feedbackService.error(
            '',
            'There has been an error updating the category',
            error
          )
          return throwError(() => error)
        }),
        map(newCategory =>
          this.convertService.jsonConvert.deserializeObject(
            newCategory,
            HubtypeContactReasonCategory
          )
        )
      )
  }

  getAllPaginatedProjects(): Observable<HubtypeProject[]> {
    const PAGE_SIZE = 100
    return this.apiService
      .getAllPaginatedResults(PAGE_SIZE, '/projects/', 'v2')
      .pipe(
        first(),
        map(allUsers =>
          this.jsonConverter.deserializeArray(allUsers, HubtypeProject)
        )
      )
  }

  updateContactReason(projectId: string, contactReason: HubtypeContactReason) {
    return this.apiService
      .patch(
        `/projects/${projectId}/contact_reasons/${contactReason.id}/`,
        {
          name: contactReason.name,
          category_id:
            contactReason.categoryId === DEFAULT_CONTACT_REASON_CATEGORY.id
              ? null
              : contactReason.categoryId,
        },
        'v1',
        false
      )
      .pipe(
        catchError(error => {
          if (error.status === 409) {
            return throwError(() => ({
              error: {
                name: error.error?.detail,
              },
            }))
          }
          this.feedbackService.error(
            '',
            'There has been an error updating the contact reason',
            error
          )
          return throwError(() => error)
        })
      )
      .pipe(
        map(updatedContactReason =>
          this.convertService.jsonConvert.deserializeObject(
            updatedContactReason,
            HubtypeContactReason
          )
        )
      )
  }

  createContactReason(
    name: string,
    projectId: string,
    categoryId: string = null
  ): Observable<HubtypeContactReason> {
    return this.apiService
      .post(
        `/projects/${projectId}/contact_reasons/`,
        {
          name,
          category_id:
            categoryId === DEFAULT_CONTACT_REASON_CATEGORY.id
              ? null
              : categoryId,
        },
        null,
        'v1',
        false
      )
      .pipe(
        catchError(error => {
          if (error.status === 409) {
            return throwError(() => ({
              error: {
                name: error.error?.detail,
              },
            }))
          }
          this.feedbackService.error(
            '',
            'There has been an error creating the contact reasons',
            error
          )
          return throwError(() => error)
        })
      )
      .pipe(
        map(category =>
          this.convertService.jsonConvert.deserializeObject(
            category,
            HubtypeContactReason
          )
        )
      )
  }

  deleteContactReason(
    projectId: string,
    contactReasonId: string
  ): Observable<HubtypeContactReason> {
    return this.apiService.delete(
      `/projects/${projectId}/contact_reasons/${contactReasonId}/`
    )
  }

  // GET: /projects/{project.id}
  private fetch(projectId: string): Observable<HubtypeProject> {
    // TODO: Define action to save project on state
    return this.apiService
      .get(`/projects/${projectId}/`)
      .pipe(
        map(response =>
          this.convertService.jsonConvert.deserializeObject(
            response,
            HubtypeProject
          )
        )
      )
  }

  // GET: /projects
  private fetchAll(): Observable<HubtypeProject[]> {
    // TODO: Define action to save project on state
    return this.apiService
      .get(`/projects/`)
      .pipe(
        map(response =>
          this.convertService.jsonConvert.deserializeArray(
            response,
            HubtypeProject
          )
        )
      )
  }
}
