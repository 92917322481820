import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { combineLatest, Observable } from 'rxjs'
import { filter, map } from 'rxjs/operators'
import * as fromRoot from '../reducers'

const licensesWarningLimit = 90

export enum LicenseState {
  WARN = 'warn',
  FULL = 'full',
  NONE = '',
}
export interface LicensesStatus {
  licenses: number
  progress: number
  state: LicenseState
  activeAccount: number
}

@Injectable({
  providedIn: 'root',
})
export class LicenseService {
  constructor(protected store: Store<fromRoot.State>) {}
  getCurrentActiveAccounts() {
    return this.store
      .select(fromRoot.getUsers)
      .pipe(map(users => users.filter(u => u.is_active).length))
  }

  getLicenseInfo(): Observable<LicensesStatus> {
    return combineLatest([
      this.getCurrentActiveAccounts(),
      this.store.select(fromRoot.getOrganization).pipe(filter(f => Boolean(f))),
    ]).pipe(
      map(values => {
        const organization = values[1]
        const activeAccount = values[0]
        const progress =
          organization.licenses === 0
            ? 100
            : (activeAccount / organization.licenses) * 100

        let state = LicenseState.NONE
        if (progress >= 100) {
          state = LicenseState.FULL
        } else if (progress < 100) {
          if (
            progress >= licensesWarningLimit &&
            organization.licenses - activeAccount <= 5
          ) {
            state = LicenseState.WARN
          }
        }

        return {
          activeAccount,
          licenses: organization.licenses,
          progress,
          state,
        }
      })
    )
  }
}
