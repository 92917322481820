import { Any, JsonProperty } from 'json2typescript'
import { HubtypeTemplateVariable } from './hubtype-template-variable'

export enum WhatsAppComponentType {
  BODY = 'BODY',
  BUTTONS = 'BUTTONS',
  FOOTER = 'FOOTER',
  HEADER = 'HEADER',
  QUICK_REPLY = 'QUICK_REPLY',
}

const WHATSAPP_COMPONENT_TYPE_ORDER = Object.freeze([
  WhatsAppComponentType.HEADER,
  WhatsAppComponentType.BODY,
  WhatsAppComponentType.FOOTER,
  WhatsAppComponentType.BUTTONS,
])

export function sortWhatsAppComponents(
  a: HubtypeWhatsappTemplateComponent,
  b: HubtypeWhatsappTemplateComponent
): number {
  const indexA = WHATSAPP_COMPONENT_TYPE_ORDER.findIndex(
    type => type === a.type
  )
  const indexB = WHATSAPP_COMPONENT_TYPE_ORDER.findIndex(
    type => type === b.type
  )
  return indexA - indexB
}

export const COMPONENT_TYPES_WITH_VARS = [
  WhatsAppComponentType.HEADER,
  WhatsAppComponentType.BODY,
]

export enum WhatsAppHeaderFormat {
  DOCUMENT_FORMAT = 'DOCUMENT',
  IMAGE_FORMAT = 'IMAGE',
  VIDEO_FORMAT = 'VIDEO',
  TEXT_FORMAT = 'TEXT',
}

export interface HubtypeWhatsappTemplateComponent {
  type: WhatsAppComponentType
  text: string // eg. "hello {{1}} {{2}}
  buttons?: { type: WhatsAppComponentType; text: string }[]
  format?: WhatsAppHeaderFormat
  variables?: HubtypeWhatsappTemplateVariable[]
}
/**
 * Same shape as {@link HubtypeTemplateVariable}, independent from our implementation
 */
export interface HubtypeWhatsappTemplateVariable {
  type: 'manual' | 'name' | 'tag'
  value: string
}

export class ImpMsgWhatsappTemplateComponent {
  type: WhatsAppComponentType
  parameters: {
    type: string // eg text
    text: string
  }[]
}

// https://developers.facebook.com/docs/whatsapp/api/messages/message-templates/media-message-templates/
export class ImpMsgWhatsappTemplate {
  @JsonProperty('name', String, false)
  public name?: string = undefined

  @JsonProperty('components', Any, false)
  public components: ImpMsgWhatsappTemplateComponent[] = []

  @JsonProperty('languageCode', String, true)
  public languageCode?: string = undefined

  @JsonProperty('namespace', String, true)
  public namespace?: string = undefined

  static extractVariables(
    template: ImpMsgWhatsappTemplate
  ): HubtypeTemplateVariable[] {
    const params = template.components
      .filter(comp =>
        COMPONENT_TYPES_WITH_VARS.map(c => c.toLowerCase()).includes(comp.type)
      )
      .map(comp => comp.parameters)
      .reduce((arr, part) => arr.concat(...part), []) // Flatten array

    // @ts-ignore: string to restricted type of strings
    return params.map(v => {
      if (v.type === 'tag' && v.text === 'name') {
        return {
          type: 'name',
          value: '',
        }
      }
      return {
        type: v.type,
        value: v.text,
      }
    })
  }
}

export interface WhatsAppButtonComponent {
  url: string
  text: string
  type: 'URL'
}
