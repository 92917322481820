import { Inject, Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { of } from 'rxjs'
import { catchError, map, switchMap } from 'rxjs/operators'
import {
  CASE_COUNTERS_ERROR,
  CASE_COUNTERS_GET,
  TransferCaseRequestAction,
  TRANSFER_CASE_REQUEST,
  TRANSFER_CASE_RESPONSE,
  UpdateCounters,
} from '../../actions/desk'
import { CustomError } from '../../constants/errors'
import { FeedbackService } from '../../services/feedback.service'
import { CaseService } from '../../services/hubtype-api/case.service'

@Injectable()
export class CaseEffects {
  counts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CASE_COUNTERS_GET),
      switchMap(() =>
        this.caseService.getCounters().pipe(
          map(counters => new UpdateCounters(counters)),
          catchError(err => {
            this.feedbackService.error(
              // eslint-disable-next-line
              CustomError.LOADING_CASE_COUNTERS.code,
              // eslint-disable-next-line
              CustomError.LOADING_CASE_COUNTERS.message,
              err
            )
            return of({ type: CASE_COUNTERS_ERROR, payload: err })
          })
        )
      )
    )
  )

  transfer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TRANSFER_CASE_REQUEST),
      switchMap((action: TransferCaseRequestAction) =>
        this.caseService
          .transferCase(action.payload.case.id, action.payload)
          .pipe(map(() => ({ type: TRANSFER_CASE_RESPONSE, payload: null })))
      )
    )
  )

  constructor(
    private actions$: Actions,
    private feedbackService: FeedbackService,
    @Inject('caseService') private caseService: CaseService
  ) {}
}
