import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { getCookie } from 'utils/dom-utils'
import { environment } from '../../../environments/environment'
import { HubtypeAuthInterceptor } from './hubtype-auth.interceptor'

@Injectable()
export class SegmentInterceptor implements HttpInterceptor {
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    let req = request
    if (this.isHubtypeAuthUri(request.url)) {
      const segmentAnonymousId = getCookie('ajs_anonymous_id')
      if (segmentAnonymousId) {
        const segmentHeaderValue = segmentAnonymousId.replace(/%22/g, '')
        req = request.clone({
          headers: request.headers.set(
            'x-segment-anonymous-id',
            segmentHeaderValue
          ),
        })
      }
    }
    return next.handle(req)
  }

  private isHubtypeAuthUri(uri: string): boolean {
    return (
      uri?.startsWith(environment.baseURL) &&
      !uri.startsWith(HubtypeAuthInterceptor.AUTH_TOKEN_URI)
    )
  }
}
