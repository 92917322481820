/* eslint-disable @typescript-eslint/naming-convention */
import { HttpHeaders } from '@angular/common/http'
import { Injectable, OnDestroy } from '@angular/core'
import { JsonObject, JsonProperty } from 'json2typescript'
import { iif, interval, Observable, of, Subject, Subscription } from 'rxjs'
import { map, switchMap } from 'rxjs/operators'
import { environment } from '../../environments/environment'
import { FeedbackService } from './feedback.service'
import { RestService } from './rest.service'

const CHECK_UPDATER_INTERVAL_MIN = 5

@JsonObject
export class BuildInfo {
  @JsonProperty('version', String, true)
  public version: string = undefined

  constructor() {}
}

export interface VersionInfo {
  localVersion: string
  latestVersion: string
  isOutdated: boolean
}

@Injectable()
export class AppVersionService implements OnDestroy {
  private latestVersion = 'unknown'
  private isOutdated = false
  private checkerIntervalSubscription: Subscription
  private versionInfo$: Subject<VersionInfo>

  constructor(
    private restService: RestService,
    private feedbackService: FeedbackService
  ) {
    this.versionInfo$ = new Subject()
    this.prepareVersionInfo()
  }

  ngOnDestroy() {
    if (this.checkerIntervalSubscription) {
      this.checkerIntervalSubscription.unsubscribe()
    }
  }

  startCheckIsOutdated(force: boolean = false) {
    this.fetchLatestVersion().subscribe(latestVersionInfo => {
      if (!force && latestVersionInfo.isOutdated) {
        return
      }

      if (this.checkerIntervalSubscription !== undefined) {
        return
      }

      this.checkerIntervalSubscription = interval(
        CHECK_UPDATER_INTERVAL_MIN * 60 * 1000
      ).subscribe(_ =>
        this.fetchLatestVersion().subscribe(periodicLatestVersionInfo => {
          if (periodicLatestVersionInfo.isOutdated) {
            this.checkerIntervalSubscription.unsubscribe()
          }
        })
      )
    })
  }

  observe(
    next?: (value: VersionInfo) => void,
    err?: (error: any) => void
  ): Subscription {
    return this.versionInfo$.asObservable().subscribe(next, err)
  }

  fetchLatestVersion(): Observable<VersionInfo> {
    return iif(
      () => Boolean(this.isOutdated),
      of(this.prepareVersionInfo()),
      this._fetchLatestVersion().pipe(
        switchMap(buildInfo => {
          this.latestVersion = buildInfo.version
          if (this.latestVersion === environment.version) {
          } else {
            this.isOutdated = true
            this.feedbackService.info(
              'There is a new version of the application. Please, refresh the browser.'
            )
          }
          return of(this.prepareVersionInfo())
        })
      )
    )
  }

  private prepareVersionInfo(): VersionInfo {
    const versionInfo = {
      localVersion: environment.version,
      latestVersion: this.latestVersion,
      isOutdated: this.isOutdated,
    }
    this.versionInfo$.next(versionInfo)
    return versionInfo
  }

  private _fetchLatestVersion(): Observable<BuildInfo> {
    const headers = new HttpHeaders({
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
    })
    return this.restService
      .request(
        'GET',
        `/assets/json/build-info.json?v=${new Date().getTime()}`,
        {
          method: 'GET',
          options: { headers },
        }
      )
      .pipe(map<any, BuildInfo>(response => response))
  }
}
