import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
} from '@angular/core'

@Component({
  selector: 'ht-empty-case',
  templateUrl: './ht-empty-case.component.html',
  styleUrls: ['./ht-empty-case.component.scss'],
})
export class HtEmptyCaseComponent implements OnInit {
  @HostBinding(`class.column-align`) @Input() columnAlign = false
  @Input()
  title = ''
  @Input()
  subtitle = ''
  @Input()
  buttonText = ''
  @Input()
  icon = ''
  @Input()
  buttonIcon = 'fa-plus'

  @Output()
  buttonClick = new EventEmitter<any>()

  constructor() {}

  ngOnInit(): void {}
}
