import { Action } from '@ngrx/store'
import { HubtypeFolder } from '../models/hubtype-folder'
import {
  HubtypeOrganization,
  HubtypeOrganizationComplete,
} from '../models/hubtype-organization'
import { HubtypeProject } from '../models/hubtype-project'
import { HubtypeProviderAccount } from '../models/hubtype-provider-account'
import { HubtypeQueue, HubtypeQueueCounter } from '../models/hubtype-queue'
import { HubtypeTemplate } from '../models/hubtype-template'
import { HubtypeUser } from '../models/hubtype-user'

export const SET_ORGANIZATION = '[Organization] Set'
export const UPDATE_ORGANIZATION = '[Organization] Update'

export const ADD_PROJECT = '[Organization] Add project'
export const DELETE_PROJECT = '[Organization] Delete project'
export const SET_PROJECT = '[Organization] Set project'
export const UPDATE_PROJECT = '[Organization] Update project'

export const ADD_TEMPLATE_FOLDER = '[Organization] Add template folder'
export const EDIT_TEMPLATE_FOLDER = '[Organization] Edit template folder'
export const DELETE_TEMPLATE_FOLDER = '[Organization] Delete template folder'
export const ADD_TEMPLATE = '[Organization] Add template'
export const DELETE_TEMPLATE = '[Organization] Delete template'
export const SET_TEMPLATE = '[Organization] Set template'
export const UPDATE_TEMPLATE = '[Organization] Update template'

export const ADD_QUEUE = '[Organization] Add queue'
export const DELETE_QUEUE = '[Organization] Delete queue'
export const SET_ALL_QUEUES = '[Organization] Set all queue'
export const SET_QUEUE = '[Organization] Set queue'
export const UPDATE_QUEUE = '[Organization] Update queue'
export const UPDATE_QUEUE_COUNTERS = '[Organization] Update Queue Counters'
export const UPDATE_ALL_QUEUES_COUNTERS =
  '[Organization] Update All Queues Counters'
export const SELECTED_QUEUE = '[Organization] Selected queue'

export const ADD_USER = '[Organization] Add user'
export const UPDATE_USER = '[Organization] Update user'
export const DELETE_USER = '[Organization] Delete user'

export const ADD_MANAGER = '[Organization] Add manager'
export const DELETE_MANAGER = '[Organization] Delete manager'
export const ADD_AGENT = '[Organization] Add agent'
export const DELETE_AGENT = '[Organization] Delete agent'
export const CHANGE_STATUS_AGENT = '[Organization] Change status agent'

export const ADD_PROVIDER_ACCOUNT = '[Organization] Add provider account'
export const UPDATE_PROVIDER_ACCOUNT = '[Organization] Update provider account'
export const DELETE_PROVIDER_ACCOUNT = '[Organization] Delete provider account'

export const UPDATE_NAME = '[Organization] Update name'

/**
 * Every action is comprised of at least a type and an optional
 * payload. Expressing actions as classes enables powerful
 * type checking in reducer functions.
 *
 * See Discriminated Unions: https://www.typescriptlang.org/docs/handbook/advanced-types.html#discriminated-unions
 */
export class SetOrganizationAction implements Action {
  readonly type = SET_ORGANIZATION

  constructor(
    public payload: { organization: HubtypeOrganizationComplete; meId: string }
  ) {}
}

export class UpdateOrganizationAction implements Action {
  readonly type = UPDATE_ORGANIZATION

  constructor(public payload: HubtypeOrganization) {}
}

export class AddProjectAction implements Action {
  readonly type = ADD_PROJECT

  constructor(public payload: HubtypeProject) {}
}

export class DeleteProjectAction implements Action {
  readonly type = DELETE_PROJECT

  constructor(public payload: HubtypeProject) {}
}

export class SetProjectAction implements Action {
  readonly type = SET_PROJECT

  constructor(public payload: HubtypeProject) {}
}

export class UpdateProjectAction implements Action {
  readonly type = UPDATE_PROJECT

  constructor(public payload: HubtypeProject) {}
}

export class AddTemplateAction implements Action {
  readonly type = ADD_TEMPLATE

  constructor(public payload: HubtypeTemplate) {}
}

export class AddTemplateFolderAction implements Action {
  readonly type = ADD_TEMPLATE_FOLDER

  constructor(public payload: HubtypeFolder) {}
}

export class EditTemplateFolderAction implements Action {
  readonly type = EDIT_TEMPLATE_FOLDER

  constructor(public payload: HubtypeFolder) {}
}

export class DeleteTemplateFolderAction implements Action {
  readonly type = DELETE_TEMPLATE_FOLDER

  constructor(public payload: HubtypeFolder) {}
}

export class DeleteTemplateAction implements Action {
  readonly type = DELETE_TEMPLATE

  constructor(public payload: HubtypeTemplate) {}
}

export class SetTemplateAction implements Action {
  readonly type = SET_TEMPLATE

  constructor(public payload: HubtypeTemplate) {}
}

export class UpdateTemplateAction implements Action {
  readonly type = UPDATE_TEMPLATE

  constructor(public payload: HubtypeTemplate) {}
}

export class AddQueueAction implements Action {
  readonly type = ADD_QUEUE

  constructor(public payload: HubtypeQueue) {}
}

export class DeleteQueueAction implements Action {
  readonly type = DELETE_QUEUE

  constructor(public payload: HubtypeQueue) {}
}

export class SetAllQueueAction implements Action {
  readonly type = SET_ALL_QUEUES

  constructor(public payload: HubtypeQueue[]) {}
}

export class SetQueueAction implements Action {
  readonly type = SET_QUEUE

  constructor(public payload: HubtypeQueue) {}
}

export class UpdateQueueAction implements Action {
  readonly type = UPDATE_QUEUE

  constructor(public payload: HubtypeQueue) {}
}

export class UpdateQueueCounters implements Action {
  readonly type = UPDATE_QUEUE_COUNTERS

  constructor(public payload: any) {}
}
export class UpdateAllQueuesCounters implements Action {
  readonly type = UPDATE_ALL_QUEUES_COUNTERS

  constructor(public payload: HubtypeQueueCounter[]) {}
}

export class AddUserAction implements Action {
  readonly type = ADD_USER

  constructor(public payload: HubtypeUser) {}
}

export class UpdateUserAction implements Action {
  readonly type = UPDATE_USER

  constructor(public payload: HubtypeUser) {}
}

export class DeleteUserAction implements Action {
  readonly type = DELETE_USER

  constructor(public payload: HubtypeUser) {}
}

export class AddManagerAction implements Action {
  readonly type = ADD_MANAGER

  constructor(public payload: { user: HubtypeUser; project: HubtypeProject }) {}
}

export class DeleteManagerAction implements Action {
  readonly type = DELETE_MANAGER

  constructor(public payload: { user: HubtypeUser; project: HubtypeProject }) {}
}

export class AddAgentAction implements Action {
  readonly type = ADD_AGENT

  constructor(public payload: { user: HubtypeUser; queue: HubtypeQueue }) {}
}

export class DeleteAgentAction implements Action {
  readonly type = DELETE_AGENT

  constructor(public payload: { user: HubtypeUser; queue: HubtypeQueue }) {}
}

export class ChangeStatusAgentAction implements Action {
  readonly type = CHANGE_STATUS_AGENT

  constructor(public payload: HubtypeUser) {}
}

export class AddProviderAccountAction implements Action {
  readonly type = ADD_PROVIDER_ACCOUNT

  constructor(public payload: HubtypeProviderAccount) {}
}

export class UpdateProviderAccountAction implements Action {
  readonly type = UPDATE_PROVIDER_ACCOUNT

  constructor(public payload: HubtypeProviderAccount) {}
}

export class DeleteProviderAccountAction implements Action {
  readonly type = DELETE_PROVIDER_ACCOUNT

  constructor(public payload: HubtypeProviderAccount) {}
}

export class UpdateNameAction implements Action {
  readonly type = UPDATE_NAME

  constructor(public payload: string) {}
}

/**
 * Export a type alias of all actions in this action group
 * so that reducers can easily compose action types
 */
export type Actions =
  | SetOrganizationAction
  | UpdateOrganizationAction
  | AddProjectAction
  | DeleteProjectAction
  | SetProjectAction
  | UpdateProjectAction
  | AddTemplateFolderAction
  | EditTemplateFolderAction
  | DeleteTemplateFolderAction
  | AddTemplateAction
  | DeleteTemplateAction
  | SetTemplateAction
  | UpdateTemplateAction
  | AddQueueAction
  | DeleteQueueAction
  | SetAllQueueAction
  | SetQueueAction
  | UpdateQueueAction
  | UpdateQueueCounters
  | UpdateAllQueuesCounters
  | AddUserAction
  | UpdateUserAction
  | DeleteUserAction
  | AddManagerAction
  | DeleteManagerAction
  | AddAgentAction
  | DeleteAgentAction
  | ChangeStatusAgentAction
  | AddProviderAccountAction
  | UpdateProviderAccountAction
  | DeleteProviderAccountAction
  | UpdateNameAction
