<div class="empty-case-container">
  <div class="empty-case">
    <div class="empty-case-text">
      <div class="text-body bold">{{ title }}</div>
      <div class="text-body-small">{{ subtitle }}</div>
      <ng-content></ng-content>
    </div>
    <div *ngIf="icon !== ''"><i [ngClass]="icon"></i></div>
  </div>
  <button ht-button-primary *ngIf="buttonText" (click)="buttonClick.emit()">
    {{ buttonText }} <i class="fa-solid" [ngClass]="buttonIcon"></i>
  </button>
</div>
