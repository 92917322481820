import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Optional,
  Output,
  Self,
} from '@angular/core'
import { ControlValueAccessor, NgControl } from '@angular/forms'
import { ErrorMessages, getMessageErrors } from 'models/form'

export enum CheckboxStyle {
  Default = 'default',
  Tick = 'tick',
  Hyphen = 'hyphen',
}

@Component({
  selector: 'ht-checkbox',
  templateUrl: './ht-checkbox.component.html',
  styleUrls: ['./ht-checkbox.component.scss'],
})
export class HtCheckboxComponent implements ControlValueAccessor, OnInit {
  @HostBinding('attr.aria-label') @Input() label?: string

  @Input() customErrors: ErrorMessages = {}
  @Input() disabled?: boolean
  @Input() noErrorMessage = true
  @Input() tabIndex?: number = 0
  @Input() type: 'checkbox' | 'button' = 'checkbox'
  @Input() style = CheckboxStyle.Default

  @Output() onchange = new EventEmitter<boolean>()

  _checked = false
  error = ''

  constructor(@Self() @Optional() private control: NgControl) {
    if (this.control) {
      this.control.valueAccessor = this
    }
  }

  @HostBinding('class.has-error') get getHasError() {
    return (
      this.control &&
      !this.control.valid &&
      this.control.errors &&
      this.control.touched
    )
  }

  @Input()
  set checked(value) {
    this._checked = value
    this.onChange(value)
    this.onchange.emit(value)
  }

  get checked() {
    return this._checked
  }

  get showError(): boolean {
    if (this.control && !this.noErrorMessage) {
      const hasError =
        !this.control.valid && this.control.errors && this.control.touched
      if (hasError) {
        this.error = getMessageErrors(this.control.errors, this.customErrors)
      }

      return hasError
    }
    return false
  }

  ngOnInit() {}

  onChange: any = () => {}

  onTouched: any = () => {}

  registerOnChange(onChange: any) {
    this.onChange = onChange
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled
  }

  writeValue(checked: boolean) {
    if (checked !== null) {
      this._checked = checked
    }
  }
}
