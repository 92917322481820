<div class="ht-notification" [attr.aria-label]="data.message">
  <div *ngIf="showIcon" class="ht-not-type" [ngClass]="data.type">
    <mat-icon>{{ icon }}</mat-icon>
  </div>
  <div class="ht-not-msg">
    {{ data.message }}
  </div>
  <div *ngIf="data.buttonConfig">
    <button
      role="button"
      type="button"
      class="feedback-action"
      (click)="data.buttonConfig.onButtonActionClick()"
    >
      {{ data.buttonConfig.buttonText }}
    </button>
  </div>

  <div class="ht-not-close">
    <button
      mat-icon-button
      aria-label="Close notification"
      (click)="onDismiss()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
