import { Directive, OnDestroy } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { Observable, Subject } from 'rxjs'
import { first, takeUntil } from 'rxjs/operators'

@Directive()
export abstract class AngularComponent implements OnDestroy {
  protected ngUnsubscribe: Subject<void> = new Subject<void>()

  ngOnDestroy(): void {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }

  /**
   * Typically called from ngOnInit() to react to changes in store until component is destroyed
   */
  subscribeUntilDestroy<T, R>(
    obs: Observable<T>,
    callback: (T) => R,
    error?: (error: any) => void,
    complete?: () => void
  ) {
    return obs
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(callback, error, complete)
  }

  /**
   * Reacts to the result of a function returning an Observable with a single value
   */
  subscribeFirst<T, R>(
    obs: Observable<T>,
    callback: (T) => R,
    error?: (error: any) => void,
    complete?: () => void
  ) {
    return obs.pipe(first()).subscribe(callback, error, complete)
  }

  backRoute(router: Router, activeRoute: ActivatedRoute) {
    router.navigate(['../'], { relativeTo: activeRoute })
  }
}
