import { JsonObject, JsonProperty } from 'json2typescript'
import { HubtypeCase } from 'models/hubtype-case'

@JsonObject
export class TransferQueueData {
  @JsonProperty('id', String, true)
  public id?: string = undefined
  @JsonProperty('name', String, true)
  public name?: string = undefined
  @JsonProperty('project', String, true)
  public project?: string = undefined
}

@JsonObject
export class TransferUserData {
  @JsonProperty('id', String, true)
  public id?: string = undefined
  @JsonProperty('name', String, true)
  public name?: string = undefined
  @JsonProperty('pic', String, true)
  public pic?: string = undefined
}

@JsonObject
export class HubtypeTransfer {
  @JsonProperty('prev_queue', TransferQueueData, true)
  public prev_queue?: TransferQueueData = undefined
  @JsonProperty('next_queue', TransferQueueData, true)
  public next_queue?: TransferQueueData = undefined
  @JsonProperty('prev_user', TransferUserData, true)
  public prev_user?: TransferUserData = undefined
  @JsonProperty('next_user', TransferUserData, true)
  public next_user?: TransferUserData = undefined
  @JsonProperty('executor_user', TransferUserData, true)
  public executor_user?: TransferUserData = undefined
  @JsonProperty('case', HubtypeCase, true)
  public case?: HubtypeCase = undefined
}
