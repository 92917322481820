import { JsonObject, JsonProperty } from 'json2typescript'

@JsonObject
export class HubtypeCasesCount {
  @JsonProperty('attending_count', Number, true)
  public attending_count?: number = undefined
  @JsonProperty('idle_count', Number, true)
  public idle_count?: number = undefined
  @JsonProperty('waiting_count', Number, true)
  public waiting_count?: number = undefined
}
