import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button'
import { MatDividerModule } from '@angular/material/divider'
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input'
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu'
import { NgxMatIntlTelInputComponent } from './ngx-mat-intl-tel-input.component'
import { SearchPipe } from './search.pipe'

@NgModule({
  declarations: [NgxMatIntlTelInputComponent, SearchPipe],
  imports: [
    CommonModule,
    FormsModule,
    MatInputModule,
    MatMenuModule,
    MatButtonModule,
    MatDividerModule,
    ReactiveFormsModule,
  ],
  exports: [NgxMatIntlTelInputComponent],
})
export class NgxMatIntlTelInputModule {}
