import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { AuthGuard } from '../services/auth-guard.service'
import { AdminRoleGuard } from '../services/role-guard.service'
import { AppleIntegrationComponent } from './apple-integration.component'

const routes: Routes = [
  {
    path: 'integrations/apple',
    component: AppleIntegrationComponent,
    canActivate: [AuthGuard, AdminRoleGuard],
  },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ProviderIntegrationRoutingModule {}
