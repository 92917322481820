import {
  Directive,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core'
import { createPopper, Instance, Placement } from '@popperjs/core'
import { fromEvent, merge, Subject } from 'rxjs'
import { filter, map, takeUntil } from 'rxjs/operators'

export type TooltipEventTrigger = 'hover' | 'click'

@Directive({
  selector: '[tooltip], [tooltipOnClick]',
})
export class TooltipDirective implements OnInit, OnDestroy {
  // Its positioning (check docs for available options)
  @Input() tooltipPlacement?: Placement
  @Input() tooltipDismissTimeout?: number
  @Input() tooltipEventTrigger?: TooltipEventTrigger
  // The popper instance
  private popper: Instance
  private readonly defaultConfig = {
    placement: 'top' as Placement,
    removeOnDestroy: true,
    eventsEnabled: false,
  }

  private readonly destroy$ = new Subject<void>()
  private tooltipElement

  private _tooltip
  private _tooltipOnClick
  private _tooltipEventTrigger

  private readonly TOOLTIP_DISMISS_TIMEOUT = 1000

  constructor(
    private readonly el: ElementRef,
    private readonly renderer: Renderer2
  ) {}

  // The text to display
  @Input('tooltip')
  set value(value: string) {
    this._tooltip = value
  }

  // Text to display on click only
  @Input()
  set tooltipOnClick(value: string) {
    this._tooltipOnClick = value
  }

  ngOnInit(): void {
    if (!this._tooltip && !this._tooltipOnClick) {
      return
    }

    this.initializeTooltip()

    merge(
      fromEvent(this.el.nativeElement, 'mouseenter'),
      fromEvent(this.el.nativeElement, 'mouseleave'),
      fromEvent(this.el.nativeElement, 'click')
    )
      .pipe(
        filter(() => this.popper !== null),
        map(e => (e as Event).type),
        takeUntil(this.destroy$)
      )
      .subscribe((e: string) => {
        if (e === 'mouseenter' && this.tooltipEventTrigger === 'hover') {
          this.updateTooltipText(this._tooltip)
          this.openTooltip()
        } else if (e === 'mouseleave' && this.tooltipEventTrigger === 'hover') {
          this.closeTooltip()
        } else if (
          e === 'click' &&
          (this.tooltipEventTrigger === 'click' || this._tooltipOnClick)
        ) {
          this.updateTooltipText(this._tooltipOnClick || this._tooltip)
          this.openTooltipOnClick()
        }
      })
  }

  ngOnDestroy(): void {
    if (!this.popper) {
      return
    }

    this.popper.destroy()

    this.destroy$.next()
    this.destroy$.complete()
  }

  private initializeTooltip(): void {
    this.tooltipEventTrigger =
      this.tooltipEventTrigger || (this._tooltip ? 'hover' : 'click')
    this._tooltipEventTrigger = this.tooltipEventTrigger

    // An element to position the text relative to the element to hover
    this.tooltipElement = document.createElement('div')
    this.tooltipElement.classList.add('ht-tooltip')
    this.updateTooltipText(this._tooltip || this._tooltipOnClick)

    this.el.nativeElement.appendChild(this.tooltipElement)
    this.popper = createPopper(this.el.nativeElement, this.tooltipElement, {
      ...this.defaultConfig,
      placement: this.tooltipPlacement || this.defaultConfig.placement,
    })

    this.renderer.setStyle(this.tooltipElement, 'display', 'none')
    this.renderer.setStyle(this.tooltipElement, 'z-index', '101')
    this.renderer.setStyle(this.tooltipElement, 'text-wrap', 'wrap')
  }

  private updateTooltipText(text: string): void {
    if (text) {
      this.tooltipElement.innerHTML = text
      this.tooltipElement.style.visibility = 'visible'
    } else {
      this.tooltipElement.style.visibility = 'hidden'
    }
  }

  private openTooltip(): void {
    this.renderer.removeStyle(this.tooltipElement, 'display')
    this.popper.update()
  }

  private openTooltipOnClick(): void {
    this.openTooltip()

    this.tooltipEventTrigger = 'click'

    setTimeout(() => {
      this.closeTooltip()
      this.tooltipEventTrigger = this._tooltipEventTrigger
    }, this.tooltipDismissTimeout || this.TOOLTIP_DISMISS_TIMEOUT)
  }

  private closeTooltip(): void {
    this.renderer.setStyle(this.tooltipElement, 'display', 'none')
  }
}
