import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { NoAuthPageComponent } from 'shared/no-auth-page/no-auth-page.component'
import { LoginComponent } from './login/login.component'
import { ResetPwdComponent } from './reset-pwd/reset-pwd.component'
import { SignupComponent } from './signup/signup.component'

const routes: Routes = [
  { path: 'sign-in', component: LoginComponent },
  {
    path: '',
    component: NoAuthPageComponent,
    children: [
      { path: 'reset-pwd', component: ResetPwdComponent },
      { path: 'signup', component: SignupComponent },
    ],
  },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
