<div class="left-column">
  <hubtype-logo href="https://www.hubtype.com/"></hubtype-logo>

  <section class="login">
    <header>
      <h3>Hi!</h3>
      <h4>Connect with your clients no matter the channel</h4>
    </header>
    <login-form></login-form>
  </section>
  <div class="app-version-wrapper">
    <app-version></app-version>
  </div>
</div>
<div class="right-column">
  <div class="spacer"></div>
  <div class="claims">
    <h1>Welcome to Hubtype Dashboard</h1>
    <h2>
      Hubtype's cloud-based helpdesk lets you handle multiple tickets at once
      and maximize the efficiencies messaging channels offer.
    </h2>
  </div>
  <div class="image-wrapper">
    <img src="assets/images/hubtype-dashboard-inbox.png" />
  </div>
</div>
