import { animate, style, transition, trigger } from '@angular/animations'
import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import { Store } from '@ngrx/store'
import { Feedback } from 'models/feedback'
import {
  CaseSyncData,
  CaseSyncDataList,
  HubtypeCase,
} from 'models/hubtype-case'
import { filter, first, forkJoin, map, Observable } from 'rxjs'
import { AnalyticsEvents } from 'src/app/constants/analytics'
import { FeedbackService } from 'src/app/services/feedback.service'
import { CaseService } from 'src/app/services/hubtype-api/case.service'
import {
  ConnectionStatus,
  PusherService,
} from 'src/app/services/pusher.service'
import { Analytics } from 'utils/analytics'
import * as fromRoot from '../../reducers'
import * as fromRootDesk from '../../reducers/desk.state'

const SHOW_RECONNECTED_ALERT_ON_REFRESH_KEY =
  'hubtype:reconnection-alert-on-refresh'

@Component({
  selector: 'pusher-status-handler',
  templateUrl: './pusher-status-handler.component.html',
  styleUrls: ['./pusher-status-handler.component.scss'],
  animations: [
    trigger('slideIn', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(45px)' }),
        animate('200ms ease-in', style({ opacity: 1, transform: 'none' })),
      ]),
    ]),
    trigger('slideOut', [
      transition(':leave', [
        animate(
          '200ms ease-out',
          style({ opacity: 0, transform: 'translateY(45px)' })
        ),
      ]),
    ]),
  ],
})
export class PusherStatusHandlerComponent implements OnInit {
  pusherConnected = true
  currentlyOnFLowbuilder = false
  showConnectionProblem = false
  showConnectionRecovered = false
  pusherLastConnectionDownTime: number = null
  pusherStatusActions = {
    [ConnectionStatus.LOST]: () => {
      this.pusherLastConnectionDownTime = Date.now()
      this.pusherConnected = false
      this.updateShowConnectionProblemStatus()
      Analytics.event(AnalyticsEvents.PUSHER_CONNECTION_LOST) //This event shouldn't be able to be tracked because you are offline when triggered. Just to confrm that this is happening.
    },
    [ConnectionStatus.RECONNECTED]: () => {
      const downtime = this.getDowntimeInSeconds()
      this.pusherLastConnectionDownTime = null
      this.pusherConnected = true
      Analytics.event(AnalyticsEvents.PUSHER_CONNECTION_RECONNECTED, {
        downtime,
      })
      this.checkDataUnsyncAfterReconnect().subscribe({
        next: dataUnsyncProblem => {
          if (dataUnsyncProblem && !this.currentlyOnFLowbuilder) {
            this.showUnsyncDataFeedback()
          }
          this.updateShowConnectionProblemStatus()
        },
        error: () => {
          if (!this.currentlyOnFLowbuilder) {
            this.showUnsyncDataFeedback()
          }
          this.updateShowConnectionProblemStatus()
        },
      })
    },
  }

  constructor(
    private store: Store<fromRoot.State>,
    private feedbackService: FeedbackService,
    @Inject('pusherService') private pusherService: PusherService,
    @Inject('caseService') private caseService: CaseService,
    private router: Router,
    private ref: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.subscribeToPusherStatus()
    this.handleReconnectionAlertOnRefresh()
    this.subscribeToUrlChange()
    this.updateShowConnectionProblemStatus()
  }

  refresh() {
    this.setShownReconnectionAlertOnRefresh('true')
    Analytics.event(AnalyticsEvents.PUSHER_CONNECTION_RECONNECTED_REFRESH_CLICK)
    setTimeout(() => window.location.reload(), 1000)
  }

  closeConnectionRecoveredAlert() {
    this.showConnectionRecovered = false
  }

  private showUnsyncDataFeedback() {
    this.showConnectionRecovered = true
  }

  private updateShowConnectionProblemStatus(): void {
    this.showConnectionProblem =
      !this.currentlyOnFLowbuilder && !this.pusherConnected
    this.ref.detectChanges()
  }

  private subscribeToUrlChange() {
    this.currentlyOnFLowbuilder = this.amIOnFlowbuilder(this.router.url)
    this.router.events
      .pipe(filter(ev => ev instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.currentlyOnFLowbuilder = this.amIOnFlowbuilder(event.url)
        this.updateShowConnectionProblemStatus()
      })
  }

  private amIOnFlowbuilder(url: string): boolean {
    const FLOWBUILDER_URL_PATTERN = /^\/bots\/[a-f0-9-]+\/flowbuilder$/
    return FLOWBUILDER_URL_PATTERN.test(url)
  }

  private handleReconnectionAlertOnRefresh() {
    const showAlert = this.getShownReconnectionAlertOnRefresh()
    if (showAlert === 'true') {
      this.setShownReconnectionAlertOnRefresh('false')
      this.feedbackService.send(
        new Feedback(
          'All data is now synced.',
          'success',
          null,
          null,
          null,
          10000
        )
      )
    }
  }

  private getStoredCasesSyncDataList(): Observable<CaseSyncDataList> {
    function converCaseToCaseSyncData(caze: HubtypeCase): CaseSyncData {
      return {
        id: caze.id,
        lastMessageId: caze.last_message?.[0]?.id,
      }
    }

    return forkJoin([
      this.store.select(fromRootDesk.getAttendingList).pipe(first()),
      this.store.select(fromRootDesk.getWaitingList).pipe(first()),
      this.store.select(fromRootDesk.getIdleList).pipe(first()),
    ]).pipe(
      map(
        ([attendingCases, waitingCases, idleCases]: [
          HubtypeCase[],
          HubtypeCase[],
          HubtypeCase[],
        ]) => ({
          attending: attendingCases.map(converCaseToCaseSyncData),
          waiting: waitingCases.map(converCaseToCaseSyncData),
          idle: idleCases.map(converCaseToCaseSyncData),
        })
      )
    )
  }

  private checkDataUnsyncAfterReconnect(): Observable<boolean> {
    const sortCasesSyncDataListByCaseId = (
      casesSyncDataList: CaseSyncDataList
    ): CaseSyncDataList => ({
      attending: casesSyncDataList.attending.sort((a, b) =>
        a.id.localeCompare(b.id)
      ),
      idle: casesSyncDataList.idle.sort((a, b) => a.id.localeCompare(b.id)),
      waiting: casesSyncDataList.waiting.sort((a, b) =>
        a.id.localeCompare(b.id)
      ),
    })

    return forkJoin([
      this.getStoredCasesSyncDataList(),
      this.caseService.getBackendAgentCasesStatus(),
    ]).pipe(
      map(([storedState, backendState]) => {
        const storedStateString = JSON.stringify(
          sortCasesSyncDataListByCaseId(storedState)
        )
        const backendStateString = JSON.stringify(
          sortCasesSyncDataListByCaseId(backendState)
        )
        const changed = storedStateString !== backendStateString

        if (changed) {
          Analytics.event(AnalyticsEvents.CASES_DATA_UNSYNC_ERROR, {
            frontendStatus: storedStateString,
            backendStatus: backendStateString,
          })
        }

        return changed
      })
    )
  }

  private setShownReconnectionAlertOnRefresh(value: 'true' | 'false') {
    localStorage.setItem(SHOW_RECONNECTED_ALERT_ON_REFRESH_KEY, value)
  }

  private getShownReconnectionAlertOnRefresh() {
    return localStorage.getItem(SHOW_RECONNECTED_ALERT_ON_REFRESH_KEY)
  }

  private getDowntimeInSeconds() {
    return (
      Math.round((Date.now() - this.pusherLastConnectionDownTime) / 10) / 100
    )
  }

  private subscribeToPusherStatus() {
    this.pusherService.status.subscribe(status => {
      if (this.pusherStatusActions[status]) {
        this.pusherStatusActions[status]()
      }
    })
  }
}
