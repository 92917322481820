import { Any, JsonObject, JsonProperty } from 'json2typescript'
import { Task } from 'shared/entities/task'

// Reduceable version of Task entity, which must have all properties as public
@JsonObject
export class RunnableTask {
  @JsonProperty('id', String, true)
  public id?: string = undefined
  @JsonProperty('name', String, true)
  public name?: string = undefined
  @JsonProperty('error', String, true)
  public error?: string = undefined
  @JsonProperty('meta', Any, true)
  public meta?: any = undefined
  @JsonProperty('startedAt', Number, true)
  public startedAt?: number = undefined
  @JsonProperty('finishedAt', Number, true)
  public finishedAt?: number = undefined

  static fromTaskEntity(task: Task): RunnableTask {
    const runnable = new RunnableTask()
    runnable.id = task.taskId
    runnable.name = task.getName()
    runnable.meta = task.getMeta()
    runnable.error = task.getError()
    runnable.startedAt = task.startedTimestamp
    runnable.finishedAt = task.finishedTimestap
    return runnable
  }

  static toTaskEntity(runnable: RunnableTask): Task {
    const task = new Task(runnable.name, runnable.meta, false)
    task.setId(runnable.id)
    task.setStarted(runnable.startedAt)
    task.setFinished(runnable.finishedAt)
    task.setError(runnable.error)
    return task
  }

  get hasError(): boolean {
    return Boolean(this.error)
  }

  get isFinished(): boolean {
    return Boolean(this.finishedAt)
  }
}
