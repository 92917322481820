import { Any, JsonObject, JsonProperty } from 'json2typescript'
import { HubtypeProviderAccount } from './hubtype-provider-account'
import { CampaignTag, HubtypeContactTag, HubtypeTag } from './hubtype-tag'
import { HubtypeUser } from './hubtype-user'

@JsonObject
export class HubtypeChat {
  @JsonProperty('chat_tags', Any, true)
  public chat_tags: HubtypeContactTag[] = []
  @JsonProperty('created_at', Any, true)
  public created_at: any = undefined // TODO CHANGE TO DATE OBJECT
  @JsonProperty('enduser', HubtypeUser, true)
  public enduser: HubtypeUser = undefined
  @JsonProperty('id', String, true)
  public id: string = undefined
  @JsonProperty('is_banned', Boolean, true)
  public is_banned: boolean = undefined
  @JsonProperty('is_blocked', Boolean, true)
  public is_blocked: boolean = undefined
  @JsonProperty('is_online', Boolean, true)
  public is_online?: boolean = undefined
  @JsonProperty('last_message_received', Any, true)
  public last_message_received: any = undefined
  @JsonProperty('last_online_status_changed_at', String, true)
  public last_online_status_changed_at: string = undefined
  @JsonProperty('name', String, true)
  public name: string = undefined
  @JsonProperty('pic', String, true)
  public pic: string = undefined // TODO ¿STRING?
  @JsonProperty('provider', String, true)
  public provider: string = undefined
  @JsonProperty('provider_account', HubtypeProviderAccount, true)
  public provider_account?: HubtypeProviderAccount = undefined
  @JsonProperty('provider_id', String, true)
  public provider_id: string = undefined
  @JsonProperty('username', String, true)
  public username: string = undefined

  constructor() {}

  public get additionalInfoTag(): HubtypeContactTag {
    // Temporary solution to show extra information comming from tags
    return this.chat_tags.filter(
      chatTag => chatTag.tag.name === HubtypeTag.ADDITIONAL_INFO_TAG_NAME
    )[0]
  }

  public get campaignTags(): CampaignTag[] {
    return this.chat_tags.map(tag => HubtypeContactTag.toCampaignTag(tag))
  }

  public get isApple(): boolean {
    return this.provider === HubtypeProviderAccount.APPLE
  }

  public get isFacebook(): boolean {
    return this.provider === HubtypeProviderAccount.FACEBOOK
  }

  public get isGeneric(): boolean {
    return this.provider === HubtypeProviderAccount.GENERIC
  }

  public get isInstagram(): boolean {
    return this.provider === HubtypeProviderAccount.INSTAGRAM
  }

  public get isTelegram(): boolean {
    return this.provider === HubtypeProviderAccount.TELEGRAM
  }

  public get isTwitter(): boolean {
    return this.provider === HubtypeProviderAccount.TWITTER
  }

  public get isWebchat(): boolean {
    return this.provider === HubtypeProviderAccount.WEBCHAT
  }

  public get isWhatsapp(): boolean {
    return this.provider === HubtypeProviderAccount.WHATSAPP
  }

  public getName(): string {
    return this.isApple ? 'Apple User' : this.name
  }
}
