<div
  *ngIf="versionInfo"
  class="text-small-extra app-version break-word"
  data-role="app-version"
>
  <a
    class="link-version"
    *ngIf="!versionInfo.isOutdated"
    matTooltip="Click to check now if there is a new version."
    (click)="checkIsOutdated()"
    >{{ versionInfo?.localVersion }}<mat-icon>check</mat-icon>
  </a>
  <a
    class="link-version"
    *ngIf="versionInfo.isOutdated"
    matTooltip="There is a new version of the application ({{
      versionInfo.latestVersion
    }}). Click to update it."
    (click)="updateApp()"
    >{{ versionInfo?.localVersion }}<mat-icon>warning</mat-icon>
  </a>
</div>
