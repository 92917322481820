/* eslint-disable @typescript-eslint/naming-convention */
import { JsonConvert, ValueCheckingMode } from 'json2typescript'
import * as auth from '../actions/auth'
import * as organization from '../actions/organization'
import { HubtypeFolder } from '../models/hubtype-folder'
import { HubtypeOrganization } from '../models/hubtype-organization'
import { HubtypeProject } from '../models/hubtype-project'
import { HubtypeProviderAccount } from '../models/hubtype-provider-account'
import { HubtypeQueue } from '../models/hubtype-queue'
import { HubtypeTemplate } from '../models/hubtype-template'
import { HubtypeUser } from '../models/hubtype-user'
import { OrganizationState } from './organization.state'

const jsonConverter: JsonConvert = new JsonConvert()
jsonConverter.valueCheckingMode = ValueCheckingMode.ALLOW_NULL

export const initialState: OrganizationState = {
  organization: null,
  projects: [],
  queues: [],
  users: [],
  template_folders: [],
  templates: [],
  settings: {
    selectedProject: null,
    selectedQueue: null,
  },
}

export function deserialize(json: OrganizationState): OrganizationState {
  return {
    organization: json.organization
      ? jsonConverter.deserializeObject(json.organization, HubtypeOrganization)
      : null,
    projects: json.projects
      ? jsonConverter.deserializeArray(json.projects, HubtypeProject)
      : [],
    queues: json.projects
      ? jsonConverter.deserializeArray(json.queues, HubtypeQueue)
      : [],
    users: json.users
      ? jsonConverter.deserializeArray(json.users, HubtypeUser)
      : [],
    template_folders: json.template_folders
      ? jsonConverter.deserializeArray(json.template_folders, HubtypeFolder)
      : [],
    templates: json.templates
      ? jsonConverter.deserializeArray(json.templates, HubtypeTemplate)
      : [],
    settings: {
      selectedQueue: json.settings.selectedQueue
        ? jsonConverter.deserializeObject(
            json.settings.selectedQueue,
            HubtypeQueue
          )
        : null,
      selectedProject: json.settings.selectedProject
        ? jsonConverter.deserializeObject(
            json.settings.selectedProject,
            HubtypeProject
          )
        : null,
    },
  }
}

export function reducer(
  state = initialState,
  action: organization.Actions | auth.Actions
): OrganizationState {
  let projects
  let queues
  let queue: HubtypeQueue
  switch (action.type) {
    case auth.LOGOUT:
      return initialState

    case organization.SET_ORGANIZATION:
      queues = action.payload.organization.projects.map(p =>
        jsonConverter.deserializeArray(p.queues, HubtypeQueue)
      )
      return {
        ...state,
        organization: action.payload.organization.simplified,
        projects: jsonConverter.deserializeArray(
          action.payload.organization.projects,
          HubtypeProject
        ),
        queues: [].concat(...queues),
        users: jsonConverter.deserializeArray(
          action.payload.organization.users,
          HubtypeUser
        ),
        template_folders: jsonConverter.deserializeArray(
          action.payload.organization.template_folders,
          HubtypeFolder
        ),
        templates: jsonConverter.deserializeArray(
          action.payload.organization.templates,
          HubtypeTemplate
        ),
      }

    case organization.UPDATE_ORGANIZATION:
      return {
        ...state,
        organization: action.payload,
      }

    case organization.ADD_PROJECT:
    case organization.UPDATE_PROJECT:
      return {
        ...state,
        projects: addUpdateDeleteObjectInArray(
          state.projects,
          jsonConverter.deserializeObject(action.payload, HubtypeProject),
          true
        ),
      }

    case organization.DELETE_PROJECT:
      return {
        ...state,
        projects: addUpdateDeleteObjectInArray(
          state.projects,
          jsonConverter.deserializeObject(action.payload, HubtypeProject),
          false
        ),
      }

    case organization.SET_PROJECT:
      return {
        ...state,
        settings: {
          ...state.settings,
          selectedProject: action.payload,
        },
      }

    case organization.ADD_TEMPLATE_FOLDER:
      return {
        ...state,
        template_folders: [...state.template_folders, action.payload],
      }
    case organization.EDIT_TEMPLATE_FOLDER:
      const folderIndex = state.template_folders.findIndex(
        tf => tf.id === action.payload.id
      )
      const template_folders = [...state.template_folders]
      template_folders[folderIndex] = action.payload
      return { ...state, template_folders }
    case organization.DELETE_TEMPLATE_FOLDER:
      const indexFolder = state.template_folders.findIndex(
        tf => tf.id === action.payload.id
      )
      const folders = [...state.template_folders]
      folders.splice(indexFolder, 1)
      folders.map(f => {
        if (f.parent === action.payload.id) {
          f.parent = undefined
        }
        return f
      })
      const templates = [...state.templates]
      templates.map(t => {
        if (t.folder === action.payload.id) {
          t.folder = undefined
        }
        return t
      })
      return { ...state, template_folders: folders, templates }

    case organization.ADD_TEMPLATE:
    case organization.UPDATE_TEMPLATE:
      return {
        ...state,
        templates: addUpdateDeleteObjectInArray(
          state.templates,
          jsonConverter.deserializeObject(action.payload, HubtypeTemplate),
          true
        ),
      }

    case organization.DELETE_TEMPLATE:
      return {
        ...state,
        templates: addUpdateDeleteObjectInArray(
          state.templates,
          jsonConverter.deserializeObject(action.payload, HubtypeTemplate),
          false
        ),
      }

    case organization.ADD_QUEUE:
      queue = jsonConverter.deserializeObject(action.payload, HubtypeQueue)
      return updateStateWithQueue(state, queue, true)

    case organization.UPDATE_QUEUE:
      if (!state.queues.some(queueItem => queueItem.id === action.payload.id)) {
        return state
      }
      queue = jsonConverter.deserializeObject(action.payload, HubtypeQueue)
      return updateStateWithQueue(state, queue, true)

    case organization.DELETE_QUEUE:
      queue = jsonConverter.deserializeObject(action.payload, HubtypeQueue)
      return updateStateWithQueue(state, queue, false)

    case organization.SET_ALL_QUEUES:
      return {
        ...state,
        queues: action.payload,
      }

    case organization.SET_QUEUE:
      return {
        ...state,
        settings: {
          ...state.settings,
          selectedQueue: action.payload,
          selectedProject: action.payload
            ? state.projects.find(p => p.id === action.payload.project_id)
            : null,
        },
      }

    case organization.ADD_USER:
    case organization.UPDATE_USER:
      return {
        ...state,
        users: addUpdateDeleteObjectInArray(
          state.users,
          jsonConverter.deserializeObject(action.payload, HubtypeUser)
        ),
      }

    case organization.DELETE_USER:
      return {
        ...state,
        users: addUpdateDeleteObjectInArray(
          state.users,
          jsonConverter.deserializeObject(action.payload, HubtypeUser),
          false
        ),
      }

    case organization.ADD_MANAGER:
    case organization.DELETE_MANAGER:
      projects = addUpdateDeleteObjectInArray(
        action.payload.user.projects,
        action.payload.project,
        action.type === organization.ADD_MANAGER
      )
      return {
        ...state,
        users: addUpdateDeleteObjectInArray(
          state.users,
          jsonConverter.deserializeObject(
            { ...action.payload.user, projects },
            HubtypeUser
          )
        ),
      }

    case organization.ADD_AGENT:
    case organization.DELETE_AGENT:
      queues = addUpdateDeleteObjectInArray(
        action.payload.user.queues,
        action.payload.queue,
        action.type === organization.ADD_AGENT
      )
      return {
        ...state,
        users: addUpdateDeleteObjectInArray(
          state.users,
          jsonConverter.deserializeObject(
            { ...action.payload.user, queues },
            HubtypeUser
          )
        ),
      }

    case organization.CHANGE_STATUS_AGENT:
      return {
        ...state,
        users: addUpdateDeleteObjectInArray(
          state.users,
          jsonConverter.deserializeObject(action.payload, HubtypeUser)
        ),
      }

    case organization.ADD_PROVIDER_ACCOUNT:
    case organization.UPDATE_PROVIDER_ACCOUNT:
    case organization.DELETE_PROVIDER_ACCOUNT:
      queue = state.queues.find(q => q.id === action.payload.queue_id)
      if (!queue) {
        return state
      }
      const provider_accounts = addUpdateDeleteObjectInArray(
        queue.provider_accounts,
        jsonConverter.deserializeObject(action.payload, HubtypeProviderAccount),
        action.type === organization.ADD_PROVIDER_ACCOUNT
      )
      return {
        ...state,
        queues: addUpdateDeleteObjectInArray(
          state.queues,
          jsonConverter.deserializeObject(
            { ...queue, provider_accounts },
            HubtypeQueue
          )
        ),
      }
    case organization.UPDATE_QUEUE_COUNTERS:
      const index = state.queues.map(q => q.id).indexOf(action.payload.queue_id)
      return {
        ...state,
        queues: [
          ...state.queues.slice(0, index),
          jsonConverter.deserializeObject(
            {
              ...state.queues[index],
              waiting_cases_count: action.payload.waiting_cases_count,
              idle_cases_count: action.payload.idle_cases_count,
              attending_cases_count: action.payload.attending_cases_count,
              max_waiting_time: action.payload.max_waiting_time,
            },
            HubtypeQueue
          ),
          ...state.queues.slice(index + 1),
        ],
      }
    case organization.UPDATE_ALL_QUEUES_COUNTERS:
      const queuesUpdatedCounters = state.queues.map(queueToUpdateCounter => {
        const counterToUpdate = action.payload.find(
          counterToUpdate =>
            counterToUpdate.queue_id === queueToUpdateCounter.id
        )
        if (counterToUpdate) {
          queueToUpdateCounter.attending_cases_count =
            counterToUpdate.attending_cases_count
          queueToUpdateCounter.idle_cases_count =
            counterToUpdate.idle_cases_count
          queueToUpdateCounter.waiting_cases_count =
            counterToUpdate.waiting_cases_count
        }
        return queueToUpdateCounter
      })
      return { ...state, queues: queuesUpdatedCounters }
    case organization.UPDATE_NAME:
      return {
        ...state,
        organization: jsonConverter.deserializeObject(
          { ...state.organization, name: action.payload },
          HubtypeOrganization
        ),
      }
    default:
      return state
  }
}
function updateStateWithQueue(
  stateToUpdate: OrganizationState,
  queueToUpdate: HubtypeQueue,
  add: boolean = false
) {
  let selectedQueueObject = getUpdatedSelectedQueue(
    stateToUpdate,
    queueToUpdate,
    add
  )

  let projectWithUpdatedQueue = getUpdatedProjectQueues(
    stateToUpdate,
    queueToUpdate,
    add
  )
  let selectedProjectWithUpdatedQueue = getUpdatedSelectedProject(
    projectWithUpdatedQueue,
    stateToUpdate
  )

  let projectListWithUpdatedProject = getUpdatedProjectList(
    projectWithUpdatedQueue,
    stateToUpdate
  )
  let queueListUpdated = addUpdateDeleteObjectInArray(
    stateToUpdate.queues,
    queueToUpdate,
    add
  )

  return {
    ...stateToUpdate,
    projects: projectListWithUpdatedProject,
    queues: queueListUpdated,
    settings: {
      ...stateToUpdate.settings,
      ...selectedQueueObject,
      ...selectedProjectWithUpdatedQueue,
    },
  }
}

function getUpdatedSelectedQueue(
  stateToUpdate: OrganizationState,
  queueToUpdate: HubtypeQueue,
  add: boolean
): { selectedQueue: HubtypeQueue } {
  if (add) {
    return stateToUpdate.settings?.selectedQueue?.id === queueToUpdate.id
      ? { selectedQueue: queueToUpdate }
      : { selectedQueue: stateToUpdate.settings?.selectedQueue }
  } else {
    return stateToUpdate.settings?.selectedQueue?.id === queueToUpdate.id
      ? { selectedQueue: null }
      : { selectedQueue: stateToUpdate.settings?.selectedQueue }
  }
}

function getUpdatedProjectList(
  projectWithUpdatedQueues: HubtypeProject,
  stateToUpdate: OrganizationState
): HubtypeProject[] {
  return projectWithUpdatedQueues
    ? addUpdateDeleteObjectInArray(
        stateToUpdate.projects,
        projectWithUpdatedQueues
      )
    : stateToUpdate.projects
}

function getUpdatedSelectedProject(
  projectWithUpdatedQueues: HubtypeProject,
  stateToUpdate: OrganizationState
): { selectedProject: HubtypeProject } {
  if (stateToUpdate.settings.selectedProject) {
    return projectWithUpdatedQueues &&
      stateToUpdate.settings.selectedProject.id === projectWithUpdatedQueues.id
      ? { selectedProject: projectWithUpdatedQueues }
      : { selectedProject: stateToUpdate.settings.selectedProject }
  } else {
    return { selectedProject: null }
  }
}

function getUpdatedProjectQueues(
  stateToUpdate: OrganizationState,
  queueToUpdate: HubtypeQueue | undefined,
  add: boolean = true
): HubtypeProject | undefined {
  if (queueToUpdate) {
    const projectToUpdate = stateToUpdate.projects.find(
      x => x.id === queueToUpdate.project_id
    )
    if (projectToUpdate) {
      let projectToUpdateCopy = jsonConverter.deserializeObject(
        projectToUpdate,
        HubtypeProject
      )

      if (projectToUpdateCopy) {
        projectToUpdateCopy.queues = projectToUpdateCopy.queues || []

        projectToUpdateCopy.queues = addUpdateDeleteObjectInArray(
          projectToUpdateCopy.queues,
          queueToUpdate,
          add
        )

        return projectToUpdateCopy
      }
    }
  }
}
function addUpdateDeleteObjectInArray(
  array: any[],
  object: any,
  add: boolean = true
) {
  const index = array.map(i => i.id).indexOf(object.id)
  if (add && index === -1) {
    return [...array, object]
  }
  if (!add && index === -1) {
    return [...array]
  } else if (add && index !== -1) {
    return [...array.slice(0, index), object, ...array.slice(index + 1)]
  } else if (!add && index !== -1) {
    return [...array.slice(0, index), ...array.slice(index + 1)]
  }
}
