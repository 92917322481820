import { JsonProperty } from 'json2typescript'
import { replaceSpecialCharacters } from '../utils/text-utils'
import { WhatsAppHeaderFormat } from './facebook-whatsapp-template'
import { HubtypeTemplateVariable } from './hubtype-template-variable'

export abstract class AnyHubtypeTemplate {
  static readonly PLACEHOLDER_VARIABLES_REGEX = /{{\d+}}/g

  @JsonProperty('id', String, true)
  public id?: string = undefined

  @JsonProperty('name', String, false)
  public name?: string = undefined

  @JsonProperty('namespace', String, true)
  public namespace?: string = undefined

  public isWhatsApp: boolean
  public variablesCount = -1

  constructor() {}

  get varCount(): number {
    if (this.variablesCount >= 0) {
      return this.variablesCount
    }
    this.variablesCount = [this.getHeader(), this.getSourceBody()].reduce(
      (acc, content) => {
        const varsInPart =
          content.match(AnyHubtypeTemplate.PLACEHOLDER_VARIABLES_REGEX)
            ?.length || 0
        return acc + varsInPart
      },
      0
    )
    return this.variablesCount
  }

  contains(lookup: string): boolean {
    if (!lookup) {
      return true
    }

    const name = this.name || ''
    const text = this.getSourceBody() || ''
    const lowerLookup = replaceSpecialCharacters(lookup.toLowerCase())
    return (
      replaceSpecialCharacters(name.toLowerCase()).includes(lowerLookup) ||
      replaceSpecialCharacters(text.toLowerCase()).includes(lowerLookup)
    )
  }

  formatBody(variables: HubtypeTemplateVariable[]): string {
    let formatted = this.getBody()
    const matcher = formatted.match(
      AnyHubtypeTemplate.PLACEHOLDER_VARIABLES_REGEX
    )

    if (!matcher) {
      return formatted
    }
    matcher.forEach((placeholder, index) => {
      formatted = formatted.replace(placeholder, variables[index].value)
    })
    return formatted
  }

  abstract get icon(): string
  abstract getBody(): string
  abstract getButtons(): TemplateButton[]
  abstract getFooter(): string
  abstract getHeader(): string
  abstract getLanguage(): string
  abstract getMediaFileNameFromUrl(url: string): string
  abstract getMediaHeader(): WhatsAppHeaderFormat
  abstract getSourceBody(): string
  abstract getVariable(index: number): HubtypeTemplateVariable
  abstract isCampaignsSelectable(): boolean
  abstract isOwnedMediaFileFromUrl(url: string): boolean
}

export interface TemplateButton {
  type: string
  value: string
}
