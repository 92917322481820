import { Injectable, OnDestroy } from '@angular/core'
import { Router } from '@angular/router'
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core'
import { Store } from '@ngrx/store'
import moment from 'moment'
import { Subject } from 'rxjs'
import { filter, first, takeUntil } from 'rxjs/operators'
import { LogoutAction } from '../actions/auth'
import * as fromRoot from '../reducers'

@Injectable()
export class IdleGuardService implements OnDestroy {
  public LOCAL_STORAGE_IDLE_KEY = 'startIdleDateTime'
  private logoutSubject: Subject<void> = new Subject<void>()

  constructor(
    private router: Router,
    private store: Store<fromRoot.State>,
    private idle: Idle
  ) {}

  handleUserIdle() {
    this.verifyIdleByLocalStorage()
    this.store.select(fromRoot.isLoggedIn).subscribe(isLoggedIn => {
      if (isLoggedIn) {
        this.store
          .select(fromRoot.getOrganization)
          .pipe(filter(Boolean), first())
          .subscribe(({ idle_duration }) => {
            if (idle_duration) {
              this.idle.setIdle(1) // Set to 1 second to logg the last time the user started idle
              this.idle.setTimeout(moment.duration(idle_duration).asSeconds())
              this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES)
              this.startLoggingOnUserStartIdle()
              this.logoutOnUserIdle()
              this.idle.watch()
            }
          })
      } else {
        this.idle.stop()
        this.logoutSubject.next()
      }
    })
  }

  private shouldLogOutUser(
    lastLoggedIdleDateTime: moment.Moment,
    idleLimit: string
  ) {
    const currentDateTime = moment()
    const secondsSinceUserIdle = moment
      .duration(currentDateTime.diff(lastLoggedIdleDateTime))
      .asSeconds()
    const secondsIdleLimit = moment.duration(idleLimit).asSeconds()
    return idleLimit && secondsSinceUserIdle > secondsIdleLimit
  }

  private getLastLoggedIdleDateTime = () => {
    if (window.localStorage) {
      const lastLoggedIdleDateTimeString = window.localStorage.getItem(
        this.LOCAL_STORAGE_IDLE_KEY
      )
      if (lastLoggedIdleDateTimeString) {
        return moment(lastLoggedIdleDateTimeString)
      }
    }
    return null
  }

  private startLoggingOnUserStartIdle() {
    this.idle.onIdleStart.pipe(takeUntil(this.logoutSubject)).subscribe(() => {
      if (window.localStorage) {
        window.localStorage.setItem(
          this.LOCAL_STORAGE_IDLE_KEY,
          moment().format()
        )
      }
    })
  }

  private logoutOnUserIdle() {
    this.store
      .select(fromRoot.isLoggedIn)
      .pipe(first())
      .subscribe(isLoggedIn => {
        if (isLoggedIn) {
          this.idle.onTimeout.pipe(first()).subscribe(() => {
            this.store.dispatch(new LogoutAction())
            this.router.navigate(['/sign-in'])
          })
        }
      })
  }

  public verifyIdleByLocalStorage() {
    this.store
      .select(fromRoot.isLoggedIn)
      .pipe(first())
      .subscribe(isLoggedIn => {
        const lastLoggedIdleDateTime = this.getLastLoggedIdleDateTime()
        if (isLoggedIn && lastLoggedIdleDateTime) {
          this.store
            .select(fromRoot.getOrganization)
            .pipe(filter(Boolean), first())
            .subscribe(({ idle_duration }) => {
              if (
                this.shouldLogOutUser(lastLoggedIdleDateTime, idle_duration)
              ) {
                this.store.dispatch(new LogoutAction())
                this.router.navigate(['/sign-in'])
              }
            })
        }
      })
  }

  ngOnDestroy() {
    this.logoutSubject.complete()
  }
}
