import { Pipe, PipeTransform } from '@angular/core'
import moment from 'moment-timezone'
import { formattedTimezone } from 'utils/time-utils'

export const TIMEZONES_DATA = moment.tz.names().map(timezone => ({
  id: timezone,
  value: formattedTimezone(timezone),
}))

@Pipe({
  name: 'timezone',
})
export class TimezonePipe implements PipeTransform {
  transform(value: string): any {
    return TIMEZONES_DATA.find(t => t.id === value)?.value || 'Not defined'
  }
}
