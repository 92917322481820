import { Inject, Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import {
  HubtypeBasicQueue,
  HubtypeListQueue,
  HubtypeQueue,
  HubtypeQueueCounter,
} from 'models/hubtype-queue'
import { HubtypeUser } from 'models/hubtype-user'
import { forkJoin, Observable, of } from 'rxjs'
import { first, map, mergeMap, share, switchMap } from 'rxjs/operators'
import {
  SetAllQueueAction,
  UpdateAllQueuesCounters,
} from '../../actions/organization'
import * as fromRoot from '../../reducers'
import { ConverterService } from '../converter.service'
import { HubtypeApiService } from './hubtype-api.service'

const QUEUE_FETCH_LIMIT = 100
const sortQueues = HubtypeBasicQueue.sort

interface PageableQueues {
  count: number
  next: number | null
  data: HubtypeBasicQueue[]
}

@Injectable()
export class QueueService {
  constructor(
    protected store: Store<fromRoot.State>,
    @Inject('apiService') private apiService: HubtypeApiService,
    @Inject('convertService') private convertService: ConverterService
  ) {}

  // GET: /queues/{queue.id}
  get(queue_id: string): Observable<HubtypeQueue> {
    return this.apiService
      .get(`/queues/${queue_id}/`)
      .pipe(
        map(response =>
          this.convertService.jsonConvert.deserializeObject(
            response,
            HubtypeQueue
          )
        )
      )
    // map(response => plainToClass<HubtypeQueue,Object>(HubtypeQueue,response));
  }

  getAll(forceSync = false): Observable<HubtypeQueue[]> {
    if (forceSync) {
      return this.fetchAll()
    }
    return this.store.select(fromRoot.getQueues).pipe(
      switchMap(queues => {
        if (queues && queues.length > 0) {
          return of(queues)
        }
        return this.fetchAll()
      })
    )
  }

  getAllByProject(projectId: string): Observable<HubtypeListQueue[]> {
    return this.store.select(fromRoot.getQueuesOfProject(projectId))
  }

  //TODO: We should remove this and use getAllByProject. But in order to do so, projects v2 should return queues with schedule settings and subquery for number ot agents as in /projects/${projectId}/queues/
  getAllByProjectFromBackend(
    projectId: string
  ): Observable<HubtypeListQueue[]> {
    return this.apiService
      .get(`/projects/${projectId}/queues/`)
      .pipe(
        map(queues =>
          queues.map(queue =>
            this.convertService.jsonConvert.deserializeObject(
              queue,
              HubtypeListQueue
            )
          )
        )
      )
  }

  getAllTransferable(
    projectId: string,
    forceSync = false
  ): Observable<HubtypeBasicQueue[]> {
    return this.store.select(fromRoot.getOrganization).pipe(
      first(Boolean),
      mergeMap(org => {
        const allQueuesVisibility = org.allow_transfer_all_projects
        if (!allQueuesVisibility && !forceSync) {
          return this.store
            .select(fromRoot.getTransferableQueues(projectId))
            .pipe(map(queues => queues.sort(sortQueues)))
        }
        // Incremental fetch as backend restricts the batch fetch
        return this.incrementalFetchQueues(allQueuesVisibility, true)
      })
    )
  }

  // GET: /queues/{queue.id}
  getCounters(queue_id: string): Observable<any> {
    return this.apiService.get(`/queues/${queue_id}/`, {
      fields:
        'waiting_cases_count,max_waiting_time,attending_cases_count,idle_cases_count',
    })
  }

  updateAllCounters(): Observable<HubtypeQueueCounter[]> {
    return this.apiService.get(`/queues/counters/`).pipe(
      first(),
      map(counters => {
        this.store.dispatch(new UpdateAllQueuesCounters(counters))
        return counters
      })
    )
  }

  // DELETE: /queues/{queue.id}
  remove(queue: HubtypeQueue): Observable<any> {
    return this.apiService.delete(
      `/queues/${queue.id}/`,
      null,
      null,
      'v1',
      false
    )
  }

  // POST: /queues/`
  add(queue: HubtypeQueue): Observable<HubtypeQueue> {
    const body = queue
    return this.apiService
      .post(`/queues/`, body, null, 'v1', false)
      .pipe(
        map(response =>
          this.convertService.jsonConvert.deserializeObject(
            response,
            HubtypeQueue
          )
        )
      )
  }

  update(queue: HubtypeQueue): Observable<HubtypeQueue> {
    const body = queue
    return this.apiService
      .put(`/queues/${queue.id}/`, body, 'v1', false)
      .pipe(
        map(response =>
          this.convertService.jsonConvert.deserializeObject(
            response,
            HubtypeQueue
          )
        )
      )
  }

  addAgents(
    queue: HubtypeQueue,
    agents: HubtypeUser[]
  ): Observable<{ manager_id: string; queue: HubtypeQueue }> {
    const agentsIds = agents.map(agent => agent.id)
    const body = { agent_id: agentsIds }
    return this.apiService.post(`/queues/${queue.id}/assign_agent/`, body)
  }

  deleteAgents(
    queue: HubtypeQueue,
    agents: HubtypeUser[]
  ): Observable<{ manager_id: string; queue: HubtypeQueue }> {
    const agentsIds = agents.map(agent => agent.id)
    const body = { agent_id: agentsIds }
    return this.apiService.post(`/queues/${queue.id}/unassign_agent/`, body)
  }

  private fetchAll(): Observable<HubtypeQueue[]> {
    return (
      this.incrementalFetchQueues(true, false) as Observable<HubtypeQueue[]>
    ).pipe(
      map(queues => {
        this.store.dispatch(new SetAllQueueAction(queues))
        return queues
      }),
      share(),
      switchMap(() => this.store.select(fromRoot.getQueues))
    )
  }

  private incrementalFetchQueues(
    allQueuesVisibility: boolean,
    isBasicQueue: boolean
  ): Observable<HubtypeQueue[] | HubtypeBasicQueue[]> {
    const fields = isBasicQueue ? 'id,name,project_name,project_id' : undefined
    return this.fetchQueues(
      1,
      allQueuesVisibility,
      QUEUE_FETCH_LIMIT,
      fields
    ).pipe(
      first(),
      map(pageable => ({
        iterations: Math.ceil(pageable.count / QUEUE_FETCH_LIMIT),
        data: pageable.data,
      })),
      // TODO: This should be able to be improved by using `expand` operator
      mergeMap(firstResult => {
        if (firstResult.iterations - 1 <= 0) {
          return of([firstResult.data])
        }

        const observable$ = new Array(firstResult.iterations)
        observable$[0] = of(firstResult.data)
        for (let i = 1; i < firstResult.iterations; i++) {
          observable$[i] = this.fetchQueues(
            i + 1,
            allQueuesVisibility,
            QUEUE_FETCH_LIMIT,
            fields
          ).pipe(map(result => result.data))
        }
        return forkJoin(observable$)
      }),
      map((result: HubtypeQueue[][]) =>
        result.reduce((acc: HubtypeQueue[], page: HubtypeQueue[]) => {
          acc.push(...page)
          return acc.sort(sortQueues)
        }, [])
      )
    )
  }

  private fetchQueues(
    page = 1,
    allQueuesVisibility = false,
    pageSize = 10,
    fields?: string
  ): Observable<PageableQueues> {
    const urlParams: { [key: string]: string | boolean | number } = {
      page,
      page_size: pageSize,
    }
    if (allQueuesVisibility) {
      urlParams.all_projects = true
    }
    if (fields) {
      urlParams.fields = fields
    }
    return this.apiService.get(`/queues/`, urlParams).pipe(
      first(),
      map(response => ({
        count: response.count,
        next: response.next ? Number(response.next.slice(-1)) : null,
        data: response.results,
      }))
    )
  }
}
