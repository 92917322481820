<div class="container">
  <i *ngIf="icon" class="fa-solid" [ngClass]="icon"></i>
  <input
    [placeholder]="placeholder"
    [disabled]="disabled"
    [value]="value"
    [ngClass]="icon ? '' : 'not-icon'"
    [ngStyle]="{ width: width ? width + 'px' : '100%' }"
    [type]="type"
    [required]="required"
    (input)="onInput($event.target.value)"
    [attr.aria-label]="label"
    [min]="min"
    [max]="max"
    *ngIf="!isTextArea"
    (blur)="handleBlur($event.target.value)"
  />

  <textarea
    [placeholder]="placeholder"
    [disabled]="disabled"
    [value]="value"
    [ngClass]="icon ? '' : 'not-icon'"
    type="text"
    (input)="onInput($event.target.value)"
    [attr.aria-label]="label"
    *ngIf="isTextArea"
  ></textarea>
  <div class="error-message" *ngIf="showError && error">
    {{ error }}
  </div>
  <div class="help-text" *ngIf="helpText">
    {{ helpText }}
  </div>
</div>
