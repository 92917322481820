import { Injectable } from '@angular/core'
import { FilterCriteria } from '../entities/filter-criteria'
import { SharedDataModule } from '../shared-data.module'

@Injectable()
export class FilterService {
  filter(data: unknown[], criteria: FilterCriteria): unknown[] {
    return data.filter(item => criteria.meets(item))
  }
}
