export function sortByProperty<T>(
  list: T[],
  possibleProperties: string[],
  mode: string = 'asc'
): T[] {
  if (!list) {
    return list
  }
  const propertyName = selectSortingProperty(list, possibleProperties)
  const dir = mode === 'asc' ? 1 : -1
  return list.sort((a, b) =>
    a[propertyName] > b[propertyName] ? 1 * dir : -1 * dir
  )
}
export function selectSortingProperty<T>(
  list: T[],
  possibleProperties: string[]
) {
  if (!possibleProperties) {
    return ''
  }
  let sortingProperty
  possibleProperties.forEach(prop => {
    if (!list.find(e => e[prop] === undefined) && !sortingProperty) {
      sortingProperty = prop
    }
  })
  return sortingProperty || ''
}

interface ItemWithId {
  id: string
}

export function updateObjectListById<T extends ItemWithId>(
  list: T[],
  updatedItem: T
): T[] {
  return list.map(item => {
    if (item.id === updatedItem.id) {
      return updatedItem
    }
    return item
  })
}

/** return single array of arrays */
export function flatMap(arr) {
  return arr.reduce((acc, val) => acc.concat(val), [])
}

export function getUniqueArray(arr: string[]) {
  return [...new Set(arr)]
}

export function arrayToCSVFormat(data: any[][]) {
  let csvFile = ''
  data.forEach(row => (csvFile += rowToCSV(row)))
  return csvFile
}

function rowToCSV(row: any[]) {
  let csvData = ''
  row.forEach((data, i) => {
    const innerValue = data === null ? '' : data.toString()
    let result = innerValue.replace(/"/g, '""')
    if (result.search(/("|,|\n)/g) >= 0) {
      result = '"' + result + '"'
    }
    if (i > 0) {
      csvData += ','
    }
    csvData += result
  })
  return csvData + '\n'
}

export function arraysContainCommonObjectbyKey(
  arr1: any[],
  arr2: any[],
  key: string
): boolean {
  const set = new Set(arr1.map(x => x[key]))
  for (const element of arr2.map(y => y[key])) {
    if (set.has(element)) {
      return true
    }
  }
  return false
}

export function arrayRemoveDuplicatesByKey(dataArray, key) {
  const unique = new Map()
  dataArray.forEach(item => {
    if (!unique.has(item[key])) {
      unique.set(item[key], item)
    }
  })
  return Array.from(unique.values())
}
