import { HubtypeUser } from '../models/hubtype-user'
import { OAuth } from '../models/oauth'

export interface State {
  readonly is_logged_in: boolean
  readonly oauth: OAuth
  readonly me: HubtypeUser | null
  readonly error: any | null
  readonly pusherConnectionStablished: boolean
}

export const initialState: State = Object.freeze({
  is_logged_in: false,
  oauth: null,
  me: null,
  error: null,
  pusherConnectionStablished: false,
})
