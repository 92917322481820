import {
  AfterViewChecked,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core'
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidatorFn,
} from '@angular/forms'
import { Observable } from 'rxjs'
import {
  RIGHT_SIDE_VERTICAL_PADDING,
  TABLE_FOOTER_HEIGHT,
} from 'src/app/constants'

export interface TableColumnInterface {
  row: TableRow
  column?: Column
  isEditing?: boolean
}

export type ActionDeactivated = boolean | ((value: any) => boolean)
export interface Action {
  label: string
  icon?: string
  onClick: (data: any, row: TableRow) => any
  isDeactivated?: ActionDeactivated
  hasVisibleLabel?: boolean
}
export interface Tag {
  name: string
  amount: number
}
export interface Column {
  name: string
  type?:
    | 'string'
    | 'tag'
    | 'dropdown'
    | 'profileAvatar'
    | 'truncated'
    | 'time'
    | 'datetime'
    | 'icon'
  key?: string
  class?: string | string[] | Set<string> | { [klass: string]: any }
  textAlign?: 'start' | 'end' | 'center'
  width?: string
  tooltip?: string
  placeholder?: string
  isDeactivated?: boolean
  component?: any
  canBeCopied?: boolean

  /**
   * Optional type==='string'
   * */
  computedLabel?: (value: any) => string
  /**
   * Required type==='dropdown'
   * */
  dropDownList?: { id: string; value: string }[]
  validators?: ValidatorFn[]
}

export interface TableConfig {
  columns: Column[]
  pagination?: {
    enabled: boolean
    serverSide: boolean
    pageSize: number
    total?: number
    currentPage?: number
    onPageChangeServer?: (page: any) => void
  }
  fillAvailableHeight?: boolean
  hasBulkActions?: boolean
  actions?: Action[]
  expandable?: boolean | ((value: any) => boolean)

  onSaveOnRow?: (line: {
    [otherOptions: string]: unknown
    id: string
  }) => Observable<any>
  onRowClick?: (line: any) => void
  onRowSelected?: (Any) => void
  isLoading?: boolean
  emptyCase?: {
    title: string
    subtitle: string
    buttonText: string
    onButtonClick: (event: any) => void
  }
  selectable?: boolean
  clickable?: boolean
  hideHeader?: boolean
  singleOption?: boolean
}

export interface RowData {
  id: string
  children?: RowDataChildren[]
  [otherOptions: string]: unknown
}

export interface RowDataChildren {
  id: string
  [otherOptions: string]: unknown
}

export interface TableRow {
  id: string
  value: any
  formGroup: UntypedFormGroup
  children?: any[]
  expandable?: boolean
  isExpanded?: boolean
  toggleEdit: () => void
}

export const DEFAULT_ACTIONS = {
  EDIT: {
    label: 'Edit',
    icon: 'fa-pen-to-square',
  },
  EDIT_IN_ROW: {
    label: 'Edit',
    icon: 'fa-pen-to-square',
    onClick: (item, row: TableRow) => {
      row.toggleEdit()
    },
  },
  DELETE: {
    label: 'Delete',
    icon: 'fa-trash-can',
  },
}

export const SELECTED_CONTROL_NAME = 'selected'
@Component({
  selector: 'ht-table',
  templateUrl: './ht-table.component.html',
  styleUrls: ['./ht-table.component.scss'],
})
export class HtTableComponent implements AfterViewChecked, OnChanges {
  @ContentChild('subRowsTemplate') subRowsTemplate: TemplateRef<any> | undefined

  @ViewChild('wrapper') wrapper: ElementRef

  @Input()
  config: TableConfig = {
    columns: [],
  }
  @Input()
  selectedIds: string[] = []
  @Input()
  activeRow: any
  @Input()
  disabledIds: string[] = []
  @Output()
  selectedIdsChange = new EventEmitter<any[]>()

  @Input() expandedRowsIds: string[] = []
  @Output() expandedRowsIdsChange = new EventEmitter<string[]>()

  @Input()
  get data(): RowData[] {
    return this.pData
  }
  set data(value) {
    this.pData = value
  }

  tableId = `table-${Math.random()}`
  editId: string
  form: UntypedFormGroup = new UntypedFormGroup({
    rows: new UntypedFormArray([]),
  })
  formEdit: UntypedFormGroup
  rows: TableRow[] = []
  hasSelectedRows = false

  private defaultObj = { id: null }
  private pData: RowData[]

  constructor(private formBuilder: UntypedFormBuilder) {}

  ngAfterViewChecked(): void {
    this.resizeTable()
  }

  ngOnChanges(): void {
    this.updateRows()
    this.updateHasSelectedRows()
  }

  onResize() {
    this.resizeTable()
  }

  onPageChange(page: number) {
    if (
      this.config.pagination.serverSide &&
      this.config.pagination.onPageChangeServer
    ) {
      this.config.pagination.onPageChangeServer(page)
    }

    this.config.pagination.currentPage = page
  }

  addNewItem(defaultItem?) {
    //If now is edit mode
    if (this.isCreatingNewItem()) {
      return
    }
    if (!this.data) {
      this.data = []
    }
    this.data.unshift({ ...defaultItem, ...this.defaultObj })
    this.editId = null
    this.updateRows()

    const rows: any = this.form.controls.rows
    this.formEdit = rows.controls[0] //We know the first control is the new form control
  }

  onRowClick(rowData: any) {
    if (this.config.selectable) {
      this.toggleSelectedRow(rowData.id)
      if (this.config.onRowSelected) {
        this.config.onRowSelected(this.selectedIds)
      }
    }
    if (this.config.onRowClick) {
      this.config.onRowClick(rowData)
    }
  }

  updateHasSelectedRows = () => {
    this.hasSelectedRows = !!this.selectedIds.length
  }

  onToggleRowExpand(row: TableRow) {
    if (row.isExpanded) {
      this.expandedRowsIds = [...new Set([...this.expandedRowsIds, row.id])]
    } else {
      this.expandedRowsIds = this.expandedRowsIds.filter(id => id !== row.id)
    }
  }

  getSelectedIds() {
    return this.form.value.rows
      .filter(({ selected }) => selected)
      .map(({ id }) => id)
  }

  //#region privateMethods
  private updateRows() {
    if (this.config && this.data) {
      const groups: UntypedFormGroup[] = []
      const rows: TableRow[] = []

      this.data.forEach(dataRow => {
        const newRow = this.generateRow(dataRow)
        rows.push(newRow)
        groups.push(newRow.formGroup)
      })

      this.form = this.formBuilder.group({
        rows: this.formBuilder.array(groups),
      })

      this.rows = rows
    }
  }

  private generateRow(dataRow: any): TableRow {
    const group = this.getGroup(dataRow)

    return {
      id: dataRow.id || null,
      children: this.generateChildrenRows(dataRow.children),
      value: dataRow,
      formGroup: group,
      expandable: this.getIsExpandable(dataRow),
      isExpanded: this.getIsExpanded(dataRow),
      toggleEdit: () => {
        const isUpdating = this.editId === dataRow.id
        this.editId = isUpdating ? undefined : dataRow.id
        this.formEdit = isUpdating ? group : undefined
      },
    }
  }

  private generateChildrenRows(childrenDataRows: any[]): TableRow[] {
    if (childrenDataRows) {
      return childrenDataRows.map(child => ({
        id: child.id,
        value: child,
        formGroup: new UntypedFormGroup({}), // TODO: form group for children not supported yet
        expandable: false,
        isExpanded: false,
        toggleEdit: () => {},
      }))
    }
  }

  private getIsExpandable(dataRow: any): boolean {
    if (typeof this.config.expandable === 'function') {
      return this.config.expandable(dataRow)
    } else {
      return this.config.expandable
    }
  }

  private getIsExpanded(dataRow: any): boolean {
    return this.config.expandable
      ? this.expandedRowsIds.includes(dataRow.id)
      : false
  }

  private getGroup(dataRow): UntypedFormGroup {
    const obj = {}
    this.config.columns.forEach(column => {
      if (column.key) {
        obj[column.key] = new UntypedFormControl(
          dataRow[column.key],
          column.validators
        )
      }
    })
    obj['id'] = new UntypedFormControl(dataRow.id)
    if (this.config.selectable) {
      const isSelected = this.selectedIds?.includes(dataRow.id)
      const isDisabled = this.disabledIds?.includes(dataRow.id)
      obj[SELECTED_CONTROL_NAME] = new UntypedFormControl({
        value: isSelected,
        disabled: isDisabled,
      })
    }

    return this.formBuilder.group(obj)
  }

  private isCreatingNewItem(): boolean {
    return this.editId === this.defaultObj.id
  }

  private toggleSelectedRow(rowId: string) {
    const index = this.selectedIds.indexOf(rowId)
    const isSelected = index > -1
    if (isSelected) {
      this.selectedIds.splice(index, 1)
    } else {
      if (this.config.singleOption) {
        this.selectedIds = []
        this.selectedIds.push(rowId)
      } else {
        this.selectedIds.push(rowId)
      }
    }
    this.updateHasSelectedRows()
    this.setSelectedRowValue(rowId, !isSelected)
  }

  private setSelectedRowValue(rowId: string, isSelected: boolean) {
    const rows: any = this.form.controls.rows
    const rowToToggle = rows.controls.find(row => row.value.id === rowId)
    rowToToggle.get(SELECTED_CONTROL_NAME).setValue(isSelected)
    if (this.config.singleOption && isSelected) {
      const rowsToUntoggle = rows.controls.filter(row => row.value.id !== rowId)
      rowsToUntoggle.map(row => row.get(SELECTED_CONTROL_NAME).setValue(false))
    }
  }

  private resizeTable() {
    if (this.config.fillAvailableHeight) {
      const tableHeight =
        window.innerHeight -
        this.wrapper.nativeElement.offsetTop -
        RIGHT_SIDE_VERTICAL_PADDING * 2 -
        (this.config.hasBulkActions && this.hasSelectedRows
          ? TABLE_FOOTER_HEIGHT - RIGHT_SIDE_VERTICAL_PADDING
          : 0)
      this.wrapper.nativeElement.style['max-height'] = `${tableHeight}px`
    }
  }
  //#endregion
}
