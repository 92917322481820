import { Inject, Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { JsonConvert } from 'json2typescript'
import { forkJoin, Observable } from 'rxjs'
import { first, map } from 'rxjs/operators'
import {
  AddTemplateAction,
  AddTemplateFolderAction,
  DeleteTemplateAction,
  DeleteTemplateFolderAction,
  EditTemplateFolderAction,
  UpdateTemplateAction,
} from '../../actions/organization'
import { TemplateFilterType } from '../../constants/templates'
import { HubtypeFolder } from '../../models/hubtype-folder'
import { HubtypeProviderAccount } from '../../models/hubtype-provider-account'
import { HubtypeTemplate } from '../../models/hubtype-template'
import { AnyHubtypeTemplate } from '../../models/hubtype-template-any'
import { TreeFolder } from '../../models/tree-folder'
import { getTemplateFolders, getTemplates, State } from '../../reducers'
import { ConverterService } from '../converter.service'
import { HubtypeApiService } from './hubtype-api.service'
import { ProviderAccountService } from './provider-account.service'

interface FolderPair {
  raw: HubtypeFolder
  folder: TreeFolder<HubtypeTemplate>
}

@Injectable()
export class TemplateService {
  constructor(
    @Inject('apiService') private apiService: HubtypeApiService,
    @Inject('convertService') private convertService: ConverterService,
    @Inject('providerAccountService')
    private providerAccountService: ProviderAccountService,
    private store: Store<State>
  ) {}
  jsonConverter: JsonConvert = new JsonConvert()
  // GET: /templates/{template.id}
  get(template_id: string): Observable<HubtypeTemplate> {
    return this.apiService
      .get(`/templates/${template_id}/`)
      .pipe(
        map(response =>
          this.convertService.jsonConvert.deserializeObject(
            response,
            HubtypeTemplate
          )
        )
      )
  }

  getAll(
    providerAccount: HubtypeProviderAccount
  ): Observable<AnyHubtypeTemplate[]> {
    if (!providerAccount || !providerAccount.isWhatsapp) {
      return this.store.select(getTemplates)
    }

    return forkJoin(
      this.store.select(getTemplates).pipe(first()),
      this.providerAccountService.getWhatsappTemplates(
        providerAccount.id,
        TemplateFilterType.ACTIVE
      )
    ).pipe(
      map(([orgTemplates, waTemplates]) => [...orgTemplates, ...waTemplates])
    )
  }

  getHubtypeTemplates(): Observable<HubtypeTemplate[]> {
    return this.store.select(getTemplates)
  }

  getTemplateFolders(): Observable<TreeFolder<HubtypeTemplate>[]> {
    return this.store
      .select(getTemplateFolders)
      .pipe(map(list => this.folderListToTree(list)))
  }

  // POST: /templates/`
  add(template: HubtypeTemplate): Observable<HubtypeTemplate> {
    const body = template
    return this.apiService.post(`/templates/`, body).pipe(
      map(response => {
        const result = this.convertService.jsonConvert.deserializeObject(
          response,
          HubtypeTemplate
        )
        this.store.dispatch(new AddTemplateAction(result))
        return result
      })
    )
  }

  // POST: /template_folders/`
  addFolder(folder: HubtypeFolder): Observable<HubtypeFolder> {
    const body = folder
    return this.apiService.post(`/template_folders/`, body).pipe(
      map(response => {
        const result = this.convertService.jsonConvert.deserializeObject(
          response,
          HubtypeFolder
        )
        this.store.dispatch(new AddTemplateFolderAction(result))
        return result
      })
    )
  }

  editFolder(folder: HubtypeFolder): Observable<HubtypeFolder> {
    const body = folder
    return this.apiService.patch(`/template_folders/${folder.id}/`, body).pipe(
      map(response => {
        const result = this.convertService.jsonConvert.deserializeObject(
          response,
          HubtypeFolder
        )
        this.store.dispatch(new EditTemplateFolderAction(result))
        return result
      })
    )
  }

  deleteFolder(folder: HubtypeFolder): Observable<HubtypeFolder> {
    return this.apiService.delete(`/template_folders/${folder.id}/`).pipe(
      map(response => {
        this.store.dispatch(new DeleteTemplateFolderAction(folder))
        return response
      })
    )
  }

  // DELETE: /templates/{template.id}
  remove(template: HubtypeTemplate): Observable<HubtypeTemplate> {
    return this.apiService.delete(`/templates/${template.id}/`).pipe(
      map(() => {
        const result = this.convertService.jsonConvert.deserializeObject(
          template,
          HubtypeTemplate
        )
        this.store.dispatch(new DeleteTemplateAction(result))
        return result
      })
    )
  }

  update(template: HubtypeTemplate): Observable<HubtypeTemplate> {
    const body = template
    return this.apiService.put(`/templates/${template.id}/`, body).pipe(
      map(response => {
        const result = this.convertService.jsonConvert.deserializeObject(
          response,
          HubtypeTemplate
        )
        this.store.dispatch(new UpdateTemplateAction(result))
        return result
      })
    )
  }

  folderListToTree(list: HubtypeFolder[]): TreeFolder<HubtypeTemplate>[] {
    const dictionary: { [name: string]: FolderPair } = {}
    list.forEach(f => {
      dictionary[f.id] = {
        raw: f,
        folder: new TreeFolder<any>(f),
      }
    })

    const folders: TreeFolder<any>[] = []
    Object.values(dictionary).forEach(item => {
      const raw = item.raw
      if (!raw.parent) {
        folders.push(item.folder)
        return
      }
      const parentFolder = dictionary[raw.parent].folder
      item.folder.setParent(parentFolder)
      item.folder.setPath(list, raw)
      parentFolder.addChild(item.folder)
    })
    return Object.values(folders).sort((f1, f2) => (f1.name > f2.name ? 1 : -1))
  }

  getAllpaginatedTemplates(): Observable<HubtypeTemplate[]> {
    const PAGE_SIZE = 100
    return this.apiService
      .getAllPaginatedResults(PAGE_SIZE, '/templates/', 'v1')
      .pipe(
        first(),
        map(allTemplates =>
          this.jsonConverter.deserializeArray(allTemplates, HubtypeTemplate)
        )
      )
  }
  getAllpaginatedTemplateFolders(): Observable<HubtypeFolder[]> {
    const PAGE_SIZE = 100
    return this.apiService
      .getAllPaginatedResults(PAGE_SIZE, '/template_folders/', 'v1')
      .pipe(
        first(),
        map(allTemplateFolders =>
          this.jsonConverter.deserializeArray(allTemplateFolders, HubtypeFolder)
        )
      )
  }
}
