/* eslint-disable @typescript-eslint/naming-convention */
import { JsonConvert, ValueCheckingMode } from 'json2typescript'
import { HubtypeCaseFilter } from 'models/hubtype-case-filter'
import {
  HubtypeCaseSort,
  SortDirection,
  SortType,
} from 'models/hubtype-case-sort'
import { HubtypeChat } from 'models/hubtype-chat'
import { HubtypeUser } from 'models/hubtype-user'
import * as auth from '../actions/auth'
import * as desk from '../actions/desk'
import * as organization from '../actions/organization'
import { ContactReasonWithCategory, HubtypeCase } from '../models/hubtype-case'
import { HubtypeMessage } from '../models/hubtype-message'
import {
  ArchiveCase,
  BackwardCompatCase,
} from '../services/hubtype-api/case-search.service.dtos'
import { CaseList, DEFAULT_FILTERS, DeskState } from './desk.state'

const jsonConverter: JsonConvert = new JsonConvert()
jsonConverter.valueCheckingMode = ValueCheckingMode.ALLOW_NULL

const ONE_SECOND_MILLIS = 1000

const updateCaseContactReasons = (
  c: HubtypeCase,
  caseId: string,
  contactReasons: ContactReasonWithCategory[]
) => {
  if (c.id === caseId) {
    c.contact_reasons = contactReasons
  }
  return c
}

export const initialState: DeskState = {
  inbox: {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    attending: [],
    idle: [],
    waiting: [],

    selectedCase: null,
    selectedCaseMessages: [],
    casesFilter: {
      status: [],
      channels: [],
      content: '',
      types: [],
    },
    casesSort: {
      sortBy: SortType.WAITING_TIME,
      sortDirection: SortDirection.DESC,
    },
    waitingCasesLoading: 0,
    closeCaseChatWindow: false,
    navbarCollapse: true,
    navbarFilters: [],
  },
  dashboard: {
    cases: [],
  },
  archive: {
    filters: { ...DEFAULT_FILTERS },
    cases: [],
    selectedCase: null,
    selectedCaseMessages: [],
    page: 0,
    prev: null,
    next: null,
  },
  reporting: {
    filters: { ...DEFAULT_FILTERS },
  },
}

export function deserialize(json: DeskState): DeskState {
  return {
    inbox: {
      attending:
        json.inbox && json.inbox.attending
          ? jsonConverter.deserializeArray(json.inbox.attending, HubtypeCase)
          : [],
      waiting:
        json.inbox && json.inbox.waiting
          ? jsonConverter.deserializeArray(json.inbox.waiting, HubtypeCase)
          : [],
      idle:
        json.inbox && json.inbox.idle
          ? jsonConverter.deserializeArray(json.inbox.idle, HubtypeCase)
          : [],
      selectedCase:
        json.inbox && json.inbox.selectedCase
          ? jsonConverter.deserializeObject(
              json.inbox.selectedCase,
              HubtypeCase
            )
          : null,
      selectedCaseMessages:
        json.inbox &&
        json.inbox.selectedCaseMessages &&
        json.inbox.selectedCaseMessages.length
          ? jsonConverter.deserializeArray(
              json.inbox.selectedCaseMessages,
              HubtypeMessage
            )
          : [],
      casesFilter:
        json.inbox && json.inbox.casesFilter
          ? jsonConverter.deserializeObject(
              json.inbox.casesFilter,
              HubtypeCaseFilter
            )
          : { status: [], types: [], channels: [], content: '' },
      casesSort:
        json.inbox && json.inbox.casesSort
          ? jsonConverter.deserializeObject(
              json.inbox.casesSort,
              HubtypeCaseSort
            )
          : {
              sortBy: SortType.WAITING_TIME,
              sortDirection: SortDirection.DESC,
            },
      waitingCasesLoading: json.inbox.waitingCasesLoading
        ? json.inbox.waitingCasesLoading
        : 0,
      closeCaseChatWindow: json.inbox.closeCaseChatWindow,
      navbarCollapse: json.inbox.navbarCollapse,
      navbarFilters: json.inbox.navbarFilters,
    },
    dashboard: {
      cases: [],
    },
    archive: {
      filters: json.archive ? json.archive.filters : { ...DEFAULT_FILTERS },
      cases:
        json.archive && json.archive.cases
          ? jsonConverter.deserializeArray(
              BackwardCompatCase.fromCases(json.archive.cases),
              ArchiveCase
            )
          : [],
      selectedCase:
        json.archive && json.archive.selectedCase
          ? jsonConverter.deserializeObject(
              BackwardCompatCase.fromCase(json.archive.selectedCase),
              ArchiveCase
            )
          : null,
      selectedCaseMessages:
        json.archive &&
        json.archive.selectedCaseMessages &&
        json.archive.selectedCaseMessages.length
          ? jsonConverter.deserializeArray(
              json.archive.selectedCaseMessages,
              HubtypeMessage
            )
          : [],
      page: json.archive ? json.archive.page : 0,
      prev: json.archive ? json.archive.prev : null,
      next: json.archive ? json.archive.next : null,
    },
    reporting: {
      filters: (json.reporting && json.reporting.filters) || {
        ...DEFAULT_FILTERS,
      },
    },
  }
}

function updateCounters(state: DeskState, action: desk.UpdateCounters) {
  // TODO is it worth?
  // const actionInbox = action.payload as typeof state.inbox
  // if (
  //   actionInbox.attending_count === state.inbox.attending_count &&
  //   actionInbox.idle_count === state.inbox.idle_count &&
  //   actionInbox.waiting_count === state.inbox.waiting_count
  // ) {
  //   return state
  // }

  return {
    ...state,
    inbox: {
      ...state.inbox,
      ...action.payload,
    },
  }
}

function getArchiveCase(state: DeskState, c: HubtypeCase) {
  let archiveCase = state.archive.cases.find(ac => ac.case.id === c.id)
  if (!archiveCase) {
    archiveCase = new ArchiveCase()
    archiveCase.case = c
  }
  return archiveCase
}
function removeDraftAndDuplicated(messages: HubtypeMessage[]) {
  const result: HubtypeMessage[] = []
  messages.forEach(mess => {
    if (
      (mess.is_draft &&
        messages.some(m => m.text === mess.text && !m.is_draft)) ||
      result.some(messageResult => messageResult.id === mess.id)
    ) {
      return
    }

    result.push(mess)
  })
  return result.sort((c1, c2) => (c1.created_at < c2.created_at ? -1 : +1))
}
export function reducer(
  state = initialState,
  action: auth.Actions | desk.Actions | organization.Actions
): DeskState {
  let case_: HubtypeCase
  switch (action.type) {
    case desk.SELECT_INBOX_CASE:
      if (!action.payload) {
        return {
          ...state,
          inbox: {
            ...state.inbox,
            selectedCase: null,
            selectedCaseMessages: [],
          },
        }
      }

      action.payload.unread_messages = 0
      return {
        ...state,
        inbox: {
          ...state.inbox,
          selectedCase: action.payload,
          selectedCaseMessages: [],
        },
      }

    case desk.ASSIGN_CASE_ME:
      if (state.inbox.attending.find(c => c.id === action.payload.id)) {
        return state
      }
      case_ = jsonConverter.deserializeObject(action.payload, HubtypeCase)
      //Remove unread messages...
      if (case_) {
        case_.unread_messages = 0
      }
      return {
        ...state,
        inbox: {
          ...state.inbox,
          attending: addCase(state.inbox.attending, case_, state.inbox.waiting),
          waiting: state.inbox.waiting.filter(c => c.id != case_.id),
          selectedCase: case_,
        },
      }

    case desk.UNASSIGN_CASE:
      return {
        ...state,
        inbox: {
          ...state.inbox,
          waiting: addCase(state.inbox.waiting, action.payload, [
            ...state.inbox.attending,
            ...state.inbox.idle,
          ]),
          attending: state.inbox.attending.filter(
            c => c.id != action.payload.id
          ),
          idle: state.inbox.idle.filter(c => c.id != action.payload.id),
          selectedCase: state.inbox.selectedCase
            ? state.inbox.selectedCase.id == action.payload.id
              ? action.payload
              : state.inbox.selectedCase
            : state.inbox.selectedCase,
        },
      }

    case desk.CASE_IDLE:
      return {
        ...state,
        inbox: {
          ...state.inbox,
          idle: addCase(
            state.inbox.idle,
            action.payload,
            state.inbox.attending
          ),
          attending: state.inbox.attending.filter(
            c => c.id != action.payload.id
          ),
          selectedCase: state.inbox.selectedCase
            ? state.inbox.selectedCase.id == action.payload.id
              ? action.payload
              : state.inbox.selectedCase
            : state.inbox.selectedCase,
        },
      }

    case desk.CASE_IDLE_OUT:
      return {
        ...state,
        inbox: {
          ...state.inbox,
          attending: addCase(
            state.inbox.attending,
            action.payload,
            state.inbox.idle
          ),
          idle: state.inbox.idle.filter(c => c.id != action.payload.id),
          selectedCase: state.inbox.selectedCase
            ? state.inbox.selectedCase.id == action.payload.id
              ? action.payload
              : state.inbox.selectedCase
            : state.inbox.selectedCase,
        },
      }

    case desk.ADD_DRAFT_MESSAGE:
      //Temporary disabled until to decide a solution to show draft safety
      return state

    // return {
    //   ...state,
    //   inbox: {
    //     ...state.inbox,
    //     selectedCaseMessages: [
    //       ...state.inbox.selectedCaseMessages,
    //       jsonConverter.deserializeObject(action.payload, HubtypeMessage),
    //     ],
    //   },
    //}
    case desk.NEW_ACK_MESSAGE_RECEIVED:
    case desk.MESSAGE_DELETED_RECEIVED:
      if (
        !action.payload ||
        state.inbox.selectedCase?.chat?.id !== action.payload.chat_id
      ) {
        return state
      }

      //Find message to update
      let messageToUpdate = state.inbox.selectedCaseMessages.find(
        message => message.id === action.payload.id
      )
      if (!messageToUpdate) {
        return state
      }
      //Clone message
      messageToUpdate = jsonConverter.deserializeObject(
        messageToUpdate,
        HubtypeMessage
      )
      if (action.type === desk.NEW_ACK_MESSAGE_RECEIVED) {
        messageToUpdate.ack = action.payload.ack
      } else {
        messageToUpdate.type = action.payload.type
        messageToUpdate.text = action.payload.text
        messageToUpdate.deleted_at = action.payload.deleted_at
      }

      const indexMessage = state.inbox.selectedCaseMessages
        .map(message => message.id)
        .indexOf(action.payload.id)

      //Update message to fire event on change
      state.inbox.selectedCaseMessages[indexMessage] = messageToUpdate

      return {
        ...state,
        inbox: {
          ...state.inbox,
          selectedCase: jsonConverter.deserializeObject(
            state.inbox.selectedCase,
            HubtypeCase
          ),
          selectedCaseMessages: state.inbox.selectedCaseMessages,
        },
      }

    case desk.NEW_MESSAGE_RECEIVED:
      if (!action.payload.case || !action.payload.message) {
        return state
      }

      const hcase = jsonConverter.deserializeObject(
        action.payload.case,
        HubtypeCase
      )

      const isMessageFromSelectedCase =
        state.inbox.selectedCase?.id === hcase.id

      const message = action.payload.message

      hcase.updateCaseFromLastMessage(message, isMessageFromSelectedCase)

      const selectedCase = isMessageFromSelectedCase
        ? hcase
        : state.inbox.selectedCase

      const updatedSelectedCaseMessages = isMessageFromSelectedCase
        ? removeDraftAndDuplicated([
            ...state.inbox.selectedCaseMessages,
            ...[message],
          ])
        : state.inbox.selectedCaseMessages

      let caseListToUpdate

      if (hcase.is_waiting) {
        caseListToUpdate = {
          waiting: setCase(state.inbox.waiting, hcase),
        }
      } else if (hcase.is_attending) {
        caseListToUpdate = {
          attending: setCase(state.inbox.attending, hcase),
        }
      } else if (hcase.is_idle) {
        caseListToUpdate = {
          idle: setCase(state.inbox.idle, hcase),
        }
      }
      if (caseListToUpdate) {
        return {
          ...state,
          inbox: {
            ...state.inbox,
            ...caseListToUpdate,
            selectedCase,
            selectedCaseMessages: updatedSelectedCaseMessages,
          },
        }
      } else {
        return state
      }

    case desk.LOAD_OLD_CHAT_MESSAGES:
      if (
        !action.payload.case ||
        !action.payload.messages ||
        !action.payload.messages.length ||
        !state.inbox.selectedCase ||
        state.inbox.selectedCase.chat.id !== action.payload.messages[0].chat_id
      ) {
        return state
      }

      const newMessages = action.payload.messages
      const selectedCaseMessages = state.inbox.selectedCaseMessages || []

      const fullMessages = removeDraftAndDuplicated([
        ...newMessages,
        ...selectedCaseMessages,
      ])

      return {
        ...state,
        inbox: {
          ...state.inbox,
          selectedCase: jsonConverter.deserializeObject(
            state.inbox.selectedCase,
            HubtypeCase
          ),
          selectedCaseMessages: fullMessages,
        },
      }

    case desk.NEW_CASE:
      if (action.payload.is_waiting) {
        if (state.inbox.waiting.find(c => c.id === action.payload.id)) {
          return state
        }
        return {
          ...state,
          inbox: {
            ...state.inbox,
            waiting: addCase(state.inbox.waiting, action.payload),
          },
        }
      } else if (action.payload.is_attending) {
        if (state.inbox.attending.find(c => c.id === action.payload.id)) {
          return state
        }
        if (state.inbox.waiting.find(c => c.id == action.payload.id)) {
          return {
            ...state,
            inbox: {
              ...state.inbox,
              attending: addCase(state.inbox.attending, action.payload),
              waiting: state.inbox.waiting.filter(
                c => c.id != action.payload.id
              ),
            },
          }
        }
        if (state.inbox.idle.find(c => c.id == action.payload.id)) {
          return {
            ...state,
            inbox: {
              ...state.inbox,
              attending: addCase(state.inbox.attending, action.payload),
              idle: state.inbox.idle.filter(c => c.id != action.payload.id),
            },
          }
        }
        return {
          ...state,
          inbox: {
            ...state.inbox,
            attending: addCase(state.inbox.attending, action.payload),
          },
        }
      }
      return state

    case desk.UPDATE_CASE_LIST:
      const me = action.payload.me
      const htCase = action.payload.case
      const attCases = removeCaseById(state.inbox.attending, htCase.id)
      const idlCases = removeCaseById(state.inbox.idle, htCase.id)
      const waitCases = removeCaseById(state.inbox.waiting, htCase.id)

      if (isCaseVisible(me, htCase)) {
        if (htCase.is_attending) {
          attCases.push(htCase)
        } else if (htCase.is_idle) {
          idlCases.push(htCase)
        } else {
          waitCases.push(htCase)
        }
      }

      return {
        ...state,
        inbox: {
          ...state.inbox,
          attending: attCases,
          idle: idlCases,
          waiting: waitCases,
          selectedCase: getSelectedCaseToUpdate(
            state.inbox.selectedCase,
            htCase,
            me
          ),
        },
        archive: {
          ...state.archive,
          selectedCase: getArchiveSelectedCaseToUpdate(
            state.archive?.selectedCase,
            htCase
          ),
        },
      }

    case desk.DISCARD_CASE:
      if (!state.inbox.waiting.find(c => c.id == action.payload.id)) {
        return state
      }
      return {
        ...state,
        inbox: {
          ...state.inbox,
          waiting: state.inbox.waiting.filter(c => c.id != action.payload.id),
          selectedCase: state.inbox.selectedCase
            ? state.inbox.selectedCase.id == action.payload.id
              ? null
              : state.inbox.selectedCase
            : state.inbox.selectedCase,
        },
      }

    case desk.UPDATE_COUNTERS:
      return updateCounters(state, action)

    case desk.RESOLVE_CASE:
      const r: HubtypeCase = [
        ...state.inbox.attending,
        ...state.inbox.idle,
      ].find(c => c.id == action.payload.id)
      if (!r) {
        return state
      }
      return {
        ...state,
        inbox: {
          ...state.inbox,
          attending: state.inbox.attending.filter(
            c => c.id != action.payload.id
          ),
          idle: state.inbox.idle.filter(c => c.id != action.payload.id),
          selectedCase: state.inbox.selectedCase
            ? state.inbox.selectedCase.id == action.payload.id
              ? null
              : state.inbox.selectedCase
            : state.inbox.selectedCase,
        },
      }
    case desk.BAN_USER:
      const s: HubtypeCase = [
        ...state.inbox.attending,
        ...state.inbox.idle,
      ].find(c => c.id == action.payload.id)
      return {
        ...state,
        inbox: {
          ...state.inbox,
          attending: state.inbox.attending.filter(
            c => c.id != action.payload.id
          ),
          idle: state.inbox.idle.filter(c => c.id != action.payload.id),
          selectedCase: state.inbox.selectedCase
            ? state.inbox.selectedCase.id == action.payload.id
              ? null
              : state.inbox.selectedCase
            : state.inbox.selectedCase,
        },
      }
    case desk.UPDATE_CASE_CHAT_ONLINE:
      const hc: HubtypeCase = [
        ...state.inbox.waiting,
        ...state.inbox.attending,
        ...state.inbox.idle,
      ].find(c => c.id == action.payload.caseId)
      if (!hc || hc.chat?.id !== action.payload.chatId) {
        return state
      }
      hc.chat = jsonConverter.deserializeObject(
        { ...hc.chat, is_online: action.payload.isOnline },
        HubtypeChat
      )
      return {
        ...state,
        inbox: {
          ...state.inbox,
          waiting: setCase(state.inbox.waiting, hc),
          attending: setCase(state.inbox.attending, hc),
          idle: setCase(state.inbox.idle, hc),
          selectedCase:
            state.inbox.selectedCase?.id == action.payload.caseId
              ? hc
              : state.inbox.selectedCase,
        },
      }
    case desk.SET_ARCHIVE_CASES:
      return {
        ...state,
        archive: {
          ...state.archive,
          cases: action.payload.results || [],
          prev: action.payload.previous,
          next: action.payload.next,
        },
      }
    case desk.SET_ARCHIVE_FILTERS:
      return {
        ...state,
        archive: {
          ...state.archive,
          filters: action.payload,
        },
      }
    case desk.SELECT_ARCHIVE_CASE: {
      if (!action.payload) {
        return {
          ...state,
          archive: {
            ...state.archive,
            selectedCase: null,
            selectedCaseMessages: [],
          },
        }
      }
      const archiveCase = new ArchiveCase()
      archiveCase.case = jsonConverter.deserializeObject(
        action.payload,
        HubtypeCase
      )

      return {
        ...state,
        archive: {
          ...state.archive,
          selectedCase: archiveCase,
          selectedCaseMessages: [],
        },
      }
    }
    case desk.UPDATE_ARCHIVE_MESSAGES: {
      const archiveCase = getArchiveCase(state, action.payload.case)
      // TODO: Review implementation to decide how to fetch those messages
      const selectedCase = state.archive.selectedCase

      const newSelectedCaseMessages = removeDraftAndDuplicated([
        ...state.archive.selectedCaseMessages,
        ...action.payload.messages,
      ])

      const updatedCase = updateArchiveCase(
        selectedCase,
        archiveCase,
        action.payload.messages
      )
      return {
        ...state,
        archive: {
          ...state.archive,
          selectedCase: updatedCase,
          selectedCaseMessages: newSelectedCaseMessages,
        },
      }
    }
    case desk.SET_DASHBOARD_CASES:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          cases: action.payload || [],
        },
      }
    case desk.ADD_DASHBOARD_CASE:
      const filteredCases = state.dashboard.cases.filter(
        c => c.case_id !== action.payload.case_id
      )
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          cases: [...filteredCases, action.payload],
        },
      }
    case desk.SET_REPORTING_FILTERS:
      return {
        ...state,
        reporting: {
          filters: action.payload,
        },
      }
    case auth.LOGOUT:
      return initialState

    case desk.UPDATE_INBOX_CASE_CONTACT_REASONS:
      if (!action.payload) {
        return state
      }

      const [updatedAttending, updatedIdle, updatedWaiting] = [
        state.inbox.attending,
        state.inbox.idle,
        state.inbox.waiting,
      ].map(cases =>
        cases.map(c =>
          updateCaseContactReasons(
            c,
            action.payload.case_id,
            action.payload.contact_reasons
          )
        )
      )

      const updatedSelectedCase =
        state.inbox.selectedCase &&
        state.inbox.selectedCase.id === action.payload?.case_id
          ? jsonConverter.deserializeObject(
              {
                ...updateCaseContactReasons(
                  state.inbox.selectedCase,
                  action.payload.case_id,
                  action.payload.contact_reasons
                ),
              },
              HubtypeCase
            )
          : state.inbox.selectedCase

      return {
        ...state,
        inbox: {
          ...state.inbox,
          attending: updatedAttending,
          idle: updatedIdle,
          waiting: updatedWaiting,
          selectedCase: updatedSelectedCase,
        },
      }

    case desk.UPDATE_ARCHIVE_CASE_CONTACT_REASONS:
      if (!action.payload) {
        return state
      }

      const updatedArchiveCases = state.archive.cases.map((aC: ArchiveCase) => {
        const updatedCase = updateCaseContactReasons(
          aC.case,
          action.payload.case_id,
          action.payload.contact_reasons
        )
        return { ...aC, case: updatedCase }
      })

      const updatedSelectedArchiveCase = new ArchiveCase()
      updatedSelectedArchiveCase.case =
        state.archive.selectedCase?.case &&
        state.archive.selectedCase.case.id === action.payload?.case_id
          ? jsonConverter.deserializeObject(
              {
                ...updateCaseContactReasons(
                  state.archive.selectedCase.case,
                  action.payload.case_id,
                  action.payload.contact_reasons
                ),
              },
              HubtypeCase
            )
          : state.archive.selectedCase?.case

      return {
        ...state,
        archive: {
          ...state.archive,
          selectedCase: updatedSelectedArchiveCase,
          cases: updatedArchiveCases,
        },
      }

    case desk.UPDATE_CASE_DESCRIPTION:
      if (!action.payload) {
        return state
      }

      const updateDescription = (c: HubtypeCase) => {
        if (c.id === action.payload.id) {
          c.description = action.payload.description
        }
        return c
      }
      const inboxAttending = state.inbox.attending.map(updateDescription)
      const inboxIdle = state.inbox.idle.map(updateDescription)
      const inboxWaiting = state.inbox.waiting.map(updateDescription)
      const archiveCases = state.archive.cases.map(archiveCase => {
        if (archiveCase.case.id === action.payload.id) {
          archiveCase.case.description = action.payload.description
        }
        return archiveCase
      })

      return {
        ...state,
        inbox: {
          ...state.inbox,
          attending: inboxAttending,
          idle: inboxIdle,
          waiting: inboxWaiting,
          selectedCase:
            state.inbox.selectedCase?.id === action.payload?.id
              ? jsonConverter.deserializeObject(
                  {
                    ...state.inbox.selectedCase,
                    description: action.payload.description,
                  },
                  HubtypeCase
                )
              : state.inbox.selectedCase,
        },
        archive: {
          ...state.archive,
          cases: archiveCases,
        },
      }

    case desk.CASES_UPDATE_WAITING_LIST:
      // This action is used to update the waiting when we load the case list from the backend when refreshing the page or login
      // cleanCasesMaintainOlders is called in order to maintain cases more updated via pusher instead of the cases returned by the backend
      action.payload = cleanCasesMaintainOlders(
        [...state.inbox.waiting, ...state.inbox.attending, ...state.inbox.idle],
        action.payload
      )
      const concatWaitingList: HubtypeCase[] = [
        ...state.inbox.waiting,
        ...action.payload,
      ].filter(HubtypeCase.uniqueList)

      return {
        ...state,
        inbox: {
          ...state.inbox,
          waiting: concatWaitingList,
          waitingCasesLoading:
            state.inbox.waitingCasesLoading > 0
              ? (state.inbox.waitingCasesLoading -= 1)
              : 0,
        },
      }
    case desk.CASES_UPDATE_ATTENDING_LIST:
      // This action is used to update the waiting when we load the case list from the backend when refreshing the page or login
      // cleanCasesMaintainOlders is called in order to maintain cases more updated via pusher instead of the cases returned by the backend
      action.payload = cleanCasesMaintainOlders(
        [...state.inbox.waiting, ...state.inbox.attending, ...state.inbox.idle],
        action.payload
      )
      const concatAttendingList: HubtypeCase[] = [
        ...state.inbox.attending,
        ...action.payload,
      ].filter(HubtypeCase.uniqueList)
      return {
        ...state,
        inbox: {
          ...state.inbox,
          attending: concatAttendingList,
        },
      }
    case desk.CASES_UPDATE_IDLE_LIST:
      // This action is used to update the waiting when we load the case list from the backend when refreshing the page or login
      // cleanCasesMaintainOlders is called in order to maintain cases more updated via pusher instead of the cases returned by the backend
      action.payload = cleanCasesMaintainOlders(
        [...state.inbox.waiting, ...state.inbox.attending, ...state.inbox.idle],
        action.payload
      )
      const concatIdleList: HubtypeCase[] = [
        ...state.inbox.idle,
        ...action.payload,
      ].filter(HubtypeCase.uniqueList)
      return {
        ...state,
        inbox: {
          ...state.inbox,
          idle: concatIdleList,
        },
      }

    case desk.CASES_FILTER_UPDATE:
      return {
        ...state,
        inbox: {
          ...state.inbox,
          casesFilter: Object.assign({}, action.payload),
        },
      }
    case desk.CASES_SORT_UPDATE:
      return {
        ...state,
        inbox: {
          ...state.inbox,
          casesSort: Object.assign({}, action.payload),
        },
      }
    case desk.CASES_UPDATE_WAITING_LIST_COUNT:
      return {
        ...state,
        inbox: {
          ...state.inbox,
          waitingCasesLoading: (state.inbox.waitingCasesLoading +=
            action.payload),
        },
      }

    case desk.UPDATE_CASE_ENDUSER_TYPING: // This event only arrives for attending or idle cases for the logged user
      const caseToUpdateTyping: HubtypeCase | null =
        findCaseById(action.payload.caseId, state.inbox.attending) ||
        findCaseById(action.payload.caseId, state.inbox.idle)
      if (!caseToUpdateTyping) {
        return state
      }
      caseToUpdateTyping.enduserTyping = action.payload.isTyping
      return updateStoredCase(caseToUpdateTyping, state)

    case desk.CLOSE_CASE_BY_ENDUSER:
      const caseToBlock: HubtypeCase | null =
        findCaseById(action.payload, state.inbox.attending) ||
        findCaseById(action.payload, state.inbox.idle) ||
        findCaseById(action.payload, state.inbox.waiting)
      if (!caseToBlock) {
        return state
      }
      caseToBlock.chat.is_blocked = true
      return updateStoredCase(caseToBlock, state)

    case desk.MARK_TO_NOT_TRANSLATE_CASE:
      const caseToMark: HubtypeCase | null =
        findCaseById(action.payload, state.inbox.attending) ||
        findCaseById(action.payload, state.inbox.idle)
      if (!caseToMark) {
        return state
      }
      caseToMark.markedToNotTranslate = true
      return updateStoredCase(caseToMark, state)

    case desk.MARK_TO_TRANSLATE_CASE:
      const caseToMarkAsTranslatable: HubtypeCase | null =
        findCaseById(action.payload.caseId, state.inbox.attending) ||
        findCaseById(action.payload.caseId, state.inbox.idle)
      if (!caseToMarkAsTranslatable) {
        return state
      }
      caseToMarkAsTranslatable.marked_to_translate_by = action.payload.userId
      return updateStoredCase(caseToMarkAsTranslatable, state)

    case desk.DELETE_CASES_BY_QUEUE:
      return {
        ...state,
        inbox: {
          ...state.inbox,
          waiting: state.inbox.waiting.filter(
            wcase => wcase.queue_id !== action.payload.queueId
          ),
          idle: state.inbox.idle.filter(
            icase => icase.queue_id !== action.payload.queueId
          ),
          attending: state.inbox.attending.filter(
            acase => acase.queue_id !== action.payload.queueId
          ),
        },
      }

    case desk.CLOSE_CASE_CHAT_WINDOW:
      return {
        ...state,
        inbox: {
          ...state.inbox,
          closeCaseChatWindow: action.payload,
        },
      }

    case desk.SET_SELECTED_MESSAGES:
      return {
        ...state,
        inbox: {
          ...state.inbox,
          selectedCase: jsonConverter.deserializeObject(
            state.inbox.selectedCase,
            HubtypeCase
          ), //Force sync on case chat
          selectedCaseMessages: action.payload,
        },
      }

    case desk.SET_ARCHIVE_MESSAGES:
      const newSelectedCase = jsonConverter.deserializeObject(
        state.archive.selectedCase,
        ArchiveCase
      )
      newSelectedCase.case = jsonConverter.deserializeObject(
        state.archive.selectedCase.case,
        HubtypeCase
      )
      return {
        ...state,
        archive: {
          ...state.archive,
          selectedCase: newSelectedCase, //Force sync on case chat,
          selectedCaseMessages: action.payload,
        },
      }
    case desk.TOGGLE_NAVBAR_COLLAPSE:
      return {
        ...state,
        inbox: {
          ...state.inbox,
          navbarCollapse: !state.inbox.navbarCollapse,
        },
      }
    case desk.SET_NAVBAR_FILTERS:
      return {
        ...state,
        inbox: {
          ...state.inbox,
          navbarFilters: action.payload,
        },
      }
    case desk.DELETE_NAVBAR_FILTER:
      return {
        ...state,
        inbox: {
          ...state.inbox,
          navbarFilters: state.inbox.navbarFilters.filter(
            navbarFilter => navbarFilter.projectId !== action.payload.projectId
          ),
        },
      }
    default:
      return state
  }
}

export function cleanCasesMaintainOlders(
  mostUpdatedCases: HubtypeCase[],
  cases: HubtypeCase[]
): HubtypeCase[] {
  return cases.filter(
    hubtypeCase => !mostUpdatedCases.find(c => c.id === hubtypeCase.id)
  )
}

function updateStoredCase(
  case_: HubtypeCase,
  currentState: DeskState
): DeskState {
  function updateState(
    listName: CaseList,
    case_: HubtypeCase,
    isSelected: boolean
  ): DeskState {
    return {
      ...currentState,
      inbox: {
        ...currentState.inbox,
        [listName]: setCase(currentState.inbox[listName], case_),
        selectedCase: isSelected
          ? jsonConverter.deserializeObject(
              Object.assign({}, case_),
              HubtypeCase
            )
          : currentState.inbox.selectedCase,
      },
    }
  }

  if (!case_) {
    return currentState
  }

  const isCaseSelected = currentState.inbox.selectedCase?.id === case_.id

  if (case_?.is_attending) {
    return updateState(CaseList.ATTENDING, case_, isCaseSelected)
  } else if (case_?.is_idle) {
    return updateState(CaseList.IDLE, case_, isCaseSelected)
  } else if (case_?.is_waiting) {
    return updateState(CaseList.WAITING, case_, isCaseSelected)
  }

  return currentState
}

// Add element if its id is not in the array
function addCase(
  casesAtending: HubtypeCase[],
  c: HubtypeCase,
  casesWaiting?: HubtypeCase[]
): HubtypeCase[] {
  if (
    casesAtending.find(item => item.id === c.id && item.queue_id === c.queue_id)
  ) {
    return casesAtending
  }
  if (casesWaiting) {
    // Will import all messages from the old case to the new copy
    const prev_case = casesWaiting.find(item => item.id === c.id)
    if (prev_case) {
      return [
        ...casesAtending,
        jsonConverter.deserializeObject({ ...c }, HubtypeCase),
      ]
    }
  }
  return [...casesAtending, c]
}

function updateArchiveCase(
  selectedCase: ArchiveCase,
  caseFromArchive: ArchiveCase,
  messages?: HubtypeMessage[]
): ArchiveCase {
  const updatedCase = updateCaseMessages(
    selectedCase.case,
    caseFromArchive.case,
    messages
  )
  const updatedArchiveCase = jsonConverter.deserializeObject(
    { ...caseFromArchive, case: updatedCase },
    ArchiveCase
  )
  return updatedArchiveCase
}

function updateCaseMessages(
  oldCase: HubtypeCase,
  newCase: HubtypeCase,
  messages?: HubtypeMessage[]
): HubtypeCase {
  const merged_messages: HubtypeMessage[] = []
  if (messages) {
    // // Merging arrays by 'id': https://stackoverflow.com/questions/19480008/javascript-merging-objects-by-id
    // const hash = new Map()
    // // eslint-disable-next-line
    // ;[...(oldCase.messages || []), ...messages].forEach(m =>
    //   hash.set(m.id, { ...(hash.get(m.id) || {}), ...m })
    // )
    // merged_messages = jsonConverter.deserializeArray(
    //   Array.from(hash.values()),
    //   HubtypeMessage
    // )
    // merged_messages.sort((c1, c2) => (c1.created_at < c2.created_at ? -1 : +1))
  }
  const updated_messages = messages
  const lastMessage = getLastMessage(updated_messages, newCase.last_message)
  const lastUnanswered = lastMessage?.length
    ? getLastUnansweredMessage(newCase.last_unanswered_message, lastMessage[0])
    : newCase.last_unanswered_message
  return jsonConverter.deserializeObject(
    {
      ...oldCase,
      assigned_to: newCase.assigned_to,
      project_id: newCase.project_id,
      queue_id: newCase.queue_id,
      status: newCase.status,
      resolution_status: newCase.resolution_status,
      resolved_at: newCase.resolved_at,
      resolved_by: newCase.resolved_by,
      type: newCase.type,
      last_message: lastMessage,
      last_unanswered_message: lastUnanswered,
      messages: [],
    },
    HubtypeCase
  )
}

function getLastMessage(m: HubtypeMessage[], def: HubtypeMessage[]) {
  const messages: HubtypeMessage[] = m.filter(
    message => message.is_enduser || message.is_agent
  )
  if (messages.length) {
    return messages.slice(messages.length - 1)
  }
  return def
}

function getSelectedCaseToUpdate(
  currentselectedCase: HubtypeCase,
  updatedCase: HubtypeCase,
  me: HubtypeUser
) {
  if (!currentselectedCase) {
    return null
  } else if (currentselectedCase?.id !== updatedCase.id) {
    return currentselectedCase
  } else if (!me || isCaseVisible(me, updatedCase)) {
    return updatedCase
  }
  return null
}

function getArchiveSelectedCaseToUpdate(
  currentselectedCase: ArchiveCase,
  updatedCase: HubtypeCase
) {
  if (currentselectedCase?.case?.id !== updatedCase.id) {
    return currentselectedCase
  }
  return { ...currentselectedCase, case: updatedCase }
}

function getLastUnansweredMessage(
  currentLastUnansweredMessage: HubtypeMessage,
  lastMessage: HubtypeMessage
) {
  if (lastMessage.is_agent) {
    return undefined
  }
  if (currentLastUnansweredMessage) {
    return currentLastUnansweredMessage
  }
  return lastMessage
}

function isCaseVisible(me: HubtypeUser, transferredCase: HubtypeCase): boolean {
  return (
    me.hasQueuePermission(transferredCase.queue_id) &&
    (transferredCase.isAssignedTo(me.id) || transferredCase.is_waiting)
  )
}

function findCaseById(caseId, cases) {
  return cases.find(c => c.id === caseId)
}

function removeCaseById(cases: HubtypeCase[], caseId: string): HubtypeCase[] {
  return cases.filter(c => c.id !== caseId)
}

function setCase(arr: HubtypeCase[], hcase: HubtypeCase) {
  return arr.map(m => {
    if (m.id === hcase.id) {
      const myCase = jsonConverter.deserializeObject(hcase, HubtypeCase)
      return jsonConverter.deserializeObject(myCase, HubtypeCase)
    } else {
      return m
    }
  })
}
