<div
  class="search-bar-container"
  [ngClass]="{
    secondary: type === 'secondary',
    'size-small': size === 'small',
    'size-tiny': size === 'tiny'
  }"
  role="searchbar"
>
  <i class="fa-solid fa-search"></i>
  <input
    type="text"
    [placeholder]="placeholder"
    [value]="value"
    (input)="onInput($event.target.value)"
    role="searchbox"
  />
  <i
    *ngIf="value"
    class="fa-solid fa-xmark"
    (click)="onCrossClick(); $event.stopPropagation()"
  ></i>
</div>
