import { enableProdMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'
import * as Sentry from '@sentry/angular'

import { Analytics } from './app/utils/analytics'
import { AppModule } from './app/app.module'
import { environment } from './environments/environment'
import { SubscribeMonitor } from './app/utils/debug-utils'

if (environment.sentryURL) {
  Sentry.init({
    dsn: environment.sentryURL,
    release: environment.release_name + '-' + environment.version,
    integrations: [
      // Registers and configures the Tracing integration,
      // which automatically instruments your application to monitor its
      // performance, including custom Angular routing instrumentation
      Sentry.browserTracingIntegration(),
      Sentry.captureConsoleIntegration({
        levels: ['error'],
      }),
      Sentry.dedupeIntegration(),
      // new Sentry.Replay(),
    ],
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    // replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    // replaysOnErrorSampleRate: 1.0,
    // To set a uniform sample rate
    tracesSampleRate: 0.2,
    environment: environment.release_name,
  })
}

if (!environment.production) {
  const monitor = new SubscribeMonitor()
  //monitor.enable()
}

if (environment.production) {
  enableProdMode()
}

Analytics.load()

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.log(err))
