<ng-container *ngIf="!sended; else sendedTemplate">
  <div class="no-auth-page-container">
    <div class="title">
      <h1>Reset your password</h1>
      <p>If you want to change your password, introduce your email here</p>
    </div>

    <form [formGroup]="form" class="ht-form" (ngSubmit)="onSubmit()">
      <div class="ht-form-item">
        <div class="ht-form-item-title">Email</div>
        <div class="ht-form-item-control">
          <ht-input
            label="Email"
            type="email"
            formControlName="email"
            [fullWidth]="true"
          ></ht-input>
        </div>
      </div>

      <div class="ht-form-actions">
        <button
          ht-button-primary
          type="submit"
          size="large"
          [fullWidth]="true"
          #submitButton
        >
          Send email
        </button>
        <a ht-button-borderless [fullWidth]="true" routerLink="/sign-in">
          Back to Sign in
        </a>
      </div>
    </form>
  </div>
</ng-container>

<ng-template #sendedTemplate>
  <div class="no-auth-page-container">
    <div class="title">
      <h1>Check your email</h1>
      <p>Thanks! We have sent an email that will enable you reset password.</p>
      <p *ngIf="!resended">
        If you haven't received an email check your spam, or
        <a (click)="resendEmail()"> resend </a>
        email.
      </p>
      <p *ngIf="resended">Email has been sent again.</p>
    </div>

    <div class="ht-form">
      <div class="ht-form-actions">
        <a ht-button-borderless routerLink="/sign-in" [fullWidth]="true">
          Go back to Sign in
        </a>
      </div>
    </div>
  </div>
</ng-template>
