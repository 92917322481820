import { Component, Inject, OnInit } from '@angular/core'
import { UntypedFormBuilder, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { Store } from '@ngrx/store'
import {
  compareControlValidator,
  emailAndPasswordSimilarityValidation,
  handleServerError,
  validateAllFormFields,
} from 'models/form'
import { HubtypeUser } from 'models/hubtype-user'
import { finalize, first, switchMap } from 'rxjs'
import { CustomError } from 'src/app/constants/errors'
import { FeedbackService } from 'src/app/services/feedback.service'
import { UserService } from 'src/app/services/hubtype-api/user.service'
import * as authActions from '../../actions/auth'
import * as fromRoot from '../../reducers'

@Component({
  selector: 'set-new-password',
  templateUrl: './set-new-password.component.html',
  styleUrls: ['./set-new-password.component.scss'],
})
export class SetNewPasswordComponent implements OnInit {
  form = this.fb.group({
    email: [''],
    password: [
      '',
      [
        Validators.required,
        Validators.minLength(12),
        Validators.maxLength(64),
        emailAndPasswordSimilarityValidation(),
      ],
    ],
    checkPassword: [
      '',
      [
        Validators.required,
        compareControlValidator('password', 'Passwords do not match'),
      ],
    ],
  })
  isAnInvite = false
  isLoading = false
  user: HubtypeUser

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private store: Store<fromRoot.State>,
    private feedbackService: FeedbackService,
    private activatedRoute: ActivatedRoute,
    @Inject('userService') private userService: UserService
  ) {}

  ngOnInit() {
    this.isAnInvite = this.activatedRoute.snapshot.data.isAnInvite
    this.loadUser()
  }

  onSubmit() {
    if (!validateAllFormFields(this.form)) {
      return
    }
    this.updatePassword()
  }

  private updatePassword() {
    const { password } = this.form.value

    this.isLoading = true
    this.userService
      .updatePassword(this.user.id, password)
      .pipe(
        first(),
        switchMap(() => this.userService.getMe(true)),
        finalize(() => (this.isLoading = false))
      )
      .subscribe({
        next: result => {
          this.store.dispatch(new authActions.SetMeAction(result))
          if (this.isAnInvite) {
            this.router.navigate(['/complete-profile'])
          } else {
            this.router.navigate(['/desk'])
          }
        },
        error: err => {
          handleServerError({
            error: err,
            form: this.form,
            feedbackService: this.feedbackService,
            customUnhandledError: CustomError.PROFILE_UPDATE,
          })
        },
      })
  }

  private loadUser() {
    this.store
      .select(fromRoot.getMeState)
      .pipe(first(Boolean))
      .subscribe(me => {
        this.user = me
        this.form.patchValue({
          email: me.email,
        })
      })
  }
}
