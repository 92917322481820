import { Injectable } from '@angular/core'

@Injectable()
export class Utilities {
  constructor() {}

  dataURLToBlob(dataURL) {
    // http://stackoverflow.com/questions/18550151/posting-base64-data-javascript-jquery
    // http://pastebin.com/1E2FAM5K
    const BASE64_MARKER = ';base64,'
    let raw
    if (!dataURL) {
      return null
    }
    if (dataURL.indexOf(BASE64_MARKER) === -1) {
      const parts = dataURL.split(',')
      const contentType = parts[0].split(':')[1]
      raw = parts[1]
      return new Blob([raw], { type: contentType })
    } else {
      const parts = dataURL.split(BASE64_MARKER)
      const contentType = parts[0].split(':')[1]
      raw = window.atob(parts[1])
      const rawLength = raw.length

      const uInt8Array = new Uint8Array(rawLength)

      for (let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i)
      }
      return new Blob([uInt8Array], { type: contentType })
    }
  }
}

export interface FileInfo {
  name: string
  extension: string
  mime_type: string
  last_modified: number // timestamp
  last_modified_date: Date
  size: number
  webkit_relative_path: string
}

export const getFileInfo = (file: any): FileInfo =>
  // Ref.: https://developer.mozilla.org/en-US/docs/Web/API/File
  ({
    last_modified: file.lastModified,
    last_modified_date: file.lastModifiedDate,
    name: file.name,
    extension: getFileExtension(file.name),
    mime_type: file.type,
    webkit_relative_path: file.webkitRelativePath,
    size: file.size,
  })

export const getFileExtension = (filename: string): string => {
  // this doesn't support files with double extension (like .tar.gz). So it will extract only the last one (.gz)
  const nameComponents = filename.split('.')
  if (nameComponents.length > 1) {
    return '.' + nameComponents[nameComponents.length - 1]
  } else {
    return ''
  }
}
