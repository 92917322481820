import { Component, Input } from '@angular/core'
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject'
import { BreadcrumbService } from 'src/app/services/breadcrumb/breadcrumb.service'

export interface BreadcrumbList {
  text: string
  link: string
  active: boolean
}

export interface Breadcrumb {
  type?: 'string' | 'list'
  content: string | BreadcrumbList[]
  link?: string
}

@Component({
  selector: 'ht-breadcrumb',
  templateUrl: './ht-breadcrumb.component.html',
  styleUrls: ['./ht-breadcrumb.component.scss'],
})
export class HtBreadcrumbComponent {
  @Input()
  breadcrumbs

  $breadcrumbs: BehaviorSubject<Breadcrumb[]>

  constructor(breadcrumbService: BreadcrumbService) {
    this.$breadcrumbs = breadcrumbService.$breadcrumbs
  }
}
