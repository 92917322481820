<form [formGroup]="form" class="ht-form" (ngSubmit)="onSubmit()">
  <div class="ht-form-item">
    <div class="ht-form-item-title">Email</div>
    <div class="ht-form-item-control">
      <ht-input
        label="Email"
        type="email"
        formControlName="email"
        fullWidth="true"
      ></ht-input>
    </div>
  </div>
  <div class="ht-form-item">
    <div class="ht-form-item-title">Password</div>
    <div class="ht-form-item-control">
      <ht-input
        label="Password"
        type="password"
        formControlName="password"
        fullWidth="true"
      ></ht-input>
    </div>
    <div class="help-text">
      <a routerLink="/reset-pwd" [queryParams]="{ email: form.value.email }">
        Forgot your password?
      </a>
    </div>
  </div>

  <div class="feedback-wrapper">
    <div *ngIf="!!error" class="error-alert">
      <i class="fa-solid fa-circle-xmark"></i>
      <span>{{ error }}</span>
    </div>

    <button
      ht-button-primary
      type="submit"
      size="large"
      [isLoading]="isSigningIn"
      fullWidth="true"
    >
      Sign in
    </button>
  </div>

  <p class="sign-up-text">
    Don’t have an account?
    <button ht-button-borderless routerLink="/signup">Sign up</button>
  </p>
</form>
