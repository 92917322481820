import produce, { Draft } from 'immer'
import { HubtypeQueue } from 'models/hubtype-queue'
import { HubtypeUser } from 'models/hubtype-user'
import { deserializeArray } from 'utils/json-utils'
import {
  ADD_AGENT,
  ADD_USER,
  CHANGE_STATUS_AGENT,
  DELETE_AGENT,
  UPDATE_USER,
} from '../actions/organization'
import { USERS_ERROR, USERS_RECEIVE } from '../actions/shared'

export type UsersState = HubtypeUser[]
export const initialState: UsersState = []

export function deserialize(json: any[]): UsersState {
  if (!json) {
    return []
  }
  return deserializeArray(json, HubtypeUser)
}

export function reducer(state?: UsersState, action: any = {}) {
  return produce((draftState: Draft<UsersState>, appraisalAction: any) => {
    switch (appraisalAction.type) {
      case USERS_RECEIVE:
        draftState = action.payload
        return draftState
      case USERS_ERROR:
        console.error(action.payload)
        return draftState
      case CHANGE_STATUS_AGENT:
        return updateStatusAgent(action.payload, draftState)
      case DELETE_AGENT: //Delete agent from queue
        const pUserDelete: HubtypeUser = action.payload.user
        const pQueueDelete: HubtypeQueue = action.payload.queue

        const userExist = draftState.find(user => user.id === pUserDelete.id)
        if (userExist) {
          userExist.queues = userExist.queues.filter(
            queue => queue.id !== pQueueDelete.id
          )
        }
        return [...draftState]

      case ADD_AGENT: //Add agent to queue
        const pUserAdd: HubtypeUser = action.payload.user
        const pQueueAdd: HubtypeQueue = action.payload.queue
        const userStore = draftState.find(user => user.id === pUserAdd.id)
        if (
          userStore &&
          !userStore.queues.find(queue => queue.id === pQueueAdd.id)
        ) {
          userStore.queues.push(pQueueAdd)
        }
        //Update store.users.queues from agent list of payload.queue
        pQueueAdd.agents?.forEach(agent => {
          const existAgent = draftState.find(user => user.id === agent.id)
          if (!existAgent.queues.find(queue => queue.id === pQueueAdd.id)) {
            existAgent.queues.push(pQueueAdd)
          }
        })
        return [...draftState]

      case ADD_USER:
        draftState.push(action.payload)
        return draftState
      case UPDATE_USER:
        return updateStatusAgent(action.payload, draftState)

      default:
        return draftState
    }
  }, initialState)(state, action)
}

function updateStatusAgent(
  user: HubtypeUser,
  list: HubtypeUser[]
): HubtypeUser[] {
  return list.map(u => (u.id === user.id ? user : u))
}
