import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FlexLayoutModule } from '@angular/flex-layout'
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button'
import { MatButtonToggleModule } from '@angular/material/button-toggle'
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog'
import { MatIconModule } from '@angular/material/icon'
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list'
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner'
import { RouterModule } from '@angular/router'
import { AvatarComponent } from './avatar/avatar.component'
import { ButtonComponent } from './button/button.component'
import { CardSelectorComponent } from './card-selector/card-selector.component'
import { ChipComponent } from './chip/chip.component'
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component'
import { FeedbackComponent } from './feedback/feedback.component'
import { MenuScreenComponent } from './menu-screen/menu-screen.component'
import { PlaceholderComponent } from './placeholder/placeholder.component'
import { PopoverComponent } from './popover/popover.component'
import { ProviderIconComponent } from './provider-icon/provider-icon.component'
import { ScreenHeaderComponent } from './screen-header/screen-header.component'
import { ScreenSectionComponent } from './screen-section/screen-section.component'
import { StatusPillComponent } from './status-pill/status-pill.component'
import { ToggleButtonsComponent } from './toggle-buttons/toggle-buttons.component'
import { UserAvatarComponent } from './user-avatar/user-avatar.component'

@NgModule({
  declarations: [
    AvatarComponent,
    ButtonComponent,
    ChipComponent,
    PopoverComponent,
    ConfirmDialogComponent,
    MenuScreenComponent,
    FeedbackComponent,
    PlaceholderComponent,
    ScreenHeaderComponent,
    ScreenSectionComponent,
    StatusPillComponent,
    ToggleButtonsComponent,
    ProviderIconComponent,
    CardSelectorComponent,
    UserAvatarComponent,
  ],
  exports: [
    AvatarComponent,
    ButtonComponent,
    ChipComponent,
    PopoverComponent,
    MenuScreenComponent,
    FeedbackComponent,
    PlaceholderComponent,
    ScreenHeaderComponent,
    ScreenSectionComponent,
    StatusPillComponent,
    ToggleButtonsComponent,
    ProviderIconComponent,
    CardSelectorComponent,
    UserAvatarComponent,
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatDialogModule,
    MatIconModule,
    MatListModule,
    MatProgressSpinnerModule,
    RouterModule,
  ],
})
export class SharedBrandModule {}
