import { Action } from '@ngrx/store'
import { HubtypeAiProject } from './../models/hubtype-ai-project'

export enum AiActionType {
  SET_PROJECT_LIST = '[AI] Update Project List',
  UPDATE_PROJECT = '[AI] Update Project',
}

export class SetProjectListAction implements Action {
  readonly type = AiActionType.SET_PROJECT_LIST

  constructor(public payload: HubtypeAiProject[]) {}
}

export class UpdateProjectAction implements Action {
  readonly type = AiActionType.UPDATE_PROJECT

  constructor(public payload: HubtypeAiProject) {}
}

export type AiAction = SetProjectListAction | UpdateProjectAction
