import { Component, Input, OnInit } from '@angular/core'

@Component({
  selector: 'hubtype-logo',
  templateUrl: './hubtype-logo.component.html',
  styleUrls: ['./hubtype-logo.component.scss'],
})
export class HubtypeLogoComponent implements OnInit {
  @Input() href: string

  constructor() {}

  ngOnInit(): void {}
}
